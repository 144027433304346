.file-upload-container {
  width: auto;
  padding: 10px;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  border-radius: 6px;
  border: dashed 2px #bfcbde;
  background-color: #ffffff;
  text-align: center;
}
.file-upload-container .img-icon-container {
  text-align: center;
}
.file-upload-container .img-icon-container img {
  height: 100px;
  width: auto;
}
.file-upload-container .file-upload-text-container {
  margin-top: 0;
  line-height: 2;
}
.file-upload-container .file-upload-text {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.2px;
  color: #424242;
}
.file-upload-container .browse-file-container {
  margin: 10px;
}
.file-upload-container .browse-file-container .browse-file-upload#browse-file-upload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.file-upload-container .browse-file-container label.browse-file-btn {
  cursor: pointer;
  display: inline-block;
  align-self: center;
  width: auto;
  height: 39px;
  padding: 0 25px;
  background-color: #9eb1c8;
  font-size: 20px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.9;
  letter-spacing: -0.2px;
  text-align: center;
  border-radius: 6px;
}
.file-upload-container .drag-overlay {
  position: absolute;
  display: flex;
  flex-flow: column;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(240, 240, 240, 0.5);
  vertical-align: text-bottom;
  font-size: 35px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 100%;
  letter-spacing: -0.2px;
  text-align: center;
}
