.printed-day-view .day-header {
  padding: 0 0.153846153846154em;
  border-right: 1px solid #000000;
  border-bottom: 2px solid #000000;
  position: relative;

  &:after {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    content: ' ';
    width: 0.153846153846154em;
    background-color: #e6e6e6 !important;
  }

  .col-10 {
    background-color: #e6e6e6 !important;
    font-size: 1.076923076923077em;
    letter-spacing: -0.02em;
    font-weight: bold;
    color: #383838;
  }

  .col-10 > .row {
    border-left: 2px solid #000000;
  }

  .col-10 > .row > div > span {
    display: block;
    padding: 0.142857142857143em;
  }

  .col-10 > .row > div:first-child > span {
    border-right: 1px solid #000000;
  }

  .col-10 > .row > div:first-child {
    width: 69.230769230769231%;
  }

  .col-10 > .row > div:last-child {
    width: 30.769230769230769%;
  }
}

@media (max-width: 1379px) {
  .printed-day-view .day-header aside {
    width: 22%;
  }

  .printed-day-view .day-header aside + div {
    width: 78%;
  }
}
