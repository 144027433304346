.hx-calendar-indicators-button.active + .custom-multiselect-filter {
  display: flex;
}

.option-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 50px;

  sh-text {
    color: #535b65;
  }
}

.hx-filters .filters-content {
  margin-top: 0;
  left: 1.5em;
}

.selected-value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 0.35em;

  i {
    margin-left: 5px;
  }

  &.cmsf sh-text {
    width: max-content;
  }
}

.width-110 {
  width: 110px;
}

.width-160 {
  width: 160px;
}

.width-40 {
  width: 60px;
}

.width-60 {
  width: 80px;
}

.custom-multiselect-filter {
  display: none;
  margin-top: 14px;
  margin-left: 10px;
  font-size: 1em;
  min-width: 20em;
  max-width: 35em;
  border-radius: 0.46154em;
  box-shadow: 0 2px 34px 0 rgba(34, 50, 71, 0.25);
  position: absolute;
  z-index: 10000;

  & > .hx-checkbox:nth-child(1) {
    height: auto;
  }

  .selectArrowUp {
    position: absolute;
    top: -0.65em;
    left: 5px;
    font-size: 1.53846em;
    margin-left: calc(50% - 0.25em - 20px);
    z-index: 10000;
  }
}

.hx-filters {
  li:hover button:not(.selected) {
    color: rgb(151, 164, 181);
    opacity: 0.8;
  }

  .grouped {
    width: max-content;
  }
}
