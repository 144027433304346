.constraint-name-wrapper{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.94rem;
}

.constraint-type-icon{
    position: relative;
    top: 0.48rem;
}

.arrow-button-wrapper{
    display: flex;
    align-items: center;
}

.arrow-buttons{
    border: solid 1px;
    border-radius: 50%;
}

.constraint-wrapper-height{
    height: 3rem;
}