@function em($pixels, $base-font-size: $font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($base-font-size)) {
    $base-font-size: $base-font-size * 1px;
  }

  @return calc($pixels / $base-font-size) * 1em;
}

$color-zircon: #dbe0e4;
$color-anti-flash-white: #f2f4f5;
$color-river-bed: #545b64;
$color-gull-gray: #9aa6b8;


@media screen and (max-width: 1200px) {
  .hx-calendar-datepicker {
    .dateTitle {
      font-size: 0.9rem !important
    }
    .calendar-state{
      font-size: 0.6rem !important
    }
    width: fit-content;
  }
  .week-stepper{
    width:20%
  }
  .trout{
  font-size: 10px !important;
  }
}

.hx-calendar-datepicker {
  background-color: $color-anti-flash-white;
  height: em(29px, 13px);
  font-size: 1em;
  justify-content: center;
  display: flex;

  .selectDocument,
  .dateTitle {
    font-size: em(15px, 13px);
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: em(-0.2px, 13px);
    height: 100%;
    display: flex;
    align-items: center;
  }

  .dateTitle {
    color: $color-river-bed;
  }

  .selectDocument {
    color: $color-gull-gray;
    display: flex;
    margin: 0 auto;
  }

  .calendar-state {
    font-size: em(11px, 13px);
    border-radius: em(7px, 11px);
    height: em(15px, 11px);
    line-height: normal;
    margin: 0 1em;
  }
}

.hx-schedule-list,
.hx-calendar-datepicker {
  .calendar-state {
    font-size: em(11px, 13px);
    border-radius: em(7px, 11px);
    height: em(15px, 11px);
    line-height: normal;
    letter-spacing: 0.125em;
  }
}
