.groups-container {
  .group-title {
    padding: 10px;
    &:not(:first-child) {
      border-top: 1px solid #cbcbcb;
    }
  }
}

.col-3.staff-profile-col {
  margin-top: 30px;
}

.button-back-to-index {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0 1.076923076923077em;
  font-size: 1em;
  letter-spacing: -0.006923076923077em;
  font-weight: 400;
  line-height: 1.153846153846154em;

  i {
    position: relative;
    top: -0.076923076923077em;
  }
}

.hx-staff-profile-info {
  .hx-staff-avatar {
    border: solid 1px rgba(0, 0, 0, 0.08);
  }

  .hx-staff-profile-details {
    transition: height 0.2s ease-in-out;
    overflow: hidden;
  }

  & > div:last-child {
    margin-left: auto;
  }

  .hx-staff-profile-pn {
    sh-text {
      display: inline;
    }
  }
}

@function em($pixels, $base-font-size: $font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($base-font-size)) {
    $base-font-size: $base-font-size * 1px;
  }

  @return calc($pixels / $base-font-size) * 1em;
}

$color-anti-flash-white: #f2f4f5;

.hx-staff-detailed-schedule {
  min-height: 177px;

  .toolbar {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 45px;

    &.header {
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      width: 100%;
      padding: 5px 21px;
    }

    .datepicker {
      width: fit-content;
      background-color: $color-anti-flash-white;
      height: em(29px, 13px);
      font-size: 1em;
      padding: 0px 10px;

      .dateTitle {
        margin-right: 5px;
      }
    }

    //for activeDataRange under src/Navigation
    .hx-calendar-datepicker {
      width: max-content;
      min-width: em(266px, 13px);
      .dateTitle {
        font-size: 1em;
      }
    }

    .hx-schedule-selector {
      top: 152px;
      left: calc(50% - calc(1032px / 2));

      .staff-list {
        top: 0;
      }
    }
  }

  .list-view-header {
    margin-bottom: 10px;

    label {
      display: inline;
    }
  }

  .list-view-item {
    padding: 3px 40px 10px 20px;
    margin-left: 20px;

    &.list-highlight {
      border-top: 1px solid;
      border-color: blue;
    }
  }

  .hx-staff-date {
    border-radius: 4px;
    background-color: #f2f4f5;
    letter-spacing: -0.1px;
    color: #808fa4;
    width: fit-content;
    padding: 0 1em 0 0.5em;

    i {
      color: #a3b3ce;
      padding-right: 0.375em;
      font-size: 1.23076923em;
    }
  }

  .schedule-view-container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 0;
    height: 132px;
  }
}

.hx-staff-profile-avatar {
  justify-content: center;
  .hx-staff-initials sh-text {
    font-size: 2.5em;
    line-height: normal;
  }
}

.hx-staff-profile-size {
  width: 100px;
  height: 100px;
}

.hx-center-align {
  text-align: center;
}

.hx-seprator {
  height: 1px;
  background: #e5e5e5;
  margin-top: 10px;
}

.hx-staff-profile-heading {
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  text-align: left;
  color: #808fa4;
}

.hx-profile-info {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: #535b65;

  sh-text[size="body-1"] {
    font-size: 14px;
    a {
      text-decoration: underline;
    }
  }
}

.hx-staff-profile-button {
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: #677a91;
  cursor: pointer;

  i {
    margin: 0 0.5rem;
    font-size: 10px;
  }
}

.hx-staff-profile-icons {
  width: 20px;
  height: 20px;
}

.hx-profile-names {
  color: #535b65;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
}

.hx-staff-editicon {
  justify-content: right;
  position: relative;
  top: 30px;
  right: 20px;
  cursor: pointer;
  button {
    background: #f2f4f5 !important;
  }
}

.hx-staff-statusicon {
  position: relative;
  cursor: default;
  top: 70px;
  right: 41px;
}

.hx-details-header {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 25px !important;
  letter-spacing: -0.025em !important;
  color: #535b65;
}

.hx-header-label {
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.05em;
  color: #808fa4;
}

.hx-department-details {
  line-height: 1.7;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  sh-text[size="body-1"] {
    font-size: 14px;
    color: inherit;
    padding: 0;
  }

  .hx-extra-column-fix {
    margin-right: 20px;
  }

  .hx-date-fix {
    margin-left: -35px;
  }

  .col-2 {
    display: flex;
    align-items: center;
  }

  span {
    display: inline-flex;
    line-height: normal;
    align-items: center;
  }

  i:before {
    display: inline-flex;
    align-items: center;
    line-height: 13px;
  }

  i.icon-calendar::before {
    font-size: 20px;
    line-height: 13px;
    margin: 0;
  }
}

@import "/src/styles/foundation/variables";

.hx-staff-profile-header {
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  border-top: 1px solid #dee5ef;
  border-bottom: 1px solid #dee5ef;
  height: 3.38461538em;
  position: fixed;
  top: $access-bar-height + $nav-bar-height;
  width: 100%;
  z-index: 198;

  & + .hx-staff-container {
    margin-top: 5.38461538em;
    margin-bottom: 2em;
  }

  h1 {
    font-size: 1.69230769em;
    color: #5f6978;
    line-height: normal;
    padding: 0;
    margin: 0;
  }
}

.hx-staff-transfer-data-container {
  display: flex;
  width: 100%;

  .hx-staff-info-labels {
    text-align: right;

    label {
      margin: 10px 0;
    }
  }
}

.align-names {
  text-align: center;
}

.hx-initiate-staff-transfer-modal .hx-staff-transfer-data-container .hx-staff-info-data label {
  line-height: 2.8em;
  margin: 0;
}

.hx-transfer-modal {
  footer {
    width: 100%;
    text-align: right;

    button {
      margin: 0 10px;
    }

    .button.primary {
      background-color: #459afe;
    }
  }
}

.hx-staff-profile-transferring-edit-form {
  position: absolute;
  top: 0;
  left: 20px;
  font-size: 12px;

  .hx-status {
    font-weight: bold;
    color: #fb8c00;
  }

  .staff-transfer-dot {
    font-size: 10px;
    font-weight: bold;
  }
  .staff-transfer-dot::before {
    content: "•";
    margin: 0 2px;
  }
}

.hx-modal-title {
  h3 {
    font-size: 14px;
  }
}

.hx-staff-profile-transferring {
  padding-left: 12.5em;
  font-size: 12px !important;

  .hx-status {
    font-weight: bold;
    color: #fb8c00;
  }

  .staff-transfer-dot {
    font-size: 10px;
    font-weight: bold;
  }
  .staff-transfer-dot::before {
    content: "•";
    margin: 0 2px;
  }
}

.hx-staff-profile-edit-transferring {
  font-size: 12px;
  margin: 10px 50px;

  .hx-status {
    font-weight: bold;
    color: #fb8c00;
  }

  .staff-transfer-dot {
    font-size: 10px;
    font-weight: bold;
  }
  .staff-transfer-dot::before {
    content: "•";
    margin: 0 2px;
  }
}

.hx-department-elegibility-header {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 5px 20px;

  label {
    display: inline;
  }
}

.description span {
  margin: 0 5px;
}

.hx-staff-info {
  display: flex;
  width: 100%;

  div.hx-staff-info-labels {
    font-size: 14px;
    width: 9.285714285714286em;
    text-align: right;
  }

  div.hx-staff-info-data {
    text-align: left;

    div {
      height: 1.412em;
    }
  }

  div,
  label {
    margin: 5px 5px;
    height: 20%;
  }
}

.hx-status-has-access,
.hx-status-no-access,
.hx-status-pending-access,
.hx-status-access-ending {
  width: 22px;
}

.hx-staff-container-box-title {
  margin-top: 30px;
  margin-bottom: 20px;
}

.ib {
  display: flex;
  width: 100%;
}

.hx-staff-history-card {
  margin: 20px 0;
  width: 100%;
  display: flex;
  background-color: white;
  border-radius: 1px;

  ul {
    list-style: none;
    padding: 10px 10px;

    li {
      margin: 10px 0;
    }
  }
}

.recurring-schedule {
  input[type="checkbox"] {
    display: none;

    & + div {
      display: flex;
      align-items: center;
      vertical-align: middle;
      justify-content: space-around;
      height: 2.15em;
      width: 2.15em;
      border-radius: 1em;
      border: solid 1px #dee5ef;
      line-height: normal;
    }

    &:checked + div {
      background: #d7e0ef;
    }
  }
  .week-row {
    &:not(:last-child) {
      padding-bottom: 10px;
    }

    .week-row-item {
      margin-right: 15px;
    }
  }
  .rule-title {
    sh-text {
      display: inline;
    }
  }
}

.hx-modal-staff-list .modal {
  overflow: visible;
}

.hx-staff-details {
  .activities-content {
    .activities-dates {
      padding: 10px 20px 0 20px;
      sh-text {
        font-size: 14px;
      }
    }
    .activities-dates:first-child {
      padding-top: 20px;
    }
    .activities-day {
      box-shadow: unset;
      padding: 0px 18px 10px 18px;
      .separator {
        margin-left: auto;
        width: 88%;
      }
      .separator:last-of-type {
        visibility: visible;
      }
    }
    .activities-day:last-of-type .separator:last-of-type {
      display: none;
    }
  }
}
