.printed-day-view .hx-calendar-staff-days {
  padding-left: 1px;
  border-left: 1px solid #000000;
  position: relative;
  width: 100%;

  & > div:first-child {
    height: 100%;
  }

  .item-wrapper {
    position: relative;
    z-index: 2;
    width: 92.307692307692308%;
  }

  .rulers {
    z-index: 1;
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .rulers div:first-child,
  .rulers div:nth-child(2),
  .rulers div:nth-child(3),
  .rulers div:last-child {
    height: 100%;

    span {
      display: block;
      height: 100%;
      border-left: 1px solid #000000;
    }
  }

  .rulers div:first-child {
    width: 15.384615384615385%;

    span {
      border: 0;
    }
  }

  .rulers div:nth-child(2) {
    width: 30.769230769230769%;
  }

  .rulers div:nth-child(3) {
    width: 46.153846153846154%;
  }

  .rulers div:last-child {
    width: 7.692307692307692%;

    span {
      border-width: 2px;
    }
  }
}
