.shift-swap-container {
    .my-shift-icon {
        display: flex;
        background-color: #d25b13;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
    }
    
    .shift-swap-icon-container {
        display: flex;
        align-items: center;
        padding-left: 0.625em;
    }
}

.shift-swap-list {
  overflow-y: auto;
  height: 52em;
}

.my-shift-icon-empty-state {
  display: flex;
  background-color: rgb(0 0 0 / 10%);
  width: 3.125em;
  height: 3.125em;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}