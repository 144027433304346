.hx-print-view .printed-day-view {
  .hx-calendar-shift-container:not(:nth-child(2)) {
    border-top: 2px solid #000000;
  }

  .hx-calendar-shift {
    border-left: 0;
    border-right: 1px solid #383838;

    padding: 0 0.076923076923077em 0 0.153846153846154em;
    background-color: #ffffff !important;

    header aside {
      display: block;
      background-color: #ffffff !important;
    }

    .row {
      border-left: 0;
      border-right: 0;
    }
  }
}

.hx-print-view .printed-day-view .hx-calendar-shift aside span:last-child {
  color: #383838 !important;
  font-size: 0.769230769230769em;
  display: block;
  height: 100%;
}
