.hx-job-processing-state {
  position: fixed;
  left: 30px;
  bottom: 30px;
  background-color: rgba(82, 97, 115, 0.75);
  color: white;
  line-height: 32px;
  font-size: 14px;
  letter-spacing: -0.1px;
  padding: 0 2em 0 0.5em;
  z-index: 111;
  width: 306px;
  border-radius: 6px;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  box-shadow: 0 4px 8px 0 rgba(82, 97, 115, 0.15);

  &::after {
    position: absolute;
    right: 0.5em;
  }

  &.processing::after {
    content: '\e916';
    font-family:
      humanics-icons-indicators,
      siemens sans,
      sans-serif !important;
  }

  &.done::after {
    content: '\e906';
    font-family:
      Icons,
      siemens sans,
      sans-serif !important;
  }

  &.error {
    background-color: darkred;
  }
}
