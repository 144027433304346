.request-time-off-details-card {
  sh-card {
    height: 50%;
    max-height: 10em;
  }
}

.request-time-off-form-delete-button {
  padding-left: 16.1875em;
}
