.extended-edit-preview {
  //in-lined
  position: relative;
  // padding-left: 77.75px;
  // padding-right: 77.75px;
  padding-left: 300px;
  padding-right: 300px;
  // padding-top: 50px;

  .icon-minus {
    font-size: 25px;
  }
}

.add-details-page-button {
  position: absolute;
  top: 20px;
  left: 497.05px;
  width: 60px;
  height: 60px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
  background-color: #4a90e2;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  z-index: 4;
  padding-top: 1.5px;
  font-size: 30px;
}

.add-details-page-hover {
  position: absolute;
  left: 450.25px;
  top: -20px;
  width: 150px;
  height: 30px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: #ffffff;
  border-radius: 15px;
  background-color: #525b65;
  padding-top: 6px;
  opacity: 0.5;
}

.delete-details-page-button {
  position: absolute;
  top: 20px;
  left: 230px;
  width: 40px;
  height: 40px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
  // background-color: #4a90e2;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  z-index: 4;
  padding-top: 1.5px;
  font-size: 30px;
}

.delete-details-page-hover {
  position: absolute;
  left: 175px;
  top: -20px;
  width: 150px;
  height: 30px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -0.1px;
  color: #ffffff;
  border-radius: 15px;
  background-color: #525b65;
  padding-top: 6px;
  opacity: 0.5;
}

.extended-preview-content {
  //in-lined
  width: 460px;
  margin-bottom: 50px;
  height: auto;
  min-height: 600px;

  position: relative;
  border: solid 0.5px #cacada;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.35);
  z-index: 1;
}

.extended-preview-content:hover {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.27);
}

.extended-preview-content.uneditable:hover {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.35);
}

.add-image-text {
  position: absolute;
  z-index: 1;
}

.placeholder-rectangle {
  height: 69px;
  background-color: #4a90e2;
}

.hover-word {
  position: absolute;
  left: 220px;
  top: 75px;
}

.hover-image {
  position: absolute;
  left: 220px;
  top: 170px;
}

.hover-text {
  position: absolute;
  left: 220px;
  top: 180px;
}

div.extended-rectangle {
  width: 16px;
  height: 100%;
  background-color: #4a90e2;
  right: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.circle {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 1.6px;
}
