.placeholder-link {
  width: 460px;
  height: 69px;
  background-color: #4a90e2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.placeholder-link:before {
  content: '';
  width: 800%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -500%;
  z-index: -5;
}

.dotted-border {
  width: 440px;
  height: 49px;
  border: dashed 1px #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
}
