.equipment-description {
  font-size: 14px;
  color: #808fa4;
  margin-bottom: 5px;
  sh-text {
    font-size: inherit !important;
    color: inherit;
  }
}

.equipment-skill {
  .name {
    color: black;
  }
}
