.time-off-details-card {
    height: 4em;
}

.icon-width {
    width: 6%;
}

.text-width {
    width: 65%
}

.bottom-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.comment-wrapper {
    padding-left: 2.75em;
}