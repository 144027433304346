div.master-announcement-container {
  display: flex;
  flex-direction: column;
  height: 75vh;
  background-color: #ffffff;

  // looks cool, doesn't it?
  sh-button._submit,
  sh-button.cancel-announcement-form[color='secondary'],
  .extended-preview-content {
    transition: all 0.3s;
  }

  sh-button._submit,
  sh-button.cancel-announcement-form[color='secondary'] {
    margin: 0 0.5rem;
  }
}

div.master-announcement-main {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: #ffffff;

  div.metadata-container {
    background-color: rgba(0, 0, 0, 0);
    width: 250px;
    position: fixed;
    top: 130px;
    left: calc(50% + 230px + 18px); // half of the screen + half the card width + the design separation (18px)
  }

  button.primary-disabled {
    background-color: #b5d6fc;
  }
}

div.master-announcements-templates-header {
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

div.left-hand-side {
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #f1f1f1;
}

button.button-close {
  margin: 20px;
  position: absolute;
  z-index: 1;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.09px;
  color: #4e5e7a;
  font-size: 15px;

  .icon-left {
    font-size: 18px;
    margin-right: 5px;
  }
}

.preview-announcements {
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #f1f1f1;
  padding-top: 50px;
  padding-bottom: 310px;
  min-height: 340px;
  position: relative;
}

div.right-hand-side {
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  border-top: solid 1px #dce2ed;
  overflow: auto;
  right: 0;
  position: relative;
  text-align: center;
}

div.right-hand-side-components {
  position: relative;
  background-color: #ffffff;
  height: 87%;
  overflow: auto;
}

.extended-preview-announcements {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  max-width: 100vw;
}

.submit-buttons {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 10%;
  bottom: 5%;
}

.submit-buttons button.button.secondary {
  margin-right: 16px;
}

button.float-right._submit {
  background-color: #4a90e2;
  color: #ffffff;
}

div.rectangle {
  width: 16px;
  height: 100%;
  background-color: #4a90e2;
  right: 10px;
  position: absolute;
  left: -20px;
}

div.front-and-center {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fbfbfb;
  overflow-y: auto;
  overflow-x: hidden;

  button.button-close {
    top: 38px;
  }

  .preview-announcements {
    background-color: #ffffff;
  }
}

.warning textarea.form-inline {
  border: 2px solid #f44336;
}

div.warning-text {
  width: 300px;
  color: #e00000;
  font-size: 10px;
  letter-spacing: -0.07px;
  position: absolute;
  font-weight: 600;
}
