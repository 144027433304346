.chart-header-alignment {
  margin-bottom: -1.5rem;
  padding-top: 1rem;
}

.trend-icon {
  float: right;
  margin-top: -5.5rem;
  margin-right: 1rem;
}
