.constraint {
  display: flex;
  height: 42px;
  align-items: center;
  flex-wrap: nowrap;
}

.autoschedule-view-container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 0;
  height: 80vh;
  min-width: calc(100% - 16.7rem);
}

.contain {
  width: 118rem;
  height: 50rem;
}

.autoschedule-modal-footer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.calendar-modal-footer {
  height: 100%;
  margin-top: 1rem;
}

.autoschedule-modal {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.auto-schedule-modal-body{
  height: 100%;
  margin-left: -1rem;
}

.z1000{
  z-index: 1000;
}

.info-icon-margin{
  margin-top: 1.3rem
}

.ranking-importance-tooltip{
  max-width: fit-content
}

.autoscheduler-footer{
  bottom: 1rem;
  left: -1rem
}

.parameter-rule{
  border: 1px solid rgb(233 233 233);
  width: 99.6%;
}
