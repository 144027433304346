.hx-calendar-group .hx-calendar-header-month {
  display: none;
}

.hx-print-view {
  .hx-calendar-header-month {
    flex-wrap: nowrap;
    display: flex !important;

    div {
      border-left: 2px solid #383838;
      border-right: 0;
      border-bottom: 0;
      border-top: 0;
      font-size: 1.076923076923077em;
      text-transform: none;
      padding: 0.142857142857143em;
      background-color: #e6e6e6 !important;
      color: #383838;
      font-weight: bold !important;
      letter-spacing: -0.021428571428571em;
      line-height: 1.142857142857143em;
    }

    div:first-child {
      border-left: 2px solid #383838;
    }

    div:last-child {
      border-right: 0;
    }
  }
}
