$color-cadet-blue: #a2b2c4;
$color-back-haze: #f6f6f8;

.calendar-select-unit-container {
  height: 6.1538em;
  position: relative;

  & > div:first-child {
    min-width: 2.307692307692308em;
  }

  & > a {
    color: $color-cadet-blue;
    width: 3em;
    background-color: $color-back-haze;
    height: 100%;
    justify-content: space-around;
    padding-right: 0.4em;
  }

  button {
    text-align: left;
    height: 100%;
    padding: 0 1em;
    border: 0;
    justify-content: normal;

    span {
      font-weight: bold;
      line-height: 1.5;

      sh-text {
        color: white;
      }
    }

    .unit-name {
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      text-align: left;
      .icon-Chevron---Down {
        position: static;
        margin-left: 0.7em;
        font-size: 0.7em;
      }
    }
  }

  > button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    &.active {
      background-color: #fff;

      .icon-Chevron---Down {
        transform: rotate(180deg);
        display: inline-block;
      }

      + .hx-calendar-unit-options {
        visibility: visible;
        opacity: 1;
        background: #fbfbfb;
      }
    }

    &:focus .icon-Chevron---Down {
      display: inline-block;
    }

    &:focus:not(.active) + .hx-calendar-unit-options {
      visibility: hidden;
    }
  }
}

.select-unit-options {
  position: absolute;
  z-index: 100000;
  min-width: 20em;
  top: 4em;
  left: 1em;
  font-size: 1.07692em;
  font-weight: 600;
  border-bottom-left-radius: 0.85714em;
  border-bottom-right-radius: 0.85714em;
  background-color: #fbfbfb;
  border: 1px solid #dee3ec;
  overflow: auto;

  & > div {
    border-right: 0;
    border-bottom: 1px solid #dee3ec;
    padding: 0;
  }
  & > div > a {
    color: #808fa4;
    padding: 1.03846em 1.53846em 1.03846em 1.92308em;
    display: block;
  }
  & > div > a > .label {
    margin-top: 0.15385em;
  }
  & > div.disabled {
    background-color: #f1f2f3;
  }
  & > div.disabled > a {
    color: rgba(128, 143, 164, 0.5);
    pointer-events: none;
  }
  & > div:last-child {
    border-bottom: 0;
  }
  & > div:hover:not(.disabled) {
    background-color: #ffffff;
  }
  & > div:hover:not(.disabled) > a {
    color: #535b65;
  }
  & > div:last-child:hover:not(.disabled) {
    border-bottom-left-radius: 0.85714em;
    border-bottom-right-radius: 0.85714em;
  }
}
