@media print {
  // set to standard A4 size
  @page {
    page-break-before: avoid;
    size: A4 landscape;
    margin: 0;
    background-color: #ffffff;
    counter-increment: page;
  }

  html body > *:not(._mainContainer):not(.hx-pdf-template) {
    display: none !important;
  }

  html body ._mainContainer {
    display: block;
    height: auto;

    ._calendarGridWrapper {
      display: block;
      overflow-y: auto;
      height: auto;

      & > div > *:not(.hx-print-view) {
        display: none !important;
      }
    }

    & > *:not(._calendarGridWrapper) {
      display: none !important;
    }
    // ._calendarGridWrapper {

    // }
  }

  html body .hx-pdf-template #hx-calendar {
    overflow: visible;
  }

  .hx-print-view {
    display: block;
    font-size: 1em;
  }
}
