.hx-calendar-indicators-button.active + .custom-dropdown-selector {
  display: flex;
}

.custom-dropdown-selector {
  display: none;
  margin-top: 14px;
  margin-left: 10px;
  font-size: 1em;
  min-width: 30em;
  max-width: 35em;
  border-radius: 0.46154em;
  box-shadow: 0 2px 34px 0 rgba(34, 50, 71, 0.25);
  position: absolute;
  z-index: 10000;

  .selectArrowUp {
    position: absolute;
    top: -0.65em;
    left: 5px;
    font-size: 1.53846em;
    margin-left: calc(50% - 0.25em - 20px);
    z-index: 10000;
  }

  ul {
    margin: -0.76923em 0 -0.76923em 1.53846em;
    width: calc(100% - 1.53846em);

    label {
      text-align: end;
      display: inline-block;
    }

    .form-item {
      padding: 0;
      margin: 10px 0;
    }
  }
}
