.hx-print-view {
  .bottom-colontitle {
    display: flex;
    flex-direction: column;
    margin: 2.461538461538462em 0 1.153846153846154em;
    height: 2.230769230769231em;
    width: 100%;

    & > div {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    & > div:first-child {
      padding: 0 1.230769230769231em;
    }

    & > div > div:first-child {
      line-height: 1em;
      margin-right: 5em;
      padding-left: 2.285714285714286em;
      font-size: 0.538461538461538em;

      color: #383838;
    }

    .print-details div:first-child {
      margin-bottom: 0.285714285714286em;
    }

    & > div > div:first-child div:last-child span {
      margin-right: 0.714285714285714em;
    }

    & > div > div:last-child {
      line-height: 1em;
      padding-right: 16px;
      font-size: 0.692307692307692em;
      flex-grow: 0;
    }

    & > div > div:nth-child(2) {
      line-height: 1;
      font-size: 0.538461538461538em;
      flex-grow: 1;
    }

    hr {
      border: 0;
      height: 1px;
      width: 100%;
      background-color: #383838 !important;
      margin: 0 0 1.076923076923077em 0;
    }

    .legend span {
      position: absolute;
      left: 0;
      top: -0.384615384615385em;
      line-height: 1em;
      margin-right: 0.153846153846154em;
      font-size: 3.714285714285714em;
      color: #383838 !important;
    }

    .legend > div {
      display: inline-block;
      position: relative;
      padding-left: 2.857142857142857em;
      margin-right: 2.857142857142857em;
      color: #383838 !important;
    }

    .legend > div:nth-last-child(-n + 1) {
      padding-left: 3.8em;
      margin-right: 3.8em;
    }
  }
}

.shift-mnemonics {
  font-size: 0.538461538461538em;
  line-height: normal;
  color: #383838;
  padding-left: 2.285714285714286em;

  span {
    margin-right: 2em;
  }
}
