.calendar-item-bar-label {
  max-width: 3.25em;
  height: 1em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.01em;
  text-align: left;
  overflow-y: visible !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}