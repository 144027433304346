div.autocomplete-container {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

input.save-template-input {
  height: 39px;
  width: 361px;
  margin-top: 10px;
}

input.warning-box {
  border: 2px solid #f44336;
}

.no-suggestions {
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #bbb;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-width: 0;
  max-height: 143px;
  overflow-y: auto;
  width: 361px;
  padding-left: 0;
  margin-left: 0;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: rgba(231, 231, 231, 0.2);
  cursor: pointer;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #f1f1f1;
}
