.hx-calendar-indicators-button {
  &.active + .display-options {
    display: flex;
    margin-left: 1.25em;
    margin-top: -3.25em;
  }

  .icon-settings {
    height: 1.257em;
  }
}

.display-options {
  display: none;
  margin-top: 14px;
  margin-left: 10px;
  font-size: 1em;
  min-width: 30em;
  max-width: 35em;
  border-radius: 0.46154em;
  box-shadow: 0 2px 34px 0 rgba(34, 50, 71, 0.25);
  position: absolute;
  z-index: 10000;

  ul {
    display: block;
    margin: 0 auto;
    padding: 20px;

    label {
      text-align: end;
      display: inline-block;
    }

    .form-item {
      padding: 0;
      margin: 10px 0;
    }
  }

  &:before {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    border-top: 0.75em solid transparent;
    border-bottom: 0.75em solid transparent;
    border-right: 1em solid #fff;
    top: 1.75em;
    left: -0.75em;
  }

  .form-item {
    width: 95%;
  }

  .view-selector {
    margin: 0 auto;
  }

  input[type='radio'] {
    display: none;

    + label {
      display: inline-flex;
      width: 13.3076923em;
      height: 2.15384615em;
      border-radius: 0.46153846em;
      border: solid 1px #459afe;
      color: #808fa4;
      text-transform: none;
      font-weight: 500;
      font-size: 1em;
      padding: 2px;

      &:nth-child(2) {
        border-right: none;
      }

      &:nth-child(2),
      &:nth-child(2) span {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:nth-child(4),
      &:nth-child(4) span {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      span {
        display: flex;
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0.46153846em;
        align-items: center;
        justify-content: center;
      }
    }

    &:checked + label span {
      background-color: #459afe;
      color: #fff;
      font-weight: 600;
    }
  }
}
