/* Styles that were inline in previous app were moved to this file */
textarea,
select,
button {
  font-family: inherit;
}
.he-header {
  background-color: #2c343e;
  .he-nav {
    justify-content: initial;
    padding-left: 3.5em;
  }
}

.darkgray1 {
  color: #526173;
}

.he-header-logo {
  position: absolute;
  width: 76px;
  height: 76px;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.25);

  img {
    position: absolute;
    left: 19px;
    width: 40px;
  }
}

// .he-header-logo {
//   img {
//     position: absolute;
//     left: 19px;
//     width: 40px;
//   }
// }

// .he-header {
//   .he-nav {
//     justify-content: initial;
//     padding-left: 3.5em;
//   }
// }

.inactive-submenu {
  &:hover {
    opacity: 1 !important;
  }
  opacity: 0.5 !important;
}

.active-submenu {
  opacity: 100 !important;
  cursor: default;
  letter-spacing: -0.1px;
  color: #ffffff;
}

.open2 {
  margin-right: 22px;
  position: fixed;
}

.he-header-account {
  position: relative;
}

.he-account-dropdown aside.tiny.text-right.opened-dropdown-aside {
  margin-right: 9px !important;
}

.form-header {
  font-weight: 700;
  text-rendering: optimizeLegibility;
  letter-spacing: -0.1px;
  font-size: 18px;
  line-height: 44px;
  color: #ffffff;
  background-color: #434f5d;
  padding: 0 0 0 20px;
}

a.reset {
  cursor: pointer;
}

.admin-table td:first-child,
.admin-table td:last-child {
  padding-right: 0.833333333333333em;
  padding-left: 0.833333333333333em;
}

.admin-table .row-in-group td:first-child,
.admin-table .row-in-group td:last-child {
  padding-left: 1.666666666666667em;
  padding-right: 1.666666666666667em;
}

.admin-table .row-in-group td.image-cell {
  padding: 0.1em 0.2em;
  width: 5em;
}

.admin-table.clickable tbody tr:hover {
  background-color: #91909021;
}

.button.secondary {
  background-color: transparent;
  border: 1px solid #dfe5ef;
  padding: 0.38462em 1.53846154em;

  &:hover {
    background-color: #fbfbfb;
  }
}

.button.primary {
  border: 1px solid #dfe5ef;
  padding: 0.38462em 1.53846154em;
  color: #fff;
  background-color: #459afe;

  &:hover {
    background-color: #669de0;
  }
}

.hx-calendar-shift {
  cursor: pointer;
}

.hx-calendar-shift section .label.hidden {
  display: none;
}

.hx-calendar-shift:hover section .label.hidden {
  display: flex;
}

.dateRange-menu-button {
  width: 124px;
  height: 42px;
  border-radius: 6px;
  background-color: #fdfdfd;
  border: solid 1px #dfe5ef;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 1em;
}

.hx-calendar-staff {
  .col * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
  }
}

.document-blank-icon {
  display: block;
  background: transparent url(styles/web_v3/document-blank-icon.svg) no-repeat center center;
  background-size: contain;
  width: 23px;
  height: 23px;
}

.activities-icon {
  display: block;
  background: transparent url(styles/web_v3/icon-tiny-menu.svg) no-repeat center center;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.active .activities-icon {
  display: block;
  background: transparent url(styles/web_v3/icon-tiny-menu-white.svg) no-repeat center center;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.slot-recommender-icon {
  display: block;
  background: transparent url(styles/web_v3/icon-slot-recommender.svg) no-repeat center center;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.active .slot-recommender-icon {
  display: block;
  background: transparent url(styles/web_v3/icon-slot-recommender-white.svg) no-repeat center center;
  background-size: contain;
  width: 21px;
  height: 21px;
}

.icon-staff {
  display: block;
  background: transparent url(styles/web_v3/icon-staff.svg) no-repeat center center;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.active .icon-staff {
  display: block;
  background: transparent url(styles/web_v3/icon-staff-white.svg) no-repeat center center;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon-dashboard {
  display: block;
  background: transparent url(styles/web_v3/icon-dashboard.svg) no-repeat center center;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.active .icon-dashboard {
  display: block;
  background: transparent url(styles/web_v3/icon-dashboard-white.svg) no-repeat center center;
  background-size: contain;
  width: 24px;
  height: 24px;
}

.icon-engagement-center {
  content: url(styles/web_v3/skills-check-icon.png);
  background-size: contain;
  width: 24px;
  height: 24px;
}

.active .icon-engagement-center {
  content: url(styles/web_v3/skills-check-icon.png);
}

.icon-nav-more-item {
  content: url(styles/web_v3/icon-nav-more-item.svg);
}

.icon-announcements {
  content: url(styles/web_v3/announcements-icon-off.svg);
}

.active .icon-announcements {
  content: url(styles/web_v3/announcements-icon-on.svg);
}

.active .icon-nav-more-item {
  content: url(styles/web_v3/icon-nav-more-item-white.svg);
}

.time-off-icon {
  display: block;
  background: transparent url(styles/web_v3/vacation.svg) no-repeat center center;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.staff-icon {
  display: block;
  background: transparent url(styles/web_v3/Staff.svg) no-repeat center center;
  background-size: contain;
  width: 24px;
  height: 23.4px;
}

.view-icon {
  display: inline-block;
  background: transparent url(styles/web_v3/view.svg) no-repeat center center;
  width: 25px;
  height: 25px;
}

.edit-icon {
  display: inline-block;
  background: transparent url(styles/web_v3/edit.svg) no-repeat center center;
  width: 25px;
  height: 25px;
}

.duplicate-icon {
  display: inline-block;
  background: transparent url(styles/web_v3/duplicate.svg) no-repeat center center;
  width: 25px;
  height: 25px;
}

.delete-icon {
  display: inline-block;
  background: transparent url(styles/web_v3/delete2.svg) no-repeat center center;
  width: 25px;
  height: 25px;
}

.cancel-icon {
  display: inline-block;
  background: transparent url(styles/web_v3/close.svg) no-repeat center center;
  width: 25px;
  height: 25px;
}

.draft-icon {
  display: inline-block;
  background: transparent url(styles/web_v3/draft.svg) no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.scheduled-icon {
  display: inline-block;
  background: transparent url(styles/web_v3/pending.svg) no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.sent-icon {
  display: inline-block;
  background: transparent url(styles/web_v3/success.svg) no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.danger-icon {
  display: inline-block;
  background: transparent url(styles/web_v3/error.svg) no-repeat center center;
  width: 20px;
  height: 20px;
  background-size: contain;
}

.document-selection-arrow {
  display: block;
  background: transparent url(styles/web_v3/arrow-doc.svg) no-repeat center center;
  background-size: contain;
  width: 20px;
  height: 20px;
}

@font-face {
  font-family: 'New-Icons';
  src: url('styles/web_v3/new-icons.eot?cf9rl');
  src:
    url('styles/web_v3/new-icons.eot?cf9rl#iefix') format('embedded-opentype'),
    url('styles/web_v3/new-icons.ttf?cf9rl') format('truetype'),
    url('styles/web_v3/new-icons.woff?cf9rl') format('woff'),
    url('styles/web_v3/new-icons.svg?cf9rl#hx-font_icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='new-icon-'],
[class*=' new-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family:
    New-Icons,
    siemens sans,
    sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.new-icon-cancelled-secondary-shift:before {
  content: '\e900';
}

.new-icon-cancelled-shift:before {
  content: '\e901';
}

.new-icon-class:before {
  content: '\e902';
}

.new-icon-week:before {
  content: '\e903';
}

.new-icon-month:before {
  content: '\e904';
}

.new-icon-share:before {
  content: '\e905';
}

.new-icon-vacation:before {
  content: '\e906';
}

.new-icon-event:before {
  display: block;
  content: url(styles/web_v3/event-icon.svg);
  background-size: contain;
  width: 25px;
  height: 25px;
}

.new-icon-event-white:before {
  display: block;
  background-color: #fff;
  content: '';
  background-size: contain;
  -webkit-mask-image: url(styles/web_v3/event-icon.svg);
  mask-image: url(styles/web_v3/event-icon.svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  width: 1em;
  height: 2em;
}

@font-face {
  font-family: 'fullview-cell-icons';
  //src: url('styles/web_v3/fullview-cell-icons.eot?cf9rl');
  //src: url('styles/web_v3/fullview-cell-icons.eot?cf9rl#iefix') format('embedded-opentype'),
  //url('styles/web_v3/fullview-cell-icons.ttf?cf9rl') format('truetype');
  src: url('styles/web_v3/fullview-cell-icons.woff?cf9rl') format('woff');
  //url('styles/web_v3/fullview-cell-icons.svg?cf9rl#fullview-cell-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fullview-cell-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family:
    fullview-cell-icons,
    siemens sans,
    sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  //line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.bg-mystic {
    background-color: #eff1f5;
  }

  svg path {
    fill: currentcolor;
  }
  span {
    display: inline-flex;
    width: 33.33%;
    justify-content: center;

    &:nth-child(1),
    &:nth-child(3) {
      color: #b9cae5;
    }
  }
}

.hidden {
  display: none;
}

@font-face {
  font-family: 'Humanics-Indicators';
  // src: url('styles/web_v3/humanics-icons-indicators.eot');
  // src: url('styles/web_v3/humanics-icons-indicators.eot#iefix') format('embedded-opentype'),
  // url('styles/web_v3/humanics-icons-indicators.ttf') format('truetype'),
  src: url('styles/web_v3/humanics-icons-indicators.woff') format('woff');
  // url('styles/web_v3/humanics-icons-indicators.svg#hx-font_icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-indicator-'],
[class*='icon-indicator-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-indicator-add-circle:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e900';
}

.icon-indicator-arrow-down:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e901';
}

.icon-indicator-arrow-left:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e902';
}

.icon-indicator-arrow-right:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e903';
}

.icon-indicator-arrow-up:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e904';
}

.icon-indicator-calendar:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e905';
}

.icon-indicator-call-back:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e906';
}

.icon-indicator-call:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e907';
}

.icon-indicator-checkmark:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e908';
}

.icon-indicator-chevron-down:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e909';
}

.icon-indicator-chevron-left:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e90a';
}

.icon-indicator-chevron-right:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e90b';
}

.icon-indicator-chevron-up:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e90c';
}

.icon-indicator-circle:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e90d';
}

.icon-indicator-clock:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e90e';
}

.icon-indicator-close:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e90f';
}

.icon-indicator-dot:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e910';
}

.icon-indicator-edit:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e911';
}

.icon-indicator-exclamation-point:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e912';
}

.icon-indicator-favorite-filled:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e913';
}

.icon-indicator-favorite-outline:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e914';
}

.icon-indicator-info:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e915';
}

.icon-indicator-loading:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e916';
}

.icon-indicator-lock:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e917';
}

.icon-indicator-minus:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e918';
}

.icon-indicator-money-sign:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e919';
}

.icon-indicator-moon:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e91a';
}

.icon-indicator-pin-slash:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e91b';
}

.icon-indicator-pin:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e91c';
}

.icon-indicator-plus:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e91d';
}

.icon-indicator-printer:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e91e';
}

.icon-indicator-question-mark:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e91f';
}

.icon-indicator-refresh:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e920';
}

.icon-indicator-sun:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e921';
}

.icon-indicator-swap-slash:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e922';
}

.icon-indicator-swap:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e923';
}

.icon-indicator-zoom:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e924';
}

.icon-indicator-note:before {
  font-family:
    Humanics-Indicators,
    siemens sans,
    sans-serif;
  content: '\e925';
}

.hx-mfa {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;

  .hx-mfa-container {
    top: 37.5%;
    transform: translateY(-50%);
    position: relative;

    .logo {
      width: 147px;
      height: 32px;
      background: transparent url(styles/web_v3/logo-b.png) no-repeat;
      background-size: contain;
    }

    > .hx-form {
      margin-top: 24px;
      border-radius: 8px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);
      background-color: #ffffff;
      padding: 32px 64px;
      font-size: 11px;

      header h3 {
        font-size: 20px;
        line-height: 1.2;
        letter-spacing: -0.3px;
        color: #4e5e7a;
      }

      section {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;

        label {
          margin-top: 16px;
          display: block;
          position: relative;

          > .hx-form-label {
            display: block;
            font-size: 10px;
            font-weight: 900;
            line-height: 1.2;
            letter-spacing: 0.5px;
            color: #808fa4;
            text-transform: uppercase;
            vertical-align: middle;
            user-select: none;
          }

          input {
            margin-top: 2px;
            border-radius: 6px;
            border: solid 2px #bfcbde;
            background-color: #ffffff;
            display: block;
            height: 28px;
            line-height: 24px;
            width: 100%;
            padding: 0 6px;
            font-size: 13px;
            font-weight: 600;
            color: #808fa4;
          }
        }
      }

      footer {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;

        &.right-aligned {
          justify-content: flex-end;
        }

        .button:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
}

.equipment-form > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'header header header'
    'image top-sidebar top-sidebar'
    'image bottom-sidebar bottom-sidebar'
    'footer footer footer';
}

.equipment-form textarea {
  resize: none;
}

.equipment-form .header {
  grid-area: header;
}

.equipment-form .image {
  grid-area: image;
}

.equipment-form .top-sidebar {
  grid-area: top-sidebar;
}

.equipment-form .bottom-sidebar {
  grid-area: bottom-sidebar;
}

.equipment-form .footer {
  grid-area: footer;
}

sh-tooltip.shui-tooltip-custom {
  top: 0.5rem !important;
}
