$color-heather: #b2c0d1;
$color-rockblue: #9eb1c8;
$color-white: #ffffff;

.hx-expertise-form {
  .datePicker {
    label {
      padding-top: 2em;
      color: $color-heather;
    }

    input[type='checkbox'] {
      display: none;

      & + div {
        display: flex;
        align-items: center;
        vertical-align: middle;
        justify-content: space-around;
        font-size: 1.076923em;
        height: 3em;
        width: 3em;
        border-radius: 1.5em;
        color: $color-heather;
        cursor: pointer;
        margin: 0.2em auto 2em;
        line-height: normal;
        border: 1px solid #dee5ef;

        &:hover {
          background: rgba($color-rockblue, 0.25);
        }
      }

      &:checked + div {
        color: $color-white;
        background: $color-rockblue;

        &:hover {
          color: $color-white;
          background: rgba($color-rockblue, 0.75);
        }
      }
    }
  }

  .preview {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1.076923em 0 0 0.5714em;
  }

  .separator {
    height: 1px;
    width: 100%;
    background: #dee5ef;
    margin: 0;
  }

  .form-extender {
    width: 100%;
    sh-button {
      margin: 20px 0;
    }
  }
}
