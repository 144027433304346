.hx-facility-users-row:hover {
  background-color: #91909021;
}

.hx-facility-users-row {
  & > div {
    margin: auto 0;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
