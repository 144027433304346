.hx-print-view {
  display: none;
  .print-table {
    page-break-before: always;
  }

  .print-table td {
    padding: 0;
    border: 0;
  }

  table thead,
  table tbody {
    border-spacing: 0;
  }
}
