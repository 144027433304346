.hx-pending-requests-container {
  .hx-date-range {
    padding-left: 3em;
    padding-right: 3em;

    .hx-time-off-schedule {
      border-top: 1px solid #979797;
      border-top-left-radius: 0.30769231em;
      border-top-right-radius: 0.30769231em;
    }
  }

  .hx-date-range .hx-time-off-schedule,
  .hx-group .hx-calendar-group,
  .hx-shift .hx-calendar-shift,
  .hx-calendar-staff > .row {
    border-left: 1px solid #979797;
    border-right: 1px solid #979797;
  }

  .hx-calendar-staff > .row._lastRoleStaff {
    border-bottom: 1px solid #979797;
    border-bottom-left-radius: 0.30769231em;
    border-bottom-right-radius: 0.30769231em;
    overflow: hidden !important;
  }
}
