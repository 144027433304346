.shift-event {
  height: 20px;
  border-radius: 4px;
}

.more-events-label {
  display: flex;
  align-items: center;
  font-size: 8px;
  line-height: 16px;
  font-weight: 400;
  width: 100%;
  height: 8px;
  max-width: 100%;
}