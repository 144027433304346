.shift-swap-card-box {
  background-color: #ffffff;
  padding-left: 1em;
  padding-right: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &.initiated {
    background-color: #f5f5f5;
  }

  &.past-date {
    opacity: 0.5;
    background-color: #f5f5f5;
    pointer-events: none;
  }
}
