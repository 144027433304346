.hx-pending-requests-container {
  .hx-shift {
    padding-left: 3em;
    padding-right: 3em;

    & > .hx-calendar-shift {
      box-shadow: 0 0.53846em 1.69231em rgba(0, 0, 0, 0.15);
      border-top: 1px solid #b9cae5;
      border-bottom: 1px solid #b9cae5;
      background-color: #d7e0ef;
    }

    & > .hx-calendar-shift > .row {
      height: 2.153846153846154em;
    }
  }

  .hx-calendar-staff {
    section {
      .bg-mystic {
        background-color: #eff1f5;
      }
    }
  }
}
