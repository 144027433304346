._cell {
  font-size: 30px;
  height: 48px;
  text-align: justify;
  display: block !important;
  line-height: 48px;
  letter-spacing: -1.5px;
  padding: 0 0.6% 0 0.5%;
  user-select: none;
  white-space: nowrap;

  &:not(:empty)::after {
    content: '';
    display: inline-block;
    width: 100%;
  }
}

._weekCells {
  flex-grow: 1;
  flex-basis: 0;

  div {
    padding: 0;
  }

  // TODO add more options if custom-length schedules become available

  &:first-child:nth-last-child(2) {
    &,
    & ~ * {
      ._cell {
        white-space: normal;
      }
    }
  }

  &:first-child:nth-last-child(4) {
    &,
    & ~ * {
      ._cell {
        @media (min-width: 1200px) {
          white-space: normal;
        }
      }
    }
  }

  &:first-child:nth-last-child(6) {
    &,
    & ~ * {
      ._cell {
        @media (min-width: 1600px) {
          white-space: normal;
        }

        @media (max-width: 1450px) {
          letter-spacing: -2.5px; // 27.5
        }

        @media (max-width: 1379px) {
          letter-spacing: -3px; //24.375
        }

        @media (max-width: 1261px) {
          letter-spacing: -4px; // 22.1875
        }

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
          letter-spacing: -4px;
        }

        @media screen and (-ms-high-contrast: active) and (max-width: 1379px),
          (-ms-high-contrast: none) and (max-width: 1379px) {
          letter-spacing: -6px;
        }

        @media screen and (-ms-high-contrast: active) and (max-width: 1261px),
          (-ms-high-contrast: none) and (max-width: 1261px) {
          letter-spacing: -8px;
        }
      }
    }
  }
}
