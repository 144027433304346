@import "/src/styles/foundation/variables";
.hx-box-shadow {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
}

.bg-white-smoke {
    background-color: rgb(245, 245, 245);
}
._mainContainer:has(.staff-role-layout) {
    padding-top: $staff-access-bar-height;
}

.icon-highlight-wrapper {
    display: inline-block;
    padding: 0.3em;
    border-radius: 0.3em;
}

.nav-item {
    &:hover {
        background-color: rgba(var(--ui-1), var(--opacity-7));
    }

    &.active {
        background-color: rgba(var(--ui-1), var(--opacity-6));
    }
}

.left-pane {
    width: $staff-side-bar-width;
}

.main-pane {
    background-color: #ebebeb;
    width: calc(100% - $staff-side-bar-width);
}
