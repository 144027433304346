.hx-open-shifts-status-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 16px;

  .hx-open-shifts-status-item {
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    width: 25%;
    height: 7em;
    padding: 0.5em 0.5em 0;
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'icon number'
      '. descrtiption';

    .status-icon {
      grid-area: icon;
      align-self: end;
      justify-self: center;

      .icon {
        font-size: 2.5em;
        line-height: 1.2em;
      }
    }
    .status-number {
      font-size: 2em;
      line-height: 1.2em;
      align-self: end;
      grid-area: number;
    }
    .status-description {
      font-size: 1.16667em;
      line-height: 1.5em;
      align-self: start;
      grid-area: descrtiption;

      .info {
        margin-left: 0.2em;
        position: relative;
        top: -0.5em;
        font-size: 0.714285em;
      }
    }
  }
}
