.hx-shift-staff-cell-tooltip {
  font-family: 'Nunito Sans', sans-serif !important;
  color: #535b65 !important;

  ul {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.hx-staff-event-info-icon {
  display: block;
  background-color: transparent;
  color: #a3b3ce;
  font-size: 12px !important;

  &.icon-close {
    margin-top: 0.25em;
  }
}

.hx-favorite-day {
  color: #b2c0d1;
}

/* Custom CSS for Avatar Cell */
.cell-tooltip-custom {
  .profile-wrapper {
    .custom-wrapper {
      display: flex;
      padding: 0.38462em 0;
    }
  }
}
