.noborder {
  border-radius: 50%;
  width: 86px;
  height: 86px;
  float: left;
  margin: 6px;
  cursor: pointer;
  border: solid 3px transparent;
  padding: 2px;
}

.badge-name {
  width: 86px;
  height: 18px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #b3b3b9;
  margin-left: 8px;
}
