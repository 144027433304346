._staffMeta {
  position: relative;
  min-width: 17.307692307692308em;

  a.bg-alabaster {
    cursor: pointer;
  }
}

.profile-dropdown-options {
  position: absolute;
  font-size: 1.07692em;
  margin-top: 3.5em;
  padding-right: 1em;
  z-index: 9999;
  width: 16.66667%;
}

.profile-dropdown-options-up {
  position: absolute;
  font-size: 1.07692em;
  margin-top: -12.95em;
  padding-right: 1em;
  z-index: 9999;
  width: 16.66667%;
}

.profile-dropdown-options > ul {
  position: relative;
  width: 100%;
  margin-left: 1px;
  font-weight: 600;
  list-style: none;
  cursor: pointer;

  & > li {
    border-right: 0;
    border-bottom: 1px solid #dee3ec;
    padding: 0;
  }
  & > li > a {
    color: #808fa4;
    padding: 1.03846em 1.53846em 1.03846em 1.92308em;
    display: block;
  }
  & > li:last-child {
    border-bottom: 0;
  }
}

.profile-dropdown-options-up > ul {
  position: relative;
  width: 100%;
  margin-left: 1px;
  font-weight: 600;
  box-shadow: 0 4px 8px 0 rgba(34, 50, 71, 0.2);
  background-color: #fbfbfb;
  border: 1px solid #dee3ec;
  list-style: none;
  cursor: pointer;

  & > li {
    border-right: 0;
    border-bottom: 1px solid #dee3ec;
    padding: 0;
  }
  & > li > a {
    color: #808fa4;
    padding: 1.03846em 1.53846em 1.03846em 1.92308em;
    display: block;
  }
  & > li:last-child {
    border-bottom: 0;
  }
}

@media (max-width: 1379px) {
  ._staffMeta,
  .profile-dropdown-options,
  .profile-dropdown-options-up {
    width: 22%;
  }

  ._staffMeta + section,
  ._staffMeta + .profile-dropdown-options + section,
  ._staffMeta + .profile-dropdown-options-up + section {
    width: 78%;
  }
}

.hx-calendar-staff-info-wrapper {
  height: 3.69231em;
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: center;
  padding: 0 1.53846em;
  margin-right: 1em;
  line-height: 1.5;

  .staff-meta {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
