.hx-cell-tooltip._warning {
  background: white;
  width: 209px;
  min-height: 46px;
  border-radius: 10px;
  position: fixed;
  z-index: 2000;
  display: flex;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);

  .tooltip-error-icon {
    background-image: url(/styles/web_v3/error.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    height: 20px;
    left: 7px;
    position: absolute;
    top: 14px;
    width: 20px;
  }
}

.hx-cell-tooltip._warning:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-top: 1em solid #ffffff;
  position: absolute;
  right: 20px;
  bottom: -1em;
}
.hx-cell-tooltip._warning.caret-left-side:before {
  right: initial;
  left: 20px;
}

.hx-shift-staff-cell-tooltip {
  min-width: 180px;
  transition: transform 0.1s ease-out;

  & > div {
    width: 100%;
    border-radius: 0.46154em;
    box-shadow: 0 2px 34px 0 rgba(34, 50, 71, 0.25);
  }
}
.hx-shift-staff-cell-tooltip i.faded {
  opacity: 0.5;
}

.hx-shift-staff-cell-tooltip li {
  flex-direction: row;

  > div {
    flex: 1;
  }
}

.hx-note {
  display: block !important;
}

._cellSelection {
  position: fixed;
  z-index: 1;
  background-color: rgba(244, 67, 54, 0);
}

._cellSelection:hover {
  border: 1px solid #f44336;
  background-color: rgba(244, 67, 54, 0.2);
  transition: background-color 0.3s ease-out;
}

.hx-notes-container li div {
  overflow: hidden;
  overflow-wrap: break-word;
}
