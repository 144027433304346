.printed-day-view .hx-calendar-staff {
  padding: 0 0.076923076923077em 0 0.153846153846154em;
  border-right: 1px solid #383838 !important;
  background-color: #ffffff;
  border: 0;

  & > .row {
    border-color: #000000;
  }

  .hx-calendar-staff-info-wrapper {
    min-height: 2.153846153846154em;
    padding: 0 0.461538461538462em;
    margin: 0;
    background-color: #ffffff;
  }

  aside._staffMeta {
    background-color: #ffffff;

    .staff-meta {
      font-size: 0.769230769230769em;
    }

    &:before {
      display: none;
    }
  }
}

.printed-day-view .hx-calendar-group-container .hx-calendar-shift-container:last-child .hx-calendar-staff:last-child {
  border-bottom: 0;
}

.printed-day-view
  .hx-calendar-group-container:last-child
  .hx-calendar-shift-container:last-child
  .hx-calendar-staff:last-child {
  border-bottom: 1px solid #000000;
}
