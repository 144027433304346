@import 'styles/foundation/variables';

#cross-hatch-vertical {
  position: absolute;
  z-index: 100;
  height: calc(100% - #{$calendar-grid-month-header-height});
  background-color: rgba(69, 154, 254, 0.04);
  border: solid 1px rgba(69, 154, 254, 0.5);
  box-shadow: inset 0 0 5px rgba(69, 154, 254, 0.5);
  pointer-events: none;
  transition: transform 0.1s ease-out;
  left: 0;
  top: $calendar-grid-month-header-height;
}
