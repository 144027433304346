.hx-print-view .hx-calendar-staff.row.on-call-avatar-row {
  display: none;
}

.hx-print-view .hx-calendar-staff {
  flex-wrap: nowrap;
  line-height: 1.230769230769231em;
  border-left: 2px solid white;
  border-right: 2px solid white;

  .row {
    flex-wrap: nowrap;
    border-width: 1px;
    border-left-width: 2px;
    flex: 0 1 auto !important;
  }

  & > .col-2 {
    min-width: 0;
    border-bottom: 1px solid #383838 !important;

    .hx-calendar-staff-info-wrapper {
      min-height: 1.230769230769231em;
      line-height: 1.111111111111111em;
      height: 1.230769230769231em;
      background-color: #ffffff;
      border: 0;
      padding: 0;
    }

    .hx-calendar-staff-info-wrapper .staff-meta {
      white-space: normal;
      margin-left: 0.777777777777778em;
      font-size: 0.692307692307692em;
      color: #383838 !important;
    }

    &:before {
      display: none;
    }

    .hx-staff-initials,
    .hx-staff-image,
    .hx-staff-avatar,
    .hx-calendar-staff-info-wrapper .staff-meta div {
      display: none;
    }
  }

  & > .col-10 {
    border: 0;
    border-bottom: 1px solid #383838 !important;

    ._weekCells {
      box-sizing: border-box;
      position: relative;
      margin: 0;
      flex-grow: 0;
      flex-basis: auto;
      flex-wrap: nowrap;

      border-left: 2px solid #383838;
      border-right: 0 !important;
    }

    ._cell {
      padding: 0 !important;

      position: relative;
      background-color: #ffffff !important;
      color: #ffffff !important;
      font-size: 1.230769230769231em;
      min-height: 1em;
      height: 1em !important;
      border: 0;
      border-right: 1px solid #383838 !important;
    }

    ._cell.half-opacity {
      opacity: 1;
    }

    ._cell:last-child {
      border-right: 0 !important;
    }

    ._cell.bg-alabaster,
    ._cell.bg-athensgray {
      background-color: #eaeaea !important;
      color: #eaeaea !important;
    }

    ._cell.bg-mystic {
      color: #9b9b9b !important;
      background-color: #9b9b9b !important;
    }

    ._cell.bg-mystic:before {
      color: #ffffff !important;
    }
  }
}
