.bg-selected-shift, .bg-selected-week-view-shift {
  background-color: rgba(73, 144, 226, 0.23);
  border: 1px solid #4990e2;

  &.first-selected,
  &.middle-selected {
    border-right-color: transparent;

    &:last-child::after {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 4px;
      background-color: rgba(73, 144, 226, 0.23);
      right: -4px;
    }
  }

  &.last-selected,
  &.middle-selected {
    border-left-color: transparent;
  }
}

.bg-selected-shift > i {
  color: #4990e2;
}

._cell.bg-error, ._weekCell.bg-error {
  background-color: #fde3e2;
  border-bottom: 2px solid #f0453d;
}

._cell.bg-selected-shift.bg-error, ._weekCell.bg-selected-week-view-shift.bg-error {
  background-color: #fde3e2;
  border-bottom: 1px solid #4990e2;
}

._cell.half-opacity, ._weekCell.half-opacity {
  opacity: 0.5;
}

._cell.isUnderstaffed {
  background: #fdd4d1;
  opacity: 0.5;
}
._cell.left-border {
  border-left: 2px solid #9eb1c8;
  &.event-line-3 {
    background: #f7f8fa;
    margin: 0 0 0 0;
    border-right: none;
  }

  &.event-line-2 {
    background: #f7f8fa;
    margin: 0 0 0 0;
    border-right: none;
  }

  &.event-line-1 {
    background: #9eb1c8;
    margin: 0 0 0 0;
    border-right: none;
  }

  &.event-line-0-75 {
    background: #9eb1c8;
    margin: 0 0 0 25%;
    border-right: none;
  }

  &.event-line-0-5 {
    background: #9eb1c8;
    margin: 0 0 0 50%;
    border-right: none;
  }

  &.event-line-0-25 {
    background: #9eb1c8;
    margin: 0 0 0 75%;
    border-right: none;
  }
}

._cell.right-border {
  border-right: 2px solid #9eb1c8;
  &.event-line-3 {
    background: #f7f8fa;
    margin: 0 0 0 0;
  }

  &.event-line-2 {
    background: #f7f8fa;
    margin: 0 0 0 0;
  }

  &.event-line-1 {
    background: #9eb1c8;
    margin: 0 0 0 0;
  }

  &.event-line-0-75 {
    background: #9eb1c8;
    margin: 0 25% 0 0;
  }

  &.event-line-0-5 {
    background: #9eb1c8;
    margin: 0 50% 0 0;
  }

  &.event-line-0-25 {
    background: #9eb1c8;
    margin: 0 75% 0 0;
  }
}
._cell.event-line-3 {
  background: #f7f8fa;
  margin: 0 0 0 0;
  border-right: none;
}

._cell.event-line-2 {
  background: #f7f8fa;
  margin: 0 0 0 0;
  border-right: none;
}

._cell.event-line-1 {
  background: #9eb1c8;
  margin: 0 0 0 0;
  border-right: none;
}

._cell.event-line-0-75 {
  background: #9eb1c8;
  margin: 0 25% 0 0;
  border-right: none;
}

._cell.event-line-0-5 {
  background: #9eb1c8;
  margin: 0 50% 0 0;
  border-right: none;
}

._cell.event-line-0-25 {
  background: #9eb1c8;
  margin: 0 75% 0 0;
  border-right: none;
}

._cell._yesterdayCell, ._weekCell._yesterdayCell {
  border-right-color: #405772;
}

._cell {
  overflow: hidden;
}

._cell > div {
  width: 100%;
  height: 100%;
}

.shift-icons {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  letter-spacing: 1px;
  margin-bottom: 1px;

  .print {
    line-height: 12px;
    padding: 0;
    margin-top: 0;
    position: relative;
  }

  &.children-1 {
    .print {
      top: 1px;
      font-size: 8px !important;

      .glyph-icon {
        font-size: 20px;
      }
    }
  }

  &.children-2 {
    .print {
      top: -2px;
      font-size: 7px !important;

      .glyph-icon {
        font-size: 16px;
      }
    }

    .print:nth-child(2) {
      top: -6px;
    }
  }

  &.children-3 {
    * {
      height: 33.3333%;
    }

    .print {
      top: -3px;
      font-size: 6px !important;

      .glyph-icon {
        font-size: 14px;
      }
    }
  }
}

.cursor-default {
  cursor: default;
}

.glyph-icon {
  font-size: 2.5rem;
}
