.pendingFooterWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pendingFooterBox {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5em 0;
}

.pendingFooterButtons {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  gap: 1em
}

.declineButton {
  display: flex;
  width: 135px;
  justify-content: flex-end;
}

.acceptButton {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  padding-left: 0em;
}


@media (max-width: 1650px) {
  .pendingFooterButtons {
    width: 55%;
  }
}

@media (max-width: 1500px) {
  .pendingFooterButtons {
    width: 60%;
  }
}

@media (max-width: 1400px) {
  .pendingFooterButtons {
    width: 65%;
  }
}

@media (max-width: 1275px) {
  .pendingFooterBox{
    flex-direction: column;
    align-items: flex-start;
  }

  .pendingFooterButtons {
    width: 100%;
    justify-content: flex-end;
  }
}