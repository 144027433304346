.shiftSwapDetailsCard {
    display: flex;
    flex-direction: row;
    margin-top: 1.2em;
  }
  
  .requestFromDetailsCard {
    display: flex;
    width: 50%;
    align-items: flex-start;
    border-right: 1px solid #0000001a;
    padding-right: -1em;
  }
  
  .requestToDetailsCard {
    display: flex;
    width: 50%;
  }
  
  .textWrapper {
    margin-left: 1.5em;
    margin-top: 0.125em;
  }
  
  .unit-manager-comment-box {
    margin-left: 1.25em;
    margin-top: 0.625em;
}