.staff-event {
  font-size: 1em;
  display: flex;
  align-items: center;
  margin: 0 5px 0 0;

  .content {
    display: flex;
    align-items: center;
  }

  .cursor-default {
    cursor: default;
  }

  .disabled-pointer {
    pointer-events: none;
  }

  .highlighted {
    background-color: #459afe;
    color: #fff;
    border: solid 1px #fff;
    border-radius: 5px;
    height: 1.6em;
    margin: 0.1em;
    padding: 0 1em 0 0.5em;
  }

  .highlighted-shift-view-bar {
    background-color: #459afe;
    color: #fff;
    border: solid 1px #fff;
    border-radius: 12rem;
    height: 100%;
    margin: 0.1em;
    padding: 0 1em 0 0.5em;
  }
}

.staff-event:not(.shift-view) {
  margin-left: 5px;
}

.hx-box-shadow-event-bar {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}