.hx-calendar-indicators-button.active + .custom-select-filter {
  display: flex;
}

.option-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-width: 50px;
}

.selected-value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 0.769230769230769em;

  i {
    margin-left: 5px;
  }

  sh-text {
    width: max-content;
  }
}

.width-160 {
  width: 160px;
}

.width-40 {
  width: 60px;
}

.custom-select-filter {
  display: none;
  margin-top: 14px;
  margin-left: 10px;
  font-size: 1em;
  min-width: 16em;
  max-width: 17em;
  border-radius: 0.46154em;
  box-shadow: 0 2px 34px 0 rgba(34, 50, 71, 0.25);
  position: absolute;
  z-index: 10000;

  .selectArrowUp {
    position: absolute;
    top: -0.65em;
    left: 5px;
    font-size: 1.53846em;
    margin-left: calc(50% - 0.25em - 20px);
    z-index: 10000;
  }

  ul {
    list-style-type: none;
    overflow: hidden;
    margin: 1px 0px 1px 0px;
    width: 100%;

    li {
      padding: 12px 0;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    li:first-child {
      padding-top: 8px;
    }

    li:last-child {
      padding-bottom: 8px;
    }

    ul {
      margin: -0.76923em 0 -0.76923em 1.53846em;
      width: calc(100% - 1.53846em);
    }
  }
}

.hx-filters {
  li:hover button:not(.selected) {
    color: rgb(151, 164, 181);
    opacity: 0.8;
  }
}

.ml1 {
  margin-left: 0.1rem;
}
