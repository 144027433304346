.card-accordion {
  .card-accordion-title {
    padding: 20px;
    color: #535b65;
    transition: padding-bottom 0.1s ease-in-out;

    sh-text[size='title-1'] {
      color: inherit;
      font-size: 14px;
      width: fit-content;
    }

    i {
      margin: 0.5rem;
      cursor: pointer;
    }

    &.active {
      color: #808fa4;
    }
  }
  .card-accordion-content {
    overflow: hidden;
    transition: height 0.1s ease-in-out;
  }
}
