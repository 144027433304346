._more.active + .top-navigation-more {
  display: flex;
  flex-direction: column;
  background-color: #32425e;
}

.top-navigation-more {
  display: none;
  top: -10px;
  margin-top: 38px;
  margin-left: 10px;
  font-size: 1em;
  min-width: 15em;
  max-width: 20em;
  border-radius: 0.46154em;
  box-shadow: 0 2px 34px 0 rgba(34, 50, 71, 0.25);
  position: absolute;
  z-index: 10000;
  padding: 10px;

  .selectArrowUp {
    position: absolute;
    top: -0.65em;
    left: 5px;
    font-size: 1.53846em;
    margin-left: calc(50% - 0.25em - 20px);
    z-index: 10000;
  }

  a {
    padding: 8px 0;

    div {
      font-size: 14px;
      font-weight: normal;
      padding: 4px 0;
      color: #6a7b96;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.hx-top-navigation-item {
  .icon.more {
    font-size: 11px;
  }

  .icon-nav-more {
    height: 21px;
  }

  .icon-nav-more-item {
    height: 22px;
  }
}

.hx-top-navigation {
  .hx-top-navigation-item {
    display: block;
  }

  ._more-items {
    display: none;

    .hx-top-navigation-item {
      &.active:after {
        left: 0.7em;
      }

      .label {
        left: -0.4em;
      }
    }
  }
}

@media (max-width: 1550px) {
  .hx-top-navigation {
    .hx-top-navigation-item:not(._more-items) {
      &:nth-child(n + 5) {
        display: none;
      }
    }

    ._more-items {
      display: block;

      .hx-top-navigation-item {
        display: none;

        &:nth-child(n + 5) {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .hx-top-navigation {
    .hx-top-navigation-item:not(._more-items) {
      &:nth-child(n + 4) {
        display: none;
      }
    }

    ._more-items {
      display: block;

      .hx-top-navigation-item {
        display: none;

        &:nth-child(n + 4) {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 960px) {
  .hx-top-navigation {
    .hx-top-navigation-item:not(._more-items) {
      &:nth-child(n + 3) {
        display: none;
      }
    }

    ._more-items {
      display: block;

      .hx-top-navigation-item {
        display: none;

        &:nth-child(n + 3) {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 840px) {
  .hx-top-navigation {
    .hx-top-navigation-item:not(._more-items) {
      &:nth-child(n + 2) {
        display: none;
      }
    }

    ._more-items {
      display: block;

      .hx-top-navigation-item {
        display: none;

        &:nth-child(n + 2) {
          display: block;
        }
      }
    }
  }
}

@media (max-width: 730px) {
  .hx-top-navigation {
    .hx-top-navigation-item {
      display: none;
    }

    ._more-items {
      display: block;

      .hx-top-navigation-item {
        display: block;
      }
    }
  }
}
