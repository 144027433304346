.left-side {
  width: 50%;
  height: 100%;
  background-color: #fbfbfb;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  overflow-x: hidden;
  zoom: 0.75;
  justify-content: flex-start;

  div.only-preview {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;
    position: relative;
  }

  div.preview-announcements {
    background-color: #fbfbfb;
  }
}

.right-side {
  width: 50%;
}

.save-template-wrapper {
  // border-radius: 6px;
  // box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  height: 661px;
}

.input-template-name-wrapper {
  height: 80%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 230px;

  .save-template-warning {
    position: relative;
    width: 361px;
    padding: 0 5px;
    font-size: 10px;
  }
}

.input-template-name-wrapper .form-label {
  text-transform: none;
  width: 105px;
  height: 32px;
  font-size: 24px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1f1f1f;
}

.save-template-wrapper .right-side {
  margin: 0;
}

.modal-box .save-template .modal {
  // margin: 0px;
}

footer.submit-buttons {
  border: 0px;
}
