.hx-schedule-duration {
  display: inline-block;
  margin-right: 0.76923em;

  sh-popover {
    width: 17em;

    sh-text[color='secondary'] {
      width: 5em;
    }
  }
}
