.calendar-view-body{
    height: 94%;
    overflow-y: auto;
    margin-left: 11px;
    border-top: 1px solid rgb(233 233 233);
    border-bottom: 1px solid rgb(233 233 233);
}

.calendar-view-wrapper{
  box-sizing: border-box
}

.calendar-view-footer-button{
  border-radius: 4rem;
}