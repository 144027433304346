.h100 {
  height: 100%;
}
@import '/src/styles/foundation/variables';

.wrapper-container {
  .hx-announcements.row {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    align-content: center;

    position: fixed;
    top: $access-bar-height + $nav-bar-height;

    h1 {
      font-size: 1.69230769em;
      color: #5f6978;
      line-height: normal;
      padding: 0;
      margin: 0;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.005em;
      text-align: center;
    }

    .title {
      display: none;
    }

    @media (max-width: 1300px) {
      .title {
        display: block;
      }
    }
  }
}

.hx-announcements {
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 10;

  button.primary {
    background-color: #459afe;
  }
}

.go-back-link {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  left: 2em;
  cursor: pointer;

  .back-arrow {
    margin-right: 10px;
    font-weight: bolder;
  }
  .back-arrow:before {
    font-family:
      Humanics-Indicators,
      siemens sans,
      sans-serif;
    content: '\e902';
  }
}
