.myShiftHeaderBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5em 0em;
  }
  
  .myShiftHeader {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: flex-start;
  }
  
  .myShiftIconBox {
    display: flex;
    background-color: #d25b13;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  
  .myShiftTitle {
    width: 96%;
    font: var(--header-1);
    font-size: 16px;
    color: var(--text-primary);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 0.2em;
    pointer-events: auto;
  }
  
  .myShiftHeaderTags {
    display: flex;
    gap: 0.5em;
    width: 50%;
    justify-content: flex-end;
    pointer-events: none;
  }
  
  .myShiftStatusButton {
    display: flex;
    width: 110px;
    justify-content: center;
    align-items: center;
    background-color: #f7eec4;
    border: 2px solid #fae16c;
    border-radius: 15px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .myShiftWithdrawButton {
    display: flex;
    width: 100px;
    justify-content: flex-start;
    padding-left: 0.8em;
  }
  
  
  @media (max-width: 1560px) {
    .myShiftHeader {
      width: 45%;
    }
  
    .myShiftTitle {
      font-size: 14px;
    }
  
    .myShiftHeaderTags {
      width: 55%;
    }
  }
  
  @media (max-width: 1435px) {
    .myShiftHeader {
      width: 40%;
      padding-bottom: 0.3em;
      justify-content: center;
    }
  
    .myShiftHeaderTags {
      width: 60%;
    }
  }
  
  @media (max-width: 1325px) {
    .myShiftHeader {
      width: 30%;
    }
  
    .myShiftHeaderTags {
      width: 70%;
    }
  }
  
  
  @media (max-width: 1155px) {
    .myShiftHeaderBox {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .myShiftHeader {
      width: 100%;
      padding-bottom: 0.3em;
    }
  
    .myShiftHeaderTags {
      width: 100%;
    }
  }
  