.extended-preview-image {
  //in-lined
  width: 100%;
  height: 100%;
  opacity: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  background: white;
}

.extended-preview-image:before {
  background-color: rgba(231, 231, 231, 0.46);
  content: '';
  width: 800%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -500%;
  z-index: -5;
}

.extended-preview-image-unclicked {
  width: 100%;
  height: 100%;
  opacity: 1;
  background: white;
  display: flex;
  flex-direction: row;
  position: relative;
}

.extended-preview-image-unclicked:before {
  content: '';
  width: 800%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -500%;
  z-index: -5;
}

.extended-preview-image:hover {
  cursor: pointer;
}

#uploaded-image {
  //in-lined
  width: 100%;
  // height: 187px;
  margin-top: 0;
  background: rgba(103, 122, 145, 0.13);
}

.image-preview-div {
  //in-lined
  width: 100%;
  height: 187px;
  overflow: hidden;
  position: relative;
  opacity: 1;

  img {
    //in-line
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  display: flex;
  justify-content: center;
}

.image-icon-placeholder {
  height: 60px;
  opacity: 0.5;
}

// .image-preview-div {
//   opacity: 0.68;
// }
