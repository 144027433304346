.request-shift-swap-container {
    .notification-card {
        position: absolute;
        z-index: 99;
        width: 30.6%;
    }
    .swapping-staff-list {
        height: 37.6em;
        overflow-y: auto;

        .swapping-staff {
            display: flex;
            align-items: center;
        }
    }
}