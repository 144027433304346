.hx-staff-avatar {
  background: #d7e0ef url(/styles/web_v3/avatar-placeholder.svg) center center no-repeat;
  background-size: 50%;
  border-radius: 50%;
  border: solid 1px rgba(0, 0, 0, 0.08);
  margin-right: 0.53846em;
  max-width: none;
}

.hx-staff-image,
.hx-staff-initials {
  margin-right: 0.53846em;
}

.hx-staff-initials {
  background-color: #d7e0ef;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  line-height: normal;
  letter-spacing: normal;

  sh-text {
    color: #2e302c;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden;
  }
}

.hx-avatar-container {
  position: relative;
  display: flex;

  .hx-avatar-status-icon {
    position: absolute;
    bottom: 5%;
    right: 7%;
  }
}

.hx-avatar-cell {
  @media (max-width: 1440px) {
    width: 1.85rem;
    height: 1.85rem;
    sh-text {
      font-size: 1rem !important;
    }
  }

  @media (max-width: 1240px) {
    width: 1.5rem;
    height: 1.5rem;
    sh-text {
      font-size: 0.75rem !important;
    }
  }

  @media (max-width: 1024px) {
    width: 1.25rem;
    height: 1.25rem;

    sh-text {
      font-size: 0.75rem !important;
    }
  }

  width: 2rem;
  height: 2rem;

  sh-text {
    font-size: 1rem;
  }

  margin: auto;
}
