.flex-column{
    flex-direction: column;
}

.constraint-modification-warning{
    border: 1px solid #e5e5e5;
    padding: 1rem;
    margin-bottom: 1rem;
}

.parameter-settings{
    padding-top: 2.4rem
}

.optional-parameter-switch-item{
    background-color: #3DC3B0;
    color: white
}

.mandatory-parameter-switch-item{
    background-color: #F19E6A;
    color: white
}

.multi-select-icon{
    opacity: 0.2
}

.constraint-description-wrapper{
    height: 2.4rem
}

.settings-switch{
    border-radius: 4rem;
}

.disabledWeight{
    opacity: 0.5;
    pointer-events: none;
}

.weight-setting-wrapper{
    padding-top: 3.4rem;
}

.slider-wrapper{
    width: 42%;
    margin-left: 1.4rem;
}

.constraint-details-font{
    font-size: 0.95rem;
}