@import '/src/styles/foundation/variables';

.hx-shift-swaps-header {
  & > div {
    position: fixed;
    top: $access-bar-height + $nav-bar-height;
    width: 100%;
    z-index: 100;
  }

  .hx-shift-swaps {
    margin-top: 3.38461538em;
  }

  h1 {
    font-size: 1.69230769em;
    color: #5f6978;
    line-height: normal;
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.005em;
    text-align: center;
  }

  .calendar-toolbar > .bl1 {
    border-color: transparent;
  }

  .title {
    display: none;
  }

  @media (max-width: 960px) {
    .title {
      display: block;
    }
  }
}
