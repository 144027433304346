.myShiftFooterBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5em 0em;
}


.myShiftFooterButtons {
  display: flex;
  gap: 0.75em;
  width: 50%;
  justify-content: flex-end;
}


@media (max-width: 1560px) {

  .myShiftFooterButtons {
    width: 55%;
  }
}

@media (max-width: 1435px) {

  .myShiftFooterButtons {
    width: 60%;
  }
}

@media (max-width: 1325px) {

  .myShiftFooterButtons {
    width: 70%;
  }
}


@media (max-width: 1155px) {
  .myShiftFooterBox {
    flex-direction: column;
    align-items: flex-start;
  }

  .myShiftFooterButtons {
    width: 100%;
  }
}
