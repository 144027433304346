$columns: (
  percentage(calc(20 / 100)),
  percentage(calc(15 / 100)),
  percentage(calc(15 / 100)),
  percentage(calc(15 / 100)),
  percentage(calc(15 / 100)),
  percentage(calc(20 / 100))
);

$turquoise: #21ae8b;
$grey: #808fa4;
$week-background-color: #dfe5ef;
$thead-background-color: #eff1f5;
$tr-background-color: #fff;
$tr-hover-background-color: #f8fbff;

// TODO tony - need to make app-wide buttons
.button-group {
  white-space: nowrap;
  sh-button {
    margin: 0 0.5rem;
  }
}

.hx-table-header {
  color: #808fa4;
  box-shadow: inset 0 1px 3px 0 rgba(157, 169, 185, 0.2);
}

.hx-table-week-range {
  border: 1px solid #dce2ed;
}

.hx-swap-icon {
  font-size: 22px;
  color: #a3b3ce;
}

.hx-remove-button {
  color: #677a91 !important;
}

.hx-shift-swap-name-time:hover {
  color: #4795fe !important;
  text-decoration: underline;
  cursor: pointer;

  .reagent-gray:hover {
    color: #4795fe !important;
  }
}

.button {
  &.outline {
    border-color: #dfe5ef;

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  &.success {
    color: #fff;
    background-color: #2ecc71;

    &:hover {
      background-color: lighten(#2ecc71, 10%);
    }

    &:active {
      background-color: darken(#2ecc71, 10%);
    }
  }
}

// TODO tony - remove this class, move style to component scope instead
.initiator-recipient {
  & > div {
    display: flex;
    white-space: nowrap;
  }

  .image-32 {
    margin-right: 0.53846em;
  }

  .image-32,
  .image-32 + div {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-around;
    vertical-align: middle;
  }

  .full-name,
  .full-name + span {
    display: block;
    line-height: 1em;
    padding-left: 6px;
  }

  .full-name {
    font-size: 13px;
  }

  .full-name + span {
    color: $grey;
    font-size: 12px;
  }
}

table.hx-shift-swaps {
  width: 100%;

  thead {
    background-color: $thead-background-color;

    th {
      @for $index from 1 through length($columns) {
        $width: nth($columns, $index);

        &:nth-child(#{$index}) {
          width: $width;
        }
      }

      font-size: 11px;
      font-weight: 900;
      text-transform: uppercase;

      &:first-child {
        padding-left: 30px;
      }

      &:last-child {
        padding-right: 30px;
      }
    }
  }

  tbody {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.1px;

    &.week {
      td {
        background-color: $week-background-color;
        font-size: 12px;
        font-weight: 900;
        letter-spacing: 1.2px;
        line-height: 1.33;
        padding: 6px 8px;
        text-transform: uppercase;
      }

      .friendly-text {
        color: $turquoise;
        font-size: 10px;
        font-weight: 900;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: 1px;
        padding: 0 8px;
      }
    }

    tr {
      background-color: $tr-background-color;

      &:hover {
        background-color: $tr-hover-background-color;
      }
    }

    td {
      vertical-align: middle;

      &:first-child {
        padding-left: 30px;
      }

      &:last-child {
        padding-right: 30px;
      }
    }
  }

  .swap-dates {
    .calendar {
      display: inline-block;
      font-size: 10px;
      text-align: center;
      text-transform: uppercase;
      overflow: hidden;

      .date {
        font-size: 18px;
        overflow: hidden;
      }
    }
  }

  .actions {
    float: right;
  }

  .responder-date {
    color: rgba(83, 91, 101, 0.5);
  }
}

.hx-open-shifts-dialog-container {
  position: fixed;
  z-index: 2000;

  textarea {
    background-color: #ffffff;
    border-radius: 6px;
    border: solid 1px #bfcbde;
    color: #535b65;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    height: 60px;
    width: 316px;

    &::placeholder {
      font-size: 12px !important;
      color: #b2c0d1 !important;
    }
  }
}

.hx-open-shifts-dialog-container .arrowDown {
  position: absolute;
  top: 0.5em;
  right: -0.7em;
  font-size: 1.53846em;
  margin-left: calc(50% - 0.25em - 20px);
  z-index: 10000;
}
