$color-cornflower-blue: #459afe;

#hx-calendar {
  position: relative;
  overflow-y: overlay;
  .ReactVirtualized__Grid__innerScrollContainer {
    min-height: 100%;
  }
}

.hx-main-navigation {
  background-color: #ffffff;

  .hx-schedule-selector:not(.staff-list) {
    top: -24px;
  }

  .bg-cornflowerblue {
    background-color: $color-cornflower-blue;
  }
}

.hx-calendar-group-header.mode-week {
  min-height: 2.4615em;

  .col-12 {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .hx-pill-indicator {
    height: 1.5em;
    font-size: 0.923076923076923em;
    overflow: hidden;

    & > div {
      padding: 0 0.833333333333333em;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.166666666666667em solid #ffffff;
    }

    & > div:first-child {
      border-top-left-radius: 0.46154em;
      border-bottom-left-radius: 0.46154em;
    }

    & > div:last-child {
      border-top-right-radius: 0.46154em;
      border-bottom-right-radius: 0.46154em;
    }

    & > div:not(:first-child) {
      border-left-width: 0.083333333333333em;
    }

    & > div:not(:last-child) {
      border-right-width: 0.083333333333333em;
    }

    & > div.hx-overstaffed {
      border-color: #fb8c00;
      color: #fb8c00;
      & + div:not(.hx-understaffed):not(.hx-overstaffed) {
        border-left-color: #fb8c00;
      }
    }

    & > div.hx-understaffed {
      border-color: #f44336;
      color: #f44336;
      & + div:not(.hx-understaffed):not(.hx-overstaffed) {
        border-left-color: #f44336;
      }
    }

    & div:not(.hx-understaffed):not(.hx-overstaffed) {
      & + div.hx-understaffed {
        border-left-width: 0.166666666666667em;
      }
    }

    & > div:not(.hx-understaffed):not(.hx-overstaffed) {
      & + div:not(.hx-understaffed):not(.hx-overstaffed) {
        border-left-color: #dfe8f6;
        padding-left: 0.916666666666667em;
      }
    }
  }
}

.bg-selected-shift {
  background-color: rgba(73, 144, 226, 0.23);
  border: 1px solid #4990e2;

  i {
    color: #4990e2;
  }

  &.first-selected,
  &.middle-selected {
    border-right-color: transparent;

    &:last-child::after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 4px;
      background-color: rgba(73, 144, 226, 0.23);
      right: -4px;
    }
  }

  &.last-selected,
  &.middle-selected {
    border-left-color: transparent;
  }
}

._cell.half-opacity {
  opacity: 0.5;
}

._cell._yesterdayCell {
  border-right-color: #405772;
}

._cell {
  overflow: hidden;
}

._cell > div {
  width: 100%;
  height: 100%;
}

._staffContainer .divider,
.staff-divider {
  margin: 0;
  border-bottom: 2px solid #9eb1c8;
}

.hx-shift-cell-wrapper.week-view {
  width: 14.285714285714286%;

  .bt1 {
    border: 0;
  }
}

.hx-shift-cell-wrapper.day-view {
  width: 100%;
}

._shift {
  .hx-avatar-placeholder.icon {
    background-color: #f1f1f1;
    text-align: center;
    color: #a3b3ce;
    margin-right: 0.53846em;
    line-height: 32px;

    &::before {
      font-size: 20px;
    }
  }
}

._modeFull ._staffContainer > .divider + .hx-calendar-staff {
  .bt1 {
    border-top: 0;
  }
}

.hx-calendar-staff {
  flex-wrap: nowrap;

  > .row {
    flex: auto;
    padding-right: 0;

    &.loading {
      background: repeating-linear-gradient(-35.3deg, #fff, #eee 5px, #fff 10px);
      border: 0;
    }
  }
}

.hx-main-navigation ._navigation sh-tooltip:not([target='print-btn']) {
  display: none;
}

@media screen and (min-width: 2100px) {
  ._modeFull .hx-calendar-staff {
    > aside .hx-calendar-staff-info-wrapper,
    > section {
      padding-top: 1px;
    }
  }

  ._modeDay .hx-calendar-staff {
    > aside .hx-calendar-staff-info-wrapper,
    .hx-shift-cell-wrapper {
      padding-top: 1px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .hx-main-navigation ._navigation {
    sh-button {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80px;
      min-width: 80px; //to prevent max-width getting affected by previously set min-width
    }
    sh-tooltip.below-1400 {
      display: block !important;
    }
  }
}

@media screen and (max-width: 1200px) {
  .hx-main-navigation ._navigation {
    sh-button {
      max-width: 60px;
      min-width: 60px; //to prevent max-width getting affected by previously set min-width
    }
    sh-tooltip.below-1200 {
      display: block !important;
    }
  }
}
