.staff-index {
  color: var(--support-4-tint, #f19e6a);
  font-size: 1.5em;
  font-style: normal;
  font-weight: 800;
}

.staff-avatar {
  align-items: center;
}

.staff-name {
  font-style: normal;
  font-weight: 400;
}

.staff-work-hours {
  color: var(--ui-ui-1-opacity-3, rgba(0, 0, 0, 0.6));
  font-style: normal;
  font-weight: 900;
}
