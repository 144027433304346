.form-title {
  background-color: #f2f4f5;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 32px;

  font-size: 14px;
  font-weight: 900;
  line-height: 1.43;
  letter-spacing: 1.2px;
  color: #535b65;
}

.separator-schedule-form {
  border: solid 0.5px #dddddd;
  height: 1px;
}

.form-field {
  position: relative;
  padding: 0px;
}

.advnaced-configuration-buttons {
  width: 30px;
  height: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.23;
  letter-spacing: -0.1px;
  text-align: center;
  color: #535b65;
}

.form-options {
  padding-top: 17px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.date-time-connector {
  padding-left: 10px;
  padding-right: 20px;
  width: 10px;
  height: 15px;
  font-size: 11px;
  font-weight: 600;
  color: #535b65;
}

.acc-form-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right;
  //above is original
  sh-button {
    margin: 0 0.5rem;
  }
}

.switch-field {
  display: flex;
  overflow: hidden;

  position: absolute;
  right: 90px;
  width: 250px;
  .form-item.col-4.pr30 {
    margin-bottom: 0px;
  }
  .pr30 {
    width: 100%;
    padding-right: 0px;
  }
  label[for='Day'],
  label[for='Night'] {
    //center the day/night icons in announncements
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  font-size: 12px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  // padding: 8px 0px;
  margin-right: -1px;
  border: 1px solid #459afe;
  // box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
  text-transform: capitalize;
  max-height: 30px;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #459afe;
  color: #fafafa;
  position: relative;
  z-index: 1;
  border: 0;
  border: 1px solid #459afe;
  box-shadow: inset 0 0 0 1px #fafafa;
}

.switch-field div:first-of-type label {
  border-radius: 6px 0 0 6px;
}

.switch-field div:last-of-type label {
  border-radius: 0 6px 6px 0;
}

.advanced-config-label {
  width: 100%;
  text-align: right;
  right: 370px;
  font-size: 11px;
}

.form-options .row {
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}

.time-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
}

.time {
  width: 60px;
}

.invalid-time {
  width: 70px;
  border: 1px solid red;
  border-radius: 7px;
}

.warning-text.time-warning {
  bottom: -3px;
  left: 170px;
  z-index: 100;
  position: absolute;
}

.row.time-row {
  position: relative;
}

.time-connector {
  margin-left: 10px;
  margin-right: 10px;
}
