.editor-div {
  //in-lined
  //change padding
  padding: 1.5em 38px 0.5em;
  // padding: 1em;
  word-wrap: break-word;

  h1 {
    font-size: 1.38462em;
    line-height: 1.14286em;
  }

  h2 {
    font-size: 1.15385em;
    line-height: 1.16667em;
  }

  h3 {
    font-size: 1em;
    line-height: 1.2em;
  }
}

.extended-preview-textfield {
  //in-lined
  position: relative;
  background: white;
  width: 100%;
}

.extended-preview-textfield:before {
  background-color: rgba(231, 231, 231, 0.46);
  content: '';
  width: 800%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -500%;
  z-index: -1000;
}

.extended-preview-textfield-unclicked:before {
  content: '';
  width: 800%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -500%;
  z-index: -1000;
}

.extended-preview-textfield-unclicked {
  //in-lined
  position: relative;
  width: 100%;
}

.extended-preview-textfield:hover {
  cursor: pointer;
}

.extended-preview-textfield .rdw-editor-main {
  //unnecessary???
  width: 383px;
  margin: 0px;

  height: 100%;
}
.extended-preview-textfield-unclicked .rdw-editor-main {
  width: 383px;
  margin: 0px;

  height: 100%;
}

.text-icon-placeholder {
  //in-lined
  width: 374px;
  height: 322px;
  margin-left: 7%;
}
