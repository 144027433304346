.hx-explanation-note {
  .form-item {
    position: relative;

    .icon-circle {
      position: absolute;
      font-size: 1.3em;
      top: 0.35em;
    }

    ._selectText {
      position: absolute;
      top: -9999px;
    }
  }

  > div > h5 {
    font-size: 0.769230769230769em;
    font-weight: 900;
    line-height: 0.83333333em;
    letter-spacing: 0.03846154em;
    color: #535b65;
  }

  > div:nth-child(2) > button {
    margin: 0;
    padding: 0;
    min-height: 0;
    font-size: 1.36em;
    position: relative;
    color: #434f5c;

    .icon-minus {
      font-size: 0.85em;
    }

    i.dimmed {
      color: #bfcbdd;
    }
  }

  select:disabled {
    background-image: none;
  }

  .updated-at {
    position: absolute;
    top: 0;
    right: 10px;
    line-height: 2.5em;
    font-size: 11px;
    color: #808fa4;
  }
}

.hx-update-time-off .row.align-middle label {
  margin-left: auto;
}
