.hx-no-requests {
  background-color: #fff;

  &:hover {
    background-color: #f8fbff;
  }
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.1px;
  line-height: 1.33;
  padding: 13px 30px;
}

.hx-pending-requests-container {
  flex: 1 1;
  width: 100%;
  overflow-y: visible;
  overflow-x: hidden;

  ._cell {
    text-align: center;
    white-space: normal;
  }

  @media (max-width: 1450px) {
    ._cell {
      letter-spacing: -2.5px;
    }
  }

  .footer-wrapper {
    padding-top: 3.076923076923077em;
    margin-top: 3em;
  }

  #cross-hatch-horizontal {
    margin: 0 2.92307692em;
  }

  &._modeFull {
    #cross-hatch-vertical {
      display: none;
    }
  }

  .hx-time-off-schedule {
    overflow: hidden;
    box-shadow: 0 0.53846em 1.69231em rgba(0, 0, 0, 0.15);
    border-top-left-radius: 0.38462em;
    border-top-right-radius: 0.38462em;
  }

  h4 {
    font-size: 1.692em;
    letter-spacing: -0.01818em;
    display: inline-block;
  }

  ._full {
    display: block;
  }

  ._count-timeOffRequests {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.45em;
    color: white;
    background: #f44336;
    height: 1.6em;
    width: 1.6em;
    border-radius: 0.8em;
    position: absolute;
    right: -1.3em;
    top: -1.3em;
    font-weight: normal;
  }

  .staff-meta {
    width: calc(100% - 3em);
  }

  .hx-calendar-group,
  .hx-calendar-header,
  .hx-calendar-staff {
    padding-right: 0;
  }

  .hx-calendar-header {
    overflow-y: hidden;
  }

  .hx-calendar-header-month div:last-child {
    border-right-width: 2px;
  }

  .hx-calendar-header-week:last-child {
    border-right-width: 1px;
  }

  .hx-calendar-staff .row > ._weekCells:last-child {
    border-right-width: 1px;
  }

  .hx-time-off-request {
    position: absolute;
    z-index: 98;
    height: 2.2307em;
    border: 1px solid #535b65;
    border-radius: 0.46em;
    top: calc(100% - 2.8462em);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 2px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);

    * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.selected {
      border: 2px solid #4a90e2;
      background-color: #d4e4f7;
    }

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .shape {
      height: 1.53846em;
      transform: skew(-45deg);
      left: calc(100% - 1.077em);
    }

    .dodger-blue .shape {
      border-right: 1px solid #459afe;
    }

    .regent-gray .shape {
      border-right: 1px solid #8290a2;
    }

    label {
      cursor: pointer;
      font-size: 1.53846em;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;

      &:hover {
        opacity: 0.7;
      }
    }

    &:hover:not(.denied):not(.selected) {
      border: 1px solid #459afe;
      cursor: pointer;
      background-color: #d4e4f7;
    }

    .info {
      top: -26px;
      background: #ffffff;
      padding: 3px 5px;
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.24);
      line-height: normal;
    }
  }

  .hx-calendar-staff .row:first-child:last-child {
    overflow: initial;
  }

  .hx-cell-details {
    .giant {
      line-height: normal;
    }
  }

  .hx-calendar-shift aside,
  ._staffMeta {
    min-width: 0;
  }

  .hx-calendar-shift i.icon-moon,
  .hx-calendar-shift i.icon-sun {
    position: relative;
    top: 0.181818181818182em;
  }

  .empty {
    padding-bottom: 3em;

    .hx-footer {
      position: absolute;
      bottom: 0;
    }
  }
}

.hx-pending-requests-container.empty {
  padding-bottom: 3em;

  .hx-footer {
    position: absolute;
    bottom: 0;
  }
}

@media (max-width: 1435px) {
  .hx-pending-requests-container {
    .hx-time-off-schedule {
      section {
        header {
          width: 78%;
          min-width: 0;
        }
      }
    }

    .hx-calendar-group {
      aside {
        width: 22%;
        min-width: 0;
      }

      section {
        width: 78%;
        min-width: 0;
      }
    }

    .hx-calendar-staff {
      section {
        width: 78%;
        min-width: 0;
      }
    }

    ._staffMeta,
    .profile-dropdown-options {
      min-width: 0;
      width: 22%;
    }
  }
}

.hx-calendar-datepicker.time-off {
  min-width: auto;
}
