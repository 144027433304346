.constraints-list-body {
  height: 96%;
}
.constraint-section-background{
  background: #F5F5F5;
}

.search-wrapper {
  justify-content: space-around;
  align-items: center;
  height: 8%;
  margin-left: 0.7rem;
}

.constraint-body-wrapper{
  height: 96%;
}

.search-empty-state {
  word-wrap: break-word;
}

.constraint-update-notification{
  // NOTIFICATION COLOR IS DIFF
  background-color: #FFF;
  top: 6rem;
}

.constraint-list-footer{
  bottom: 1rem;
  left: -1rem
}

.constraint-list-footer-button{
  border-radius: 4rem;
  border: '1px solid var(--ui-ui-1-opacity-7, rgba(0, 0, 0, 0.05))';
}

.constraint-table-wrapper{
  height: 92%;
    width: 98%;
}

.param-information-tooltip {
  word-break: break-word;
  margin-right: 20px;
}

.dragItem {
  cursor: pointer;
}

.constraint-table-heading{
  font-size:0.956rem;
}
