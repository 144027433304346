.hx-accept-staff-transfer-modal .hx-staff-transfer-data-container {
  display: flex;
  flex-direction: row;

  .call-to-action {
    padding-top: 1.538461538461538em;
  }

  .icon-indicator-dot {
    position: absolute;
    top: 2em;
    left: 0.769230769230769em;
  }
}
