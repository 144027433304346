.hx-remote-staff-requests-table {
  width: 100%;
  margin: 0 auto;
  > header.row {
    height: 2em;
  }

  header {
    box-shadow: inset 0px 1px 3px rgba(34, 50, 71, 0.1);
    height: 2em;

    > div {
      display: flex;
      align-items: center;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: relative;
      float: left;
      overflow: hidden;
      white-space: nowrap;
      font-size: 0.84615em;
      line-height: 1.09091em;
      letter-spacing: 0.09091em;
      padding: 0 2.09091em 0 0;
    }

    > div:nth-child(1) {
      padding-left: 1rem;
      width: 12%;
    }

    > div:nth-child(2) {
      width: 12%;
    }

    > div:nth-child(3) {
      width: 34%;
    }

    > div:nth-child(4) {
      width: 12%;
    }

    > div:nth-child(5) {
      width: 17%;
    }

    > div:nth-child(6) {
      width: 8%;
    }

    > div:nth-child(7) {
      width: 2%;
    }
  }
}

.hx-remote-staff-list {
  sh-icon {
    cursor: pointer;
    transform: scale(0.8);
  }

  .actions {
    display: flex;
    max-width: 3.5rem;
    justify-content: space-between;
  }

  .row {
    padding: 0;
    display: flex;
    align-items: center;
    height: 3em;
    border-bottom: 1px solid #dfe5ef;
  }

  .row:hover {
    background-color: #f8f9fb;
  }

  div.row > div {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    float: left;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.07692em;
    padding: 0 1.14286em 0 0;
    text-overflow: ellipsis;

    &:nth-child(1) {
      padding-left: 1rem;
      width: 12%;
    }

    &:nth-child(2) {
      width: 12%;
    }

    &:nth-child(3) {
      width: 34%;
    }

    &:nth-child(4) {
      width: 12%;
    }

    &:nth-child(5) {
      width: 17%;
    }

    &:nth-child(6) {
      width: 8%;
    }

    &:nth-child(7) {
      width: 2%;
      margin-left: auto;
      margin-right: 0.75rem;
    }
  }

  .row.child-row > div {
    padding-left: 1em;
  }
}
