.hx-search-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  input {
    color: #808fa4;
    background-color: #ffffff;
  }
}

.icon-zoom {
  color: #677a91;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  z-index: 1;
  position: absolute;
}

.hx-dirty-search:after {
  font-family:
    Icons,
    siemens sans,
    sans-serif !important;
  font-style: normal;
  content: '\e912';
  right: -0.7em;
  transform: rotate(45deg);
  cursor: pointer;
  height: 25%;
  top: 0.5em;
  position: absolute;
}
