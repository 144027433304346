.hx-calendar-group-container > .divider {
  margin: 0;
  border-bottom: 8px solid #dfe5ef;
}

.hx-print-view {
  .hx-calendar-group {
    box-shadow: none;
    background-color: #ffffff;
    border: 2px solid #383838;

    letter-spacing: 0.6px;
    font-size: 1em;
    font-weight: bold;

    & > .row {
      flex-wrap: nowrap;
    }

    button {
      font-size: 0.769230769230769em;
      color: #383838 !important;
      padding: 0 0.6em;
    }

    button span {
      font-size: 1em;
    }

    .col-10 {
      margin: 0;
      padding: 0;
    }

    aside {
      min-width: 0;
    }

    & > header aside button i {
      display: none !important;
    }
  }
}

.hx-calendar-group button {
  sh-icon,
  sh-text {
    color: inherit;
  }
}
