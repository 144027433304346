.assignStaffButton {
    justify-content: flex-end;
}

.staffBox {
    border: 1px solid #d0d6e2;
    max-height: 200px;
    overflow-y: auto;
    border-radius: 4px;
    margin-top: -2.3rem;

    :hover {
        cursor: pointer;
    }
}

.removeStaff {
    float: right;
    margin-top: 1rem;
}

.hideStaffBox {
    display: none;
}

.selectedStaff {
    margin-top: -4.3rem !important;
}

.hx-calendar-staff-info-wrapper {
    margin-right: 0em !important;
}

input:disabled {
    cursor: default;
    background-color: white;
}
