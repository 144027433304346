.add-component-button-above {
  position: absolute;
  left: 46%;
  width: 30px;
  top: -15px;

  height: 30px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
  background-color: #4a90e2;
  color: #ffffff;
  border-radius: 50%;
  z-index: 10000;
}

.add-component-button-below {
  position: absolute;
  left: 46%;
  width: 30px;
  bottom: -15px;
  height: 30px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
  background-color: #4a90e2;
  color: #ffffff;
  border-radius: 50%;
  z-index: 10000;
}

.delete-component-button {
  position: absolute;
  top: 5%;
  left: 105%;
  border-radius: 50%;
  padding: 6px;
  width: 31.4px;
  height: 32px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
  background-color: #ffffff;
}
