$color-mismatch: #f44336;
$color-expected: #2ecc71;
$color-text: #526173;

.hx-warning-modal {
  .hx-modal.warning {
    width: fit-content;

    .hx-modal-title {
      font-size: 1.23077em;
      margin-right: 1em;
      margin-bottom: 1em;
    }
    .hx-modal-body {
      font-weight: normal;
      margin-bottom: 1em;

      .group-title {
        text-transform: capitalize;
      }

      .group-item {
        display: flex;
        flex-direction: row;
        line-height: 1.6em;

        .icon {
          line-height: inherit;
          margin-left: 0.3em;
          cursor: pointer;
        }
        .item-title {
          color: $color-text;
        }

        .item-status {
          padding-left: 0.2em;
          color: $color-mismatch;
        }

        .modal-tooltip {
          overflow: visible;
          background: #fff;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
          border-radius: 8px;
          line-height: 0.3em;
          display: flex;
          flex-direction: row;
          padding: 1em;

          &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 100%;
            left: calc(50% - 15px);
            box-sizing: border-box;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #fff #fff;
            transform-origin: 0 0;
            transform: rotate(-45deg);
            box-shadow: -4px 4px 6px rgba(0, 0, 0, 0.1);
          }

          .required-status {
            padding-left: 0.2em;
            &.mismatch {
              color: $color-expected;
            }
          }
        }
      }
    }
  }
}

.info-tooltip {
  position: relative;
  display: inline-block;
  filter: drop-shadow(0px 0px 8px #b2b2b2);
}

.info-tooltip:hover .info-tooltip-text {
  visibility: visible;
  margin-left: 8px;
  margin-top: -12px;
}

.info-tooltip .info-tooltip-text {
  visibility: hidden;
  width: 120px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  background-color: white;
  color: black;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 105%;

  content: ' ';
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent white transparent transparent;
}
