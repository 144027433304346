.hx-calendar-indicators-button.active + .hx-calendar-highlight-popup {
  display: flex;
}

.hx-calendar-highlight-popup {
  display: none;
  margin-top: 14px;
  margin-left: -46px;
  font-size: 1em;
  min-width: 23.07692em;
  max-width: 25em;
  border-radius: 0.46154em;
  box-shadow: 0 2px 34px 0 rgba(34, 50, 71, 0.25);
  position: absolute;
  top: 9.25em;
  z-index: 10000;

  .arrowUp {
    position: absolute;
    top: -0.65em;
    left: -32px;
    font-size: 1.53846em;
    margin-left: calc(50% - 0.25em - 20px);
    z-index: 10000;
  }

  ul {
    list-style-type: none;
    overflow: hidden;
    margin: 0;
    width: 100%;

    ul {
      margin: -0.76923em 0 -0.76923em 1.53846em;
      width: calc(100% - 1.53846em);
    }
  }

  .hx-checkbox i {
    font-size: 1em;
  }
}

button.hx-calendar-indicators-button {
  position: relative;

  sh-text {
    color: #535b65;
  }
}
