.day-notes {
  float: left;
  width: auto;
  height: 620px;
  position: relative;
  display: flex;
  flex-direction: column;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
  border-right: solid 1px #000000;

  .day-notes-title {
    position: relative;
    background-color: #e6e6e6 !important;
    font-size: 1.076923076923077em;
    letter-spacing: -0.028571428571429em;
    color: #383838;
    font-weight: bold;
    padding: 0.214285714285714em 0.285714285714286em !important;
    height: 1.428571428571429em !important;
    border-bottom: solid 1.5px #383838;
    line-height: 1em;

    span {
      line-height: 1.4em;
      margin-left: 0.6em;
      font-size: 0.714285714285714em;
      font-weight: normal;
      letter-spacing: -0.029em;
    }
  }

  .day-notes-content {
    position: relative;
    display: flex;
    flex-direction: row;
    height: auto;
    height: 600px;
  }

  .day-notes-content > div {
    position: relative;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.day-notes .day-note {
  position: relative;
  padding-top: 0.769230769230769em;
  width: 8.615384615384615em;
  padding-left: 0.538461538461538em;
  padding-right: 0.538461538461538em;
  page-break-before: auto;
  page-break-after: auto;

  &.with-divider:after {
    overflow-y: visible;
    overflow-x: hidden;
    position: absolute;
    display: block;
    content: ' ';
    bottom: 0;
    left: 0.538461538461538em;
    width: 7.230769230769231em;
    height: 1px;
    border-bottom: solid 0.3px #000000;
  }

  .day-note-shift-name {
    font-size: 0.692307692307692em;
    line-height: 1.222222222222222em;
    letter-spacing: -0.025555555555556em;
    opacity: 0.85;
    font-weight: bold;
    color: #232323;
  }

  .day-note-shift-time {
    font-size: 0.538461538461538em;
    line-height: 1.285714285714286em;
    letter-spacing: -0.025714285714286em;
    opacity: 0.85;
    font-weight: normal;
    color: #383838;
  }

  .day-note-staff-name {
    opacity: 0.85;
    font-size: 0.692307692307692em;
    letter-spacing: -0.025555555555556em;
    line-height: 1.222222222222222em;
    font-weight: bold;
    color: #383838;
    margin-bottom: 0.555555555555556em;
  }

  .day-note-subject {
    font-size: 0.615384615384615em;
    letter-spacing: -0.025em;
    line-height: 1.125em;
    font-weight: bold;
    color: #383838;
    margin-bottom: 0.375em;

    i {
      display: inline-block;
      margin-right: 0.25em;
    }
  }

  .day-note-explanation {
    font-size: 0.615384615384615em;
    letter-spacing: -0.025em;
    line-height: 1.125em;
    font-weight: normal;
    color: #383838;
    margin-bottom: 0.875em;
  }

  .day-note-last-update {
    font-size: 0.461538461538462em;
    color: #383838;
    letter-spacing: -0.025em;
    line-height: 1.5em;
    font-weight: normal;
    margin-bottom: 0.5em;
  }
}
