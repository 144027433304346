/* Positioning */

.hx-calendar-group,
.hx-calendar-shift {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.hx-header,
.hx-calendar-navigation,
.hx-calendar-header {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 100%;
  z-index: 100;
}

.hx-header {
  top: 0;
  z-index: 102;
}
.hx-calendar-navigation {
  z-index: 10000;
}
.hx-calendar-header {
  top: 9em;
  overflow: hidden;
}

/* Functional */

.hx-calendar-group ._full,
.hx-calendar-group ._week,
.hx-calendar-group ._day {
  display: none;
}

._modeFull .hx-calendar-group ._full,
._modeDay .hx-calendar-group ._day,
._modeWeek .hx-calendar-group ._week {
  display: block;
}

._shift > div {
  overflow-x: hidden;
}

/*._modeWeek .hx-calendar-staff,
._modeDay  .hx-calendar-staff {
  overflow: hidden;
}*/

.hx-calendar-staff aside {
  z-index: 11;
}

._collapsed ._shift {
  display: none;
}

._collapsed ._full,
._collapsed ._week,
._collapsed ._day {
  visibility: hidden;
}

._filterDay ._shiftNight {
  display: none;
}
._filterNight ._shiftDay {
  display: none;
}

._filterNight ._group:not(._hasNightShifts) {
  display: none;
}
._filterDay ._group:not(._hasDayShifts) {
  display: none;
}

/* Visual */

.modal footer {
  min-height: 72px;
}

._group:not(._collapsed) .hx-calendar-group ._icon-reorder {
  display: none;
}

._group .hx-calendar-group ._icon-reorder {
  cursor: -webkit-grab;
  cursor: grab;
  position: absolute;
  right: 1em;
  top: 1em;
}

.hx-openshifts ._content {
  position: relative;
}

.hx-calendar-datepicker > div {
  position: relative;
}

._group ._shift ._assignment {
  cursor: pointer;
  position: relative;
}

.pb0 {
  padding-bottom: 0;
}

.hx-calendar-staff-shift {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hx-calendar-staff-shift .small {
  white-space: nowrap;
}

.hx-calendar-staff-modal header aside {
  max-width: 32.2em;
}

._modeFull .col-10 .icon-checkmark {
  opacity: 0.5;
}

._modeFull .icon-close.error {
  font-size: 1.2em;
}

.hx-calendar-group {
  z-index: 99;
}

.hx-calendar-shift {
  z-index: 98;
}

.hx-calendar-shift.js-is-stuck {
  z-index: 98;
}

.hx-calendar-staff img + div > div {
  text-overflow: ellipsis;
  overflow: hidden;
}

.hx-calendar-staff aside a {
  cursor: default;
}

.hx-calendar-datepicker > .icon-down {
  cursor: pointer;
}

._datepickerPopup {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

._group .hx-calendar-open-shift:first-child > .row {
  border-top: 0 !important;
}

._dayAssignment {
  visibility: hidden;
}

.hx-calendar-staff-hours:hover ._dayAssignment:first-child {
  visibility: visible;
  border: solid 1px #54a1fe;
}

._dayAssignment.scheduled {
  visibility: visible;
}

.hx-tooltip._mismatchTooltip {
  margin-top: -2.8571em;
}

._groupHeader {
  height: 34px;
  z-index: 100;
}

.hx-shift-opportunity table {
  margin-top: 7.16667em;
}

.hx-calendar-staff-shift .smaller {
  text-align: center;
}

.he-schedule-autofill > section > input[type='checkbox'] + label + div {
  display: none;
}

.he-schedule-autofill > section > input[type='checkbox']:checked + label + div {
  display: block;
}

.he-schedule-autofill > section > input[type='checkbox'] + label:before {
  height: 1em;
  content: 'Show autofill configuration';
  display: inline-block;
}

.he-schedule-autofill > section > input[type='checkbox']:checked + label:before {
  content: 'Hide autofill configuration';
}

.he-schedule-autofill > section > input[type='checkbox'] + label:after {
  font-family:
    Icons,
    siemens sans,
    sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin-left: 1em;
  height: 1em;
  content: '\e900';
  display: inline-block;
}

.he-schedule-autofill > section > input[type='checkbox']:checked + label:after {
  content: '\e903';
}

.hx-shift-swaps ._filters {
  position: fixed;
  min-height: 100%;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.icon {
  vertical-align: middle;
}

._highlight-notWorkingRequiredWeekends ._cell-notWorkingRequiredWeekends {
  background-color: #fef2c6;
}

._highlight-holidayShifts ._cell-holidayShift {
  background-color: #f4e2fe;
}

._highlight-available ._cell-available .icon-checkmark,
._highlight-requestOff ._cell-requestOff .icon-close,
._highlight-unavailable ._cell-unavailable .icon-close,
._highlight-PTO ._cell-PTO .icon-Money-Sign,
._highlight-annotation ._cell-annotation .humanics-icons-Note,
._highlight-lock ._cell-lock .icon-Lock,
._highlight-info ._cell-info .icon-info {
  visibility: visible;
}

._cell-available .icon-checkmark,
._cell-requestOff .icon-close,
._cell-unavailable .icon-close,
._cell-PTO .icon-Money-Sign,
._cell-annotation .humanics-icons-Note,
._cell-lock .icon-Lock,
._cell-info .icon-info {
  visibility: hidden;
}

.row-7 .bg-mystic._cell-unavailable,
.row-7 .bg-mystic._cell-PTO,
.row-7 .bg-alabaster._cell-requestOff {
  background-color: #fbfbfb;
}

.row-7 .bg-mystic._cell-unavailable:nth-child(1),
.row-7 .bg-mystic._cell-unavailable:nth-child(7),
.row-7 .bg-mystic._cell-PTO:nth-child(1),
.row-7 .bg-mystic._cell-PTO:nth-child(7),
.row-7 .bg-alabaster._cell-requestOff:nth-child(1),
.row-7 .bg-alabaster._cell-requestOff:nth-child(7) {
  background-color: #f1f2f3;
}

._assignment.danger:hover {
  background-color: rgba(244, 67, 54, 0.2) !important;
  border: 1px solid rgba(244, 67, 54, 1);
  -webkit-transition: background-color 0.5s ease-out;
  -moz-transition: background-color 0.5s ease-out;
  -o-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
}

.hx-shift-staff-cell-tooltip {
  position: fixed;
  z-index: 2000;
}

._assignment .hx-shift-staff-cell-tooltip i {
  visibility: visible;
}

._assignment .hx-shift-staff-cell-tooltip ul li div {
  width: 150px;
}

._staff ._warning {
  background: white;
  width: 300px;
  height: 57px;
  border-radius: 10px;
  position: fixed;
  z-index: 2000;
  display: flex;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);
}

._staff ._warning:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-top: 1em solid #ffffff;
  position: absolute;
  right: 20px;
  bottom: -1em;
}

._staff.danger:hover ._warning {
  display: flex;
}

._highlight-requestOff .row-7 > ._cell-requestOff.bg-alabaster {
  background-color: #f2f4f5;
}

._highlight-unavailable .row-7 > ._cell-unavailable.bg-mystic {
  background-color: #dee5ef;
}

._highlight-PTO .row-7 > ._cell-PTO.bg-mystic {
  background-color: #dee5ef;
}

.row-7 .col-1 .loading {
  display: block;
  width: 100%;
  height: 100%;
}

.row-7 .col-1 .loading::after {
  content: '';
  display: block;
  background: transparent url(./spinner-orange.svg) no-repeat center center;
  background-size: contain;
  animation: rotate 1s infinite steps(12);
  width: 100%;
  height: 100%;
}

.sh-link {
  color: var(--text-primary) !important;
  cursor: pointer;
  text-decoration: underline !important;
}

.sh-link:hover {
  color: rgb(236, 102, 2) !important;
  opacity: var(--opacity-1) !important;
  transition: var(--transition-time) all ease-in-out;
}
