.hx-time-off-filter {
  h1 {
    font-size: 1.69230769em;
    color: #5f6978;
    line-height: normal;
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.005em;
    text-align: center;
  }
  .title {
    display: none !important;
  }

  @media (max-width: 1100px) {
    .title {
      display: block !important;
    }
  }
}
