.hx-calendar-header {
  height: 3.923076923077em;

  aside {
    min-width: 17.307692307692308em;
    padding: 0;
    box-shadow: inset 0 1px 3px 0 rgba(157, 169, 185, 0.2);

    input {
      height: auto;
      border: 0;
      border-radius: 0;
      background-size: 17px;
      background-position: 1em center;
      padding-left: 3em;
      font-size: 14px;
      color: #808fa4;

      &::placeholder {
        color: #808fa4;
      }
    }

    .hx-switch {
      display: flex;
      align-items: center;
    }

    .menu {
      position: static;

      /* Small Uppercase Title */

      font-style: normal;
      font-weight: 900;
      font-size: 11px;
      line-height: 15px;
      /* identical to box height */

      letter-spacing: 0.045em;
      text-transform: uppercase;

      /* Text/Light Gray Secondary */

      color: #808fa4;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
      margin: auto;
      top: 0;
      bottom: 0;
    }
    .menu.active-mode {
      background-color: #32425e;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 8px;
      span {
        color: #ffffff;
      }
    }
  }

  > header,
  > header > div {
    height: inherit;
  }
}

@media (max-width: 1379px) {
  .hx-calendar-header aside {
    width: 22%;
  }

  .hx-calendar-header aside + header {
    width: 78%;
  }
}
