.create-edit-staff-form {
  .button.primary {
    background-color: rgba(69, 154, 254, 1);
    &:hover {
      background-color: rgba(69, 154, 254, 0.7);
    }
    &[disabled] {
      cursor: not-allowed;
    }
  }

  .form-item {
    input,
    select {
      font-size: 1.15384615em;

      & + i {
        font-size: 1.7em;
      }
    }
  }

  .center-align {
    text-align: center;
  }
}

.hx-staff-profile-box {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.875em;
    margin-bottom: 2.5em;

  .hx-staff-initials sh-text {
    font-size: 2.5em;
    line-height: normal;
  }
}

.avatar-button-container {
  display: flex;
  align-items: center;
  margin-left: 1.375em;
}

.hx-staff-profile-picture-size {
  width: 8em;
    height: 8em;
}