.printed-grid .print-content > div {
  page-break-inside: avoid;
}

@media (max-width: 1379px) {
  .hx-print-view .hx-months-container .col-2 {
    width: 22%;
  }

  .hx-print-view .hx-months-container .col-10 {
    width: 78%;
  }

  .hx-print-view ._staff.hx-calendar-staff > .col-2 {
    width: 22%;
  }

  .hx-print-view ._staff.hx-calendar-staff > .col-10 {
    width: 78%;
  }
}

.hx-print-view {
  .hx-months-container {
    border-left: 2px solid transparent;
    border-right: 2px solid #383838;
    width: 98vw;
  }

  .hx-calendar-staff,
  .hx-calendar-group {
    padding-right: 0;
  }

  ._staffMeta {
    min-width: 0;
  }

  .hx-calendar-group aside {
    min-width: 0;
  }

  .print-table {
    font-size: 1em;
  }

  .staff-divider {
    display: none;
  }
}
