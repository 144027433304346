.hx-staff-container-box-title {
  justify-content: space-between;
  color: #535b65;

  sh-text[size='header-2'] {
    font-size: 16px;
    color: inherit;
    width: fit-content;
  }
  i {
    cursor: pointer;
    padding: 0 5px;
  }
  &.active {
    color: #808fa4;
  }
}

.crud-card {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  position: relative;

  sh-text[size='body-1'] {
    font-size: 14px;
  }

  .crud-card-contents {
    & > .card-item:first-child {
      padding-top: 20px;
    }

    & > .card-item:last-of-type {
      padding-bottom: 20px;
    }

    & > .card-accordion:last-child .card-item:last-child {
      padding-bottom: 20px;
    }
  }

  .empty-card-content {
    padding: 20px;
  }

  .crud-card-floating-button {
    position: absolute;
    top: 5%;
    right: 10%;
  }

  .crud-card-separator {
    height: 1px;
    margin: 0 20px;
  }

  .collapsed-shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50px;
    width: 100%;
    background-image: linear-gradient(rgba(255, 255, 255, 0) 0%, white 80%);
    z-index: 10;
  }
}
