h1 {
  text-align: center;
  margin: 2rem 0 4rem 0;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.accordion-title,
.accordion-content {
  padding: 0.5rem;
}

.arrow-container {
  height: 15px;
  width: 25px;
}
