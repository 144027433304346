.form-input {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: flex-end;
 
  .x-mark {
    &:after {
      content: "\00d7";
    }
  }
}