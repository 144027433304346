._staffContainer .divider,
.staff-divider {
  margin: 0;
  border-bottom: 2px solid #9eb1c8;
}

.hx-shift-cell-wrapper.week-view {
  width: 14.285714285714286%;

  .bt1 {
    border: 0;
  }
}

.hx-shift-cell-wrapper.day-view {
  width: 100%;
}

._shift {
  .hx-avatar-placeholder.icon {
    background-color: #f1f1f1;
    text-align: center;
    color: #a3b3ce;
    margin-right: 0.53846em;
    line-height: 32px;

    &::before {
      font-size: 20px;
    }
  }

  .hx-calendar-shift {
    .label-calendar .sign {
      display: none;
    }
    i.icon-moon,
    i.icon-sun {
      position: relative;
      top: 0.181818181818182em;
    }
    .legend {
      border: 1px solid;
      width: 2.2em;
      height: 1.7em;
    }
  }
}

._modeFull ._staffContainer > .divider + .hx-calendar-staff {
  .bt1 {
    border-top: 0;
  }
}

.hx-print-view {
  .hx-calendar-shift.on-call-avatar-row,
  .hx-calendar-shift .legend {
    display: none;
  }
}

.hx-print-view .hx-calendar-shift {
  padding-right: 0;
  color: #ffffff !important;
  background-color: #a4a4a4 !important;
  border-bottom: 0;
  white-space: pre-line;
  overflow: visible;
  font-size: smaller;

  .row,
  .row-7 {
    flex-wrap: nowrap;
  }

  > .row {
    border-left: 2px solid #a4a4a4;
    border-right: 2px solid #383838;
    border-bottom: 2px solid #383838;
  }

  .label-calendar {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;
    color: #ffffff !important;
    padding: 0;
  }

  .label-calendar .sign {
    color: #ffffff !important;
    display: inline-block;
  }

  ._scheduledNumber .label-calendar {
    font-size: 0.692307692307692em;
    letter-spacing: -0.038888888888889em;
  }

  i.icon-moon,
  i.icon-sun {
    position: relative;
    top: 0;
  }

  aside {
    display: flex;
    align-items: center;
    padding: 0;
    height: 100%;
    min-width: 0;
  }

  aside span:first-child {
    display: flex;
    align-items: center;
    height: 1.4em;
    line-height: 1.4em;

    font-size: 0.769230769230769em;
    margin: 0 0.7em 0 0.5em;
    text-transform: uppercase;
    letter-spacing: 0.066666666666667em !important;
  }

  aside span:first-child i {
    margin-right: 0.5em;
    color: #ffffff !important;
  }

  aside span:last-child {
    font-weight: 700;
    line-height: 1.4em;
    padding-top: 0.142857142857143em;
  }

  ._headerCell._scheduledNumber {
    border: 0 solid #a4a4a4;
    border-right: 1px solid #383838 !important;
  }

  ._headerCell._scheduledNumber:last-child {
    border-right: 0 !important;
  }

  .row-7 {
    border-left: 2px solid #383838;
  }

  .col-10 > .row {
    border: 0 !important;
  }

  .col-10 > .row > .br4 {
    border: 0 !important;
  }
}
