.hx-main-navigation {
  position: relative;
  display: flex;
  flex-direction: column;

  .hx-navigation {
    position: relative;
    z-index: 120;

    .hx-schedule-selector {
      top: 10px;
    }
  }
}

// calendar navigation submenu
div.calendar-navigation-container {
  justify-content: center;
  background-color: white;
  div {
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin: 0 auto;
  }
}

.calendar-navigation-item {
  color: #808fa4;
  font-size: 14px;
  line-height: 16px;
}

.hx-navigation > .row > .col:nth-child(2) {
  > .loader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  > .loader .loading {
    display: inline-block;
    color: transparent !important;
    position: relative;
  }

  > .loader .loading:after {
    width: 3.07692em;
    height: 3.07692em;
  }
}

.active-calendar-navigation-item {
  color: #059999;
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 3px solid #059999;
}

// toolbar
.common-toolbar {
  padding: 0 1.07692308em;
  height: 44px;
  box-shadow: 0 1px 3px 0 rgba(157, 169, 185, 0.2);
  border-top: 1px solid #dee5ef;
  border-bottom: 1px solid #dee5ef;

  .icon-zoom {
    margin-left: 0.61538em;
    font-size: 1.23077em;
  }

  input {
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #fbfbfb;
  }
}

.calendar-toolbar {
  font-size: 13px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.54;
  letter-spacing: -0.1px;
  border-top: 1px solid #dee5ef;
  border-bottom: 1px solid #dee5ef;
  height: 44px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(157, 169, 185, 0.2);
  padding: 0 1.07692308em;

  .icon-zoom {
    margin-left: 0.61538em;
    font-size: 1.23077em;
  }

  input {
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #fbfbfb;
  }

  & > div:first-child {
    display: flex;
    padding: 0;
  }

  .toolbar-button {
    border: 1px solid #dfe5ef;
    border-radius: 6px;
    padding: 4px;
    font-weight: 400;
    font-size: 12px;
  }

  .add-display-button {
    color: #808fa4;
    font-weight: 100;
  }

  label.shift-select {
    color: #535b65;
    margin-bottom: 0;
    font-weight: 100;
    font-size: 13px;
    font-style: normal;
    font-stretch: normal;
    text-transform: initial;
    letter-spacing: 0;
    white-space: nowrap;
  }
}

// helpers
.space-around-align {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.space-between-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.space-evenly-align {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.flex-end-align {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

// legacy styles tweaks/overrides
div.calendar-data {
  margin-top: 20px;
  display: inline-block;
  width: 100%;
}

section.hx-calendar-navigation {
  background-color: white;
  & > div {
    padding-left: 1em;
    padding-right: 1em;
  }
}

.button-status {
  margin-left: 12px;
}

.icon-print {
  font-size: 18px;
}

.filter-container {
  position: relative;
  margin-left: 10px;
}

.red-dot-notification {
  height: 5px;
  width: 5px !important;
  background-color: red;
  border-radius: 50%;
  display: inline-block !important;
  margin: 1px 1px 4px 4px !important;
}
