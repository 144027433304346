@function em($pixels, $base-font-size: $font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($base-font-size)) {
    $base-font-size: $base-font-size * 1px;
  }

  @return calc($pixels / $base-font-size) * 1em;
}

$color-gulf-blue: #32425e;
$color-bombay: #afb3bc;
$color-white: #ffffff;
$color-carmine-pink: #e25141;

.hx-top-navigation {
  height: em(50px, 13px);
  min-height: em(50px, 13px);
  background-color: $color-gulf-blue;
  line-height: normal;
  justify-content: space-between;

  position: fixed;
  width: 100%;
  z-index: 200;
  top: 32.5px;

  ._items + div {
    .dropdown-menu {
      margin-left: auto;
    }
  }

  .icon-question {
    color: $color-bombay;
    position: relative;

    &:after {
      content: "";
      height: 2em;
      width: 2em;
      border: 1px solid $color-bombay;
      position: absolute;
      left: 28%;
      top: -20%;
      border-radius: 50%;
    }
  }

  ._items {
    justify-content: center;
    white-space: nowrap;
    overflow: visible;
    text-overflow: ellipsis;
    max-height: em(50px, 13px);
    min-width: 1100px;
  }

  .hx-top-navigation-item {
    color: $color-bombay;
    font-size: em(14px, 13);
    letter-spacing: em(-0.1px, 14px);
    font-weight: 600;
    position: relative;
    cursor: pointer;

    i {
      font-size: 1.75em;
      margin: 0 0.25em;
      position: relative;
      z-index: 100;
    }

    a {
      color: $color-bombay;
      padding: 1em 0;
    }

    .label {
      position: absolute;
      background-color: $color-carmine-pink;
      color: $color-white;
      line-height: normal;
      display: flex;
      padding: 0 0.5em;
      border-radius: 1em;
      top: 0.65em;
      left: -0.75em;
      height: em(15px, 11px);
      font-size: em(11px, 14px);
      z-index: 110;
    }

    &:not(.active):not(._more-items):hover {
      opacity: 0.7;
    }

    &.active {
      color: $color-white;
      letter-spacing: em(-0.11px, 14px);
      font-weight: 800;
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 1em;
        border: 1px solid #ffffff;
        position: absolute;
        left: 0.8em;
        top: 3em;
        height: 3px;
        background: #ffffff;
        border-radius: 2px;
      }

      a {
        color: $color-white;
      }
    }
  }

  .calendar-select-unit-container {
    height: 100%;
    align-self: start;
    width: 15em;

    & > a {
      display: none;
    }

    button {
      width: 90%;
      color: $color-white;
      background-color: $color-gulf-blue;
      padding: 0 1.07692308em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.0125px;
    }
  }
}

@media (max-width: 1440px) {
  .hx-top-navigation {
    ._items {
      min-width: calc(100% - 32em);
    }
  }
}

@media (max-width: 1300px) {
  .hx-top-navigation {
    ._items {
      min-width: calc(100% - 40em);
    }
  }
}

@media (max-width: 730px) {
  .hx-top-navigation {
    ._items {
      min-width: calc(100% - 40em);
    }
  }
}
