.popup {
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);
  position: absolute;
  z-index: 110;
}

.triangle {
  z-index: -1;
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  border-radius: 1px;
  background-color: #ffffff;
  position: absolute;
}
