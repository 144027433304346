.hx-calendar-header .hx-calendar-header-day .col-9,
.hx-calendar-header .hx-calendar-header-day .col-3,
.hx-calendar-header .hx-calendar-header-day .col-6 {
  font-size: 0.92308em;
  line-height: 1.33333em !important;
  letter-spacing: 0.1em;
  padding: 0.33333em 0.83333em;
}
.hx-calendar-header .hx-calendar-header-day i {
  position: relative;
  top: 0.08333em;
}
.hours-list-container {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  height: inherit;
  align-items: center;
}
.hour-container {
  width: 4.166%;
  float: left;
  height: 50%;
}
.hx-calendar-header .hx-calendar-header-day .icon-left {
  margin-right: 0.41667em;
}
.hx-calendar-header .hx-calendar-header-day .row:last-child .col-3,
.hx-calendar-header .hx-calendar-header-day .row:last-child .col-6 {
  line-height: 1em !important;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.hx-calendar-header .hx-calendar-header-day .row:last-child i {
  position: relative;
  top: -0.08333em;
  margin-right: 0.41667em;
}
