.date-card {
  width: 100%;
  max-width: 100%;

  .date-section {
    width: 100%;
    max-width: 100%;
    height: 40px;
    display: flex;
    background: #FFFFFF;
    align-items: center;
    justify-content: flex-end
  }

  .event-tag {
    min-width: 100%;
    height: 20px;
    gap: 5px;
    background: #D85808;
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 6px;

    .shift-swap-label {
      max-width: 51px;
      height: 16px;
      color: #FFFFFF;
      font-size: 10px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.01em;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .tag-asset {
      width: 16px;
      height: 16px;
    }
  }

  .calendar-cell {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .cell-label {
      width: 19px;
      height: 24px;
      font-size: 100%;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;

      &.same-as-selected-label {
        color: white;
      }
    }

    .today-date {
      color: #CF4B00;
    }

    &.is-selected {
      color: white;
      background-color: #CF4B00;
    }
    .not-current-month-date {
      color: #808080a8;
    }
  }

  // Media queries for responsiveness
  @media screen and (max-width: 500px) {
    .shift-swap-tag .shift-swap-label {
      font-size: 8px;
    }
  }
}

.staff-events-container {
  overflow-x: hidden !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  height: 70px;

  .staff-event-label {
    max-width: 51px;
    height: 16px;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.01em;
    text-align: left;
    overflow-y: visible !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}