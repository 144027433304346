.extended-preview-subject {
  //in-lined
  height: 100%;
  height: 91px;
  font-size: 24px;
  font-weight: 710;
  letter-spacing: normal;
  padding-left: 38px;
  padding-right: 38px;
  padding-top: 30px;
  padding-bottom: 30px;

  color: #000000;
  position: relative;
}

.extended-preview-subtitle {
  width: 100%;
  height: 20px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #70707e;
  margin-top: 8px;
}
