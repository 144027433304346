.hx-calendar-header {
  height: 3.923076923077em;

  aside {
    min-width: 17.307692307692308em;
    padding: 0;
    align-items: initial;
    box-shadow: inset 0 1px 3px 0 rgba(157, 169, 185, 0.2);

    input {
      height: auto;
      border: 0;
      border-radius: 0;
      background-size: 17px;
      background-position: 1em center;
      padding-left: 3em;
      font-size: 14px;
      color: #808fa4;

      &::placeholder {
        color: #808fa4;
      }
    }
  }

  > header,
  > header > div {
    height: inherit;
  }

  .day-of-month {
    font-size: 10px;
  }
}

@media (max-width: 1379px) {
  .hx-calendar-header aside {
    width: 22%;
  }

  .hx-calendar-header aside + header {
    width: 78%;
  }
}
