$color-heather: #b2c0d1;
$color-rockblue: #9eb1c8;
$color-white: #ffffff;

.hx-recurring-schedule {
  .hx-seprator {
    height: 1px;
    background: #e5e5e5;
    margin-top: 10px;
  }

  .text-reccur-shift {
    font-size: 1em;
    width: 1em;
    padding-top: 0.25em;
  }

  .submenu-recur {
    width: 240px;
    border-radius: 4px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    z-index: 999;
    right: -150px;
    box-shadow:
      -6px 10px 25px rgba(0, 0, 0, 0.3),
      0 0 0 0px #eee;

    .context__item:not(.context__item--disabled) .context__item__data {
      padding-left: 1.083333333333333em;
    }

    .context__item.shift-item > .context__item__data,
    .context__item.time-off-item > .context__item__data {
      padding-left: 2.666666666666667em;
    }
  }

  .hx-radio-custom {
    display: flex;
    align-items: center;
    font-size: 1em;
    height: 2.07692em;
    position: relative;
    overflow: hidden;
  }
  .hx-radio-custom [type='radio'] {
    position: absolute;
    left: -769.15385em;
  }

  .hx-radio-custom [type='radio'] + label {
    position: relative;
    padding-left: 1.6em;
    cursor: pointer;
    text-transform: none;
    height: 1.07692em;
    font-size: 1em;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    color: #535b65;
  }

  .hx-radio-custom [type='radio'] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0.92308em;
    height: 0.92308em;
    border: 1px solid #bfcbde;
    border-radius: 100%;
    background: #ffffff;
  }

  .hx-radio-custom [type='radio'] + label:after {
    font-size: 0.61538em;
    display: flex;
    align-items: center;
    justify-content: space-around;
    content: '\e919';
    font-family:
      Icons,
      siemens sans,
      sans-serif;
    width: 1.5em;
    height: 1.5em;
    color: #4c9ffa;
    position: absolute;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    left: 0;
    top: 0;
  }

  .hx-radio-custom [type='radio']:disabled + label:before {
    box-shadow: none;
    background-color: #dfe5ef;
  }

  .hx-radio-custom [type='radio']:disabled:checked + label:after {
    color: #97a4b5;
  }

  .hx-radio-custom [type='radio']:not(:disabled) + label:hover:after {
    color: #97a4b5;
  }

  .hx-radio-custom [type='radio']:not(:disabled) + label:hover:before {
    border: 1px solid #97a4b5 !important;
  }

  .hx-radio-custom [type='radio']:not(:disabled):focus + label:after {
    color: #4c9ffa;
  }

  .hx-radio-custom [type='radio']:not(:disabled):focus + label:before {
    border: 1px solid #4c9ffa !important;
  }

  .hx-radio-custom [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  .hx-radio-custom [type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .hx-radio-custom .success[type='radio'] + label:before {
    border: 1px solid #2ecc71;
  }

  .hx-radio-custom .error[type='radio'] + label:before {
    border: 1px solid #f44336;
  }

  hx-radio-custom + .error-message {
    display: flex;
    align-items: center;
    color: #f44336;
    font-size: 0.84615em;
    font-weight: 600;
    letter-spacing: -0.00909em;
    padding: 0.27273em 0;
  }

  hx-radio-custom + .error-message i {
    font-size: 1.36364em;
    margin-right: 0.2em;
    margin-left: -0.13333em;
  }

  .datePicker {
    label {
      padding-top: 2em;
      color: $color-heather;
    }

    input[type='checkbox'] {
      display: none;

      & + div {
        display: flex;
        align-items: center;
        vertical-align: middle;
        justify-content: space-around;
        font-size: 1.076923em;
        height: 3em;
        width: 3em;
        border-radius: 1.5em;
        color: $color-heather;
        cursor: pointer;
        margin: 0.2em auto 2em;
        line-height: normal;
        border: 1px solid #dee5ef;

        &:hover {
          background: rgba($color-rockblue, 0.25);
        }
      }

      &:checked + div {
        color: $color-white;
        background: $color-rockblue;

        &:hover {
          color: $color-white;
          background: rgba($color-rockblue, 0.75);
        }
      }
    }
  }

  .preview {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1.076923em 0 0 0.5714em;
  }

  .button.primary {
    background-color: rgba(69, 154, 254, 1);
    &:hover {
      background-color: rgba(69, 154, 254, 0.7);
    }
    &[disabled] {
      cursor: not-allowed;
    }
  }
}
