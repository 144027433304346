.hx-switch {
  &.disabled {
    .hx-switch-label {
      cursor: not-allowed;
    }
  }
}

.hx-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.hx-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 3.401em;
  height: 1.801em;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  margin-right: 1em;

  .hx-switch-button {
    content: '';
    position: absolute;
    top: 0.2em;
    width: 1.401em;
    height: 1.401em;
    border-radius: 50%;
    transition: 0.2s;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
}

.hx-switch-checkbox + .hx-switch-label .hx-switch-button {
  left: 50%;
}

.hx-switch-checkbox:checked + .hx-switch-label .hx-switch-button {
  left: 0.2em;
  transform: unset;
}
