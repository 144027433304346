.hx-calendar-group .hx-calendar-group-days .col-1 > div:first-child {
  display: none;
}

.hx-print-view {
  .hx-calendar-group-days {
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    border: 0;
  }

  .hx-calendar-group-days .row-7 {
    flex-wrap: nowrap;
  }

  .hx-calendar-group-days > div {
    padding: 0;
    margin: 0 !important;
    border-left: 2px solid #000000;
  }

  .hx-calendar-group-days .col-1 {
    padding: 5px 2px;
    min-height: 30px;
    border-right: 1px solid #a4a4a4;
  }

  .hx-calendar-group-days .col-1 > div:first-child {
    display: block;
    color: #383838;
    font-weight: bold;
    font-size: 1.1em;
    letter-spacing: -0.036363636363636em;
  }

  .hx-calendar-group-days .col-1 > div:last-child {
    color: #515151;
    display: block;
    font-size: 0.9em;
    letter-spacing: -0.022222222222222em;
    font-weight: normal;
  }
}
