.pendingHeaderWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .pendingHeaderBox {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5em 0;
  }
  
  .pendingHeader{
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: flex-start;
  }
  
  .iconBox {
    display: flex;
    background-color: #d25b13;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }
  
  .pendingTitle {
    width: 96%;
    font: var(--header-1);
    font-size: 16px;
    color: var(--text-primary);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 0.2em;
    pointer-events: auto;
  }
  
  .pendingHeaderTags {
    display: flex;
    gap: 0.5em;
    width: 50%;
    justify-content: flex-end;
    pointer-events: none;
  }
  
  .declineButton {
    display: flex;
    width: 135px;
    justify-content: flex-start;
  }
  
  .acceptButton {
    display: flex;
    width: 50%;
    justify-content: flex-start;
    padding-left: 0em;
  }
  
  .pendingRequestAvatar{
      margin: 0.5em 0em;
      display: flex;
  }
  
  @media (max-width: 1650px) {
    .pendingHeader {
      width: 45%;
    }
  
    .pendingHeaderTags {
      width: 55%;
    }
  }
  
  @media (max-width: 1500px) {
    .pendingHeader {
      width: 40%;
    }
  
    .myShiftTitle {
      font-size: 12px;
    }
  
    .pendingHeaderTags {
      width: 60%;
    }
  }
  
  @media (max-width: 1400px) {
    .pendingHeader {
      width: 35%;
    }
  
    .myShiftTitle {
      font-size: 12px;
    }
  
    .pendingHeaderTags {
      width: 65%;
    }
  }
  
  @media (max-width: 1275px) {
    .pendingHeaderWrapper{
      padding-bottom: 0em;
    }
  
    .pendingHeaderBox{
      flex-direction: column;
      align-items: flex-start;
    }
  
    .pendingHeader {
      width: 100%;
      padding-bottom: 0.3em;
    }
  
    .pendingHeaderTags {
      width: 100%;
      justify-content: flex-start;
    }
  }