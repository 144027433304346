.delete-component-button {
  width: 31.4px;
  height: 32px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.35);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  .del-img {
    height: 18px;
    width: 16px;
  }
}
