.weekly-view-event-bar {
  display: flex;
  align-items: center;
  border-radius: 4px;
  height: 40px;
  margin-top: 5px;
  width: 100%;
}

.weekly-view-event-bar-main {
  margin: 0 2.5px;
  width: 98%;
}

.weekly-view-event-bar-main.isAvatarCell {
  width: 97%;
}

.weekly-view-spillproof-bar {
  display: flex;
  width: 100%;
}

.event-bar-center {
  width: 40%;
}

.event-bar-shift-name {
  width: 40%;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 3px;
}

.event-bar-right {
  width: 10%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
}

.week-view-shift-icon {
  display: flex;
  font-size: 15px;
}

.icon-info-partial-shift:before {
  background-color: red;
  border-radius: 50%;
}

.week-view-shiftView {
  margin: 0 0.8em;
}

.event-bar-shiftview {
  display: flex;
  flex-direction: column;
  font-size: 1.3em;
  letter-spacing: 0.05em;
  line-height: 1;
  word-spacing: 0.1em;
  overflow: hidden;
  margin-right: 4px;
}

.shiftView-shiftRange {
  font-size: 0.85em;
}

.event-bar-staffFullName {
  color: white;
  margin-bottom: 0.3125em;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1280px) {
  .event-bar-shiftview {
    padding-left: 5px;
  }
}
