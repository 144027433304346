.hx-announcements-header {
  z-index: 100;
}

.btn-wrapper {
  width: 154px;
  height: 30px;
  border-radius: 6px;
  background-color: #459afe;
  position: fixed;
  margin-right: 20px;
  right: 43px;
  top: 120px;
  text-align: center;
  font-size: 13px;
}

div.metadata-container {
  width: 350px;
  height: 180px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin: 10px;

  .metadata-details {
    width: 100%;
    flex-flow: column;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: -0.1px;
    color: #808fa4;
    text-transform: capitalize;
    position: relative;

    .retry-announcement {
      position: absolute;
      right: 0px;
      top: 86px;
      width: 80px;
      height: 28px;
      border-radius: 6px;
      border: solid 1px #dfe5ef;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Nunito Sans', sans-serif;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.23;
      letter-spacing: -0.1px;
      text-align: center;
      color: #677a91;
    }

    .announcement-header {
      justify-content: space-between;

      .icons-pane i:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }

    .announcement-status {
      flex-flow: row;
      font-weight: bold;
      height: 30px;
      line-height: 30px;
      font-size: 15px;
      letter-spacing: normal;
      color: #343439;
      align-items: center;

      div {
        margin-top: 1px;
        margin-left: 5px;
      }
    }

    .icons-pane {
      width: 40%;
      font-size: 10px;
      justify-content: flex-end;
      position: relative;
      ._warning {
        padding: 0 10px;
        display: flex;
        align-items: center;
        height: 32px;
        position: absolute;
        justify-content: center;
      }
    }

    .announcement-last-edited {
      margin-top: 20px;
    }
  }
}

.announcements-content {
  margin: 0 auto;
  padding-top: 44px;
  width: 100vw;

  .pagination-container {
    width: 100vw;
    margin-left: calc((74.615384615384615em - 100vw) / 2);
  }

  .button-column {
    position: absolute;
    right: calc((((100% - 970px) / 2) - 200px) / 2);
    width: 200px;
    height: 100%;
  }

  .btn-wrapper {
    position: fixed;
    top: 50px;
    margin-top: 70px;

    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: -0.2px;
    text-align: center;
    color: #fff;
  }
}
.announcements-sub-header {
  display: flex;
  align-items: center;
}
.announcements-title {
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -0.3px;
  text-align: left;
  color: #535b65;
  margin: 12px 0px 12px 0px;
}
.announcements-log {
  display: flex;
  flex-flow: column;
  margin: 20px 0;
  width: 74.615384615384615em;
  padding-top: 10px;
  padding-bottom: 20px;

  .bucket-headers {
    margin-top: 15px;
    padding-left: 115px;
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    color: #535b65;
  }

  .announcement-item + hr {
    margin-top: 1em;
    align-self: center;
    width: 750px;
  }

  .announcement-item + hr.without-details {
    margin-top: -2em;
  }

  .announcement-item {
    margin: 30px 0;
    .notifications-unclicked {
      cursor: pointer;
    }
  }

  .announcement-item div.card {
    position: relative;
    zoom: 0.75;
    margin: 0 10px;
    align-items: flex-start;
    padding-top: 0px;
    margin-right: 50px;
  }
}
.announcements-log p {
  text-align: center;
  margin: 10px;
}
.announcements-log .announcements-log-entry {
  display: flex;
  position: relative;
  flex-direction: horizontal;
  justify-content: space-between;
  height: 140px;
  width: auto;
  background-color: white;
  margin: 5px 0px;
  border-radius: 6px;
}
.announcements-log .announcements-log-entry:hover {
  opacity: 0.5;
}
.announcements-log .announcements-log-entry .left-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: auto;
  width: 20%;
  margin: 5px;
  padding: 15px;
}
.announcements-log .announcements-log-entry .left-column .profile-picture-container {
  height: 60%;
  margin-bottom: 5px;
}
.announcements-log .announcements-log-entry .left-column .profile-picture-container img {
  display: block;
  margin: auto;
  height: 100%;
  width: auto;
  border-radius: 50%;
}
.announcements-log .announcements-log-entry .left-column .last-edited {
  display: block;
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.1px;
  color: #95a0b2;
  width: 100%;
}
.announcements-log .announcements-log-entry .middle-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: auto;
  width: 60%;
  margin: 5px;
  padding: 11px;
  text-align: left;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.1px;
}
.announcements-log .announcements-log-entry .middle-column .announcement-title {
  font-size: 18px;
  font-weight: 700;
  color: #535b65;
}
.announcements-log .announcements-log-entry .middle-column .announcement-subtitle {
  font-size: 15px;
  font-weight: 600;
  color: #808fa4;
}
.announcements-log .announcements-log-entry .middle-column .announcement-content {
  font-size: 12px;
  font-weight: 400;
}
.announcements-log .announcements-log-entry .right-column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: auto;
  width: 20%;
  margin: 5px;
}
.announcements-log .announcements-log-entry .right-column .announcement-badges {
  flex-wrap: row;
  margin-top: 2px;
}
.announcements-log .announcements-log-entry .right-column .announcement-badges img {
  height: 50px;
  width: auto;
  border-radius: 50%;
  margin-right: 5px;
}
.announcements-log .announcements-log-entry .right-column .announcement-status-new {
  border-radius: 4px;
  width: 100px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  background-color: #2ecc71;
  text-transform: capitalize;
}
.announcements-log .announcements-log-entry .right-column .announcement-status-sent {
  border-radius: 4px;
  width: 100px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  background-color: #2ecc71;
  text-transform: capitalize;
}
.announcements-log .announcements-log-entry .right-column .announcement-status-scheduled {
  border-radius: 4px;
  width: 100px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  background-color: skyblue;
  text-transform: capitalize;
}
.announcements-log .announcements-log-entry .right-column .announcement-status-draft {
  border-radius: 4px;
  width: 100px;
  font-weight: 600;
  color: #fff;
  text-align: center;
  background-color: #aaa;
  text-transform: capitalize;
}
.announcements-log .announcements-log-entry .right-column .validity-range {
  padding: 5px;
  border-radius: 4px;
  background-color: #f2f4f5;
  text-align: left;
  color: #808fa4;
  width: fit-content;
  display: flex;
  align-content: left;
}
.announcements-log .announcements-log-entry .right-column .validity-range .icon.icon-calendar {
  margin: 2px;
}
.announcements-log .announcements-log-entry .view-edit-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(240, 240, 240, 0.3);
  font-size: 30px;
  font-weight: 600;
  color: black;
  border-radius: 6px;
  text-align: center;
  vertical-align: middle;
  z-index: auto;
  left: 0;
}
.announcements-log .announcements-log-entry .view-edit-overlay:hover {
  opacity: 1;
}
