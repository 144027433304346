.hx-print-modal {
  .modal-header {
    font-size: 1.07692308em;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.08571429em;
    height: 2.85714286em;
    padding: 0 2em;
    display: flex;
    align-items: center;
    color: #535b65;
    text-transform: uppercase;
  }

  .close {
    display: none;
  }

  .hx-schedule-print {
    .button {
      width: 6.15384615em;

      &:nth-child(1) {
        border-right: none;
      }

      &.bg-focus {
        background-color: #1c86f2;
      }
    }

    .col-5,
    .col-7 {
      margin-bottom: 0.769230769230769em;
    }

    .col-7 {
      color: var(--slate-grey);
    }

    .form-label {
      line-height: 1.2em;
    }

    .hx-checkbox {
      height: auto;

      label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: auto;
        color: #535b65;
        font-weight: normal;

        &:before {
          margin-top: 2px;
        }
      }
    }
  }

  .button {
    border: 1px #459afe solid;
  }

  .button.bg-dodgerblue {
    background-color: #459afe;
    color: white;
    box-shadow: 0 0 0 1px white inset;
  }

  .customDatePicker {
    width: auto;
    border: 1px solid #bfcbde;
    display: inline;
    position: relative;
    padding: 0 0.69231em 0 2em;
  }
}
