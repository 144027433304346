.print-and-profile-container {
  .hx-unavailability-requests-completness,
  .hx-staffing-totals {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1.07692em;
    padding: 0.57143em;
    height: 2.28571em;
    margin-right: 0.3em;

    &.hx-staffing-totals {
      padding: 0;

      .label {
        height: 100%;
        font-size: inherit;
      }
    }
  }

  > aside.float-right {
    padding-top: 0.3em;
  }

  & > div {
    margin: 0.15em 0 0.15em 0.53846em;
    padding-left: 0.64286em;
  }

  .icon-print {
    border: 1px solid #dfe5ef;
    border-radius: 0.46154em;
    padding: 3px;
  }
}
