.date {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border-radius: 2px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0 !important;
    background-color: transparent;
  }

  scrollbar-width: none;
  scrollbar-color: transparent transparent;

  &:hover {
    overflow: auto;
  }
}