@import '/src/styles/foundation/variables';

._modeFull {
  #cross-hatch-vertical,
  #cross-hatch-horizontal {
    position: fixed;
    z-index: 100;
    height: 100%;
    background-color: rgba(69, 154, 254, 0.04);
    border: solid 1px rgba(69, 154, 254, 0.5);
    box-shadow: inset 0 0 5px rgba(69, 154, 254, 0.5);
    pointer-events: none;
    transition: transform 0.1s ease-out;
    left: 0;
    top: 0;
  }

  #cross-hatch-horizontal {
    height: 50px;
    width: 100%;
  }

  #cross-hatch-vertical {
    top: $access-bar-height + $nav-bar-height + $calendar-toolbar-height + $calendar-grid-month-header-height;
  }
}
