._staffMeta {
  position: relative;
  min-width: 17.307692307692308em;
  flex-shrink: 0;
  flex-grow: 0;

  a.bg-alabaster {
    cursor: pointer;
  }
}

.profile-dropdown-options {
  position: absolute;
  font-size: 1.07692em;
  margin-top: 3.75em;
  padding-right: 1em;
  z-index: 9999;
  width: 16.66667%;
}

.profile-dropdown-options > ul {
  position: relative;
  width: 100%;
  margin-left: 1px;
  font-weight: 600;
  box-shadow: 0 4px 8px 0 rgba(34, 50, 71, 0.2);
  background-color: #fbfbfb;
  border: 1px solid #dee3ec;
  list-style: none;
  cursor: pointer;

  & > li {
    border-right: 0;
    border-bottom: 1px solid #dee3ec;
    padding: 0;
  }
  & > li > a {
    color: #808fa4;
    padding: 1.03846em 1.53846em 1.03846em 1.92308em;
    display: block;
  }
  & > li:last-child {
    border-bottom: 0;
  }
}

@media (max-width: 1379px) {
  ._staffMeta,
  .profile-dropdown-options {
    width: 22%;
  }
}

.hx-calendar-staff-info-wrapper {
  min-height: 3.69231em;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  align-items: center;
  padding: 0 0.76923077em;
  margin-right: 1em;
  line-height: 1.5;

  .staff-meta {
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    width: calc(100% - 3em);
  }
}

._modeFull,
._modeDay {
  .hx-calendar-staff aside::before {
    border-top: 1px solid #dee3ec;
  }
}
