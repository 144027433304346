$font-normal: 400;
$font-bold: 700;
$color-armadillo: #535b65;
$color-tropical-blue: #c4dbf2;
$color-cadet-blue: #a2b2c4;
$color-charcoal: #38434f;
$color-white: #ffffff;

.bg-tropicalblue {
  background-color: $color-tropical-blue;
}

@function em($pixels, $base-font-size: $font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($base-font-size)) {
    $base-font-size: $base-font-size * 1px;
  }

  @return calc($pixels / $base-font-size) * 1em;
}

.hx-calendar-context-menu {
  opacity: 1 !important;
  position: static !important;
  font-size: em(13px, 13px);
  border-radius: 0.30769231em;
  overflow: hidden;

  &.context {
    width: em(150px, 13px);
    z-index: 101;
  }
}

.submenu {
  width: 148px;
  border-radius: 4px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  z-index: 999;
  right: -150px;
  box-shadow:
    -6px -3px 25px rgba(0, 0, 0, 0.3),
    0 0 0 0px #eee;
  max-height: calc(50vh - 100px);
  overflow-y: scroll;

  .shifts-separator {
    padding-left: 1em;
  }

  .context__item:not(.context__item--disabled) .context__item__data {
    padding-left: 1.083333333333333em;
  }

  .context__item.shift-item > .context__item__data {
    padding-left: 1.333333333333334em;
  }
  .context__item.time-off-item > .context__item__data {
    padding-left: 2.666666666666667em;
  }
}

.context {
  cursor: pointer;
  padding: 0;
  border-radius: em(4px, 13px);
  position: fixed;
  opacity: 0;
  user-select: none;
  background-color: #fff;
  box-sizing: border-box;

  .hx-contex-menu-item-advanced-options {
    height: em(34px, 13px);
    display: flex;
    align-items: center;
    padding: 0;

    .context__item__data {
      width: 100%;
      height: 100%;
    }

    i {
      margin-right: em(7px, 13px);
    }
  }

  &__submenu-arrow {
    font-size: 12px;
    position: relative;
    right: 10px;
    line-height: 22px;
    margin-left: auto;
  }

  &__item {
    cursor: pointer;

    &.selected > &__data,
    &:not(&--disabled):hover > &__data {
      font-weight: $font-bold;
      color: $color-armadillo;
      background-color: $color-tropical-blue;
    }

    &--disabled {
      cursor: default;
      opacity: 1;

      .context__item__data {
        color: rgba($color-armadillo, 0.5);
        font-weight: $font-normal;

        i {
          color: $color-cadet-blue;
          font-size: em(17px, 13px);
        }
      }
    }

    &__data {
      font-size: em(12px, 13px);
      color: $color-charcoal;
      font-weight: $font-normal;
      display: flex;
      align-items: center;
      padding: em(4px, 12px) em(10px, 12px);

      i {
        display: block;

        font-size: em(14px, 13px);
      }

      & > span:first-child {
        margin-right: em(3px, 13px);
        display: block;
      }

      & > span + span {
        margin-left: em(3px, 13px);
        display: block;
      }
    }
  }
}
