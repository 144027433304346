.last-login-banner {
  min-width: 8em;
  sh-text {
    color: var(--text-white-disabled);
  }

  .last-login-label {
    padding-right: 0.5rem;
    border-right: 1px groove var(--text-white-disabled);
    text-align: right;
    box-sizing: content-box;
  }

  .last-login-value {
    padding-left: 0.5rem;
  }
}
