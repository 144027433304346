@import '/src/styles/foundation/variables';

.hx-activities-header {
  min-width: 1068px;
  width: 100%;
  z-index: 100;

  & + section {
    padding-top: $nav-bar-height;
  }
}

.activities-nav-bar {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(157, 169, 185, 0.2);
  z-index: 90;

  position: fixed;
  top: $access-bar-height + $nav-bar-height;
  width: 100vw;

  .title {
    display: none !important;
  }

  @media (max-width: 1300px) {
    .title {
      display: block !important;
    }
  }

  & > .col-2 {
    width: 10%;
    min-width: 110px;
  }

  .controls {
    box-sizing: border-box;
    display: flex;
    margin-left: auto;
    justify-content: center;
    border-color: transparent;

    .icon-zoom {
      font-size: 1.23076923em;
      margin-left: 0.625em;
    }
  }

  .resetFilters {
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    color: #526173;
    cursor: pointer;
  }

  .activities-separator {
    width: 1px;
    height: 20px;
    border: solid 1px #dfe5ef;
    margin: 0 15px 0 0;
  }

  .hx-activities-filter {
    position: relative;
    display: flex;
    align-items: center;
    width: 45%;
    height: 100%;
    min-width: 587px;
    align-self: center;
  }

  .hx-search-wrapper {
    input,
    input:focus {
      border: none;
      box-shadow: none;
    }

    .hx-dirty-search {
      right: 1.1em;
      cursor: pointer;
      height: 25%;
      top: 0;
      position: absolute;
    }
  }

  h1 {
    font-size: 1.69230769em;
    color: #5f6978;
    line-height: normal;
    padding: 0;
    margin: 0;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.005em;
    text-align: center;
  }

  form {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;

    input {
      display: none;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      border-radius: 6px;
      border: solid 1px #dfe5ef;
      line-height: 1.33;
      text-align: center;
      color: #677a91;
      margin: 0 0 0 0.615384615384615em;
      cursor: pointer;
      text-transform: capitalize;
      padding: 0 0.68em;
      font-weight: 600;
      letter-spacing: normal;
      font-size: 0.84615385em;
    }

    label.checkbox {
      display: inline-block;
      margin-right: 16px;
    }

    input:checked + label {
      color: #535b65;
      background: #dfe5ef;
      border-radius: 0.46154em;
    }

    span {
      align-self: flex-end;
    }
  }
}

.activities-content {
  margin: 0 auto 1.5em;
  width: 74.615384615384615em;

  .activities-dates {
    display: flex;
    align-items: center;
    padding: 2.30769231em 0 1.07692308em;

    sh-text {
      font-size: 16px;
      text-align: left;
      color: #535b65;
      margin: 0;
    }

    div {
      width: 20px;
      height: 20px;
      border-radius: 6px;
      border: solid 1px #dfe5ef;
      margin-left: auto;
    }
  }

  .activities-day {
    border-radius: 4px;
    background-color: #ffffff;
    padding: 16px 33px 16px 33px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    margin: 0;

    .separator {
      height: 1px;
      background-color: #dce2ed;
    }

    .separator:last-of-type {
      visibility: hidden;
    }
  }

  & ~ .pagination-container {
    margin-top: 2.30769231em;
  }

  .activities-item {
    display: flex;
    align-items: center;
    margin: 10px 0;

    .skip-bar {
      height: 1px;
      background-color: #dce2ed;
      float: left;
    }

    .activities-date-range {
      border-radius: 4px;
      background-color: #f2f4f5;
      letter-spacing: -0.1px;
      text-align: left;
      color: #808fa4;
      min-width: 10em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.351em 0 auto auto;
      padding: 0 0.46153846em;

      i {
        color: #a3b3ce;
        padding-right: 0.375em;
        font-size: 1.23076923em;
      }
    }

    .activity-item-time {
      font-size: 12px;
      color: #95a0b2;
      margin-right: 15px;
      max-width: 65px;
      min-width: 60px;
    }

    .activities-item-content {
      display: flex;
      justify-content: space-around;

      .more {
        text-decoration: underline;
        text-underline-position: under;

        &:not(:hover) {
          color: #549bff;
        }
      }

      img {
        margin-right: 15px;
      }

      .activity-item-name {
        font-size: 14px;
        color: #535b65;
        margin-right: 15px;
        display: inline;
      }

      .activity-item-position {
        color: #808fa4;
        display: inline;
      }

      .activity-item-detail {
        font-size: 14px;
        color: #808fa4;
      }

      .activity-explanation {
        height: 29px;
        border-radius: 6px;
        border: solid 1px #dce2ed;
        margin-top: 10px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 0.76923077em;
        font-weight: 800;
        color: #808fa4;
        letter-spacing: 1px;
        padding: 0 0.78em;

        .explanation-title {
          opacity: 0.5;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.2;
          text-align: left;
          color: #808fa4;
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      .activity-note {
        display: flex;
        margin-top: 10px;

        .note-sidebar {
          width: 3px;
          height: 55px;
          border-radius: 2px;
          background-color: #808fa4;
        }

        .note-content {
          display: flex;
          flex-direction: column;
          margin-left: 5px;
          margin-top: 2px;

          .note-title {
            opacity: 0.5;
            font-size: 10px;
            font-weight: 900;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.2;
            letter-spacing: 1px;
            text-align: left;
            color: #808fa4;
          }

          .note-text {
            font-size: 14px;
            color: #808fa4;
          }
        }
      }
    }
  }
}

.timeJump {
  position: fixed;
  top: 40%;
  float: left;
  margin-left: 18px;
  display: flex;
  flex-direction: row;

  .timeJumpControls {
    button {
      display: flex;
      align-items: center;
      width: 68px;
      height: 23px;
      border-radius: 6px;
      background-color: #ebebeb;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: -0.1px;
      color: #677a91;
      margin-top: 14px;
      margin-bottom: 14px;
      text-transform: capitalize;

      i {
        margin-left: 7px;
        margin-right: 5px;
      }
    }

    span {
      opacity: 0.25;
      font-size: 10px;
      font-weight: 900;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: 1px;
      text-align: left;
      color: #808fa4;
      margin-top: 14px;
      margin-bottom: 14px;
      text-transform: uppercase;
    }
  }

  .tip {
    display: flex;
    align-items: center;
    width: 140px;
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: -0.1px;
    color: #a7b0b5;
    margin-left: 10px;
  }
}

@media (max-width: 1316px) {
  .activities-content {
    width: 65.538461538461538em;
  }
}

.activities-title {
  display: flex;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-pack: justify;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 1.53846em;
}

.color-grey {
  color: #d9e0e4;
}

.border-solid {
  border: solid 1px #dfe5ef;
}

.menu-separator {
  width: 1px;
  height: 34px;
  border: solid 1px #dfe5ef;
  margin: 0 15px 0 15px;
}
