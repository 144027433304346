@import '/src/styles/foundation/variables';

.hx-staff-list-filter {
  width: 100%;
  z-index: 100;
  position: fixed;
  top: $access-bar-height + $nav-bar-height;

  .title {
    display: none;
  }

  @media (max-width: 1300px) {
    .title {
      display: block;
    }
  }

  & + .hx-staff-list-table {
    // margin-top: 3.38461538em;
    padding-top: 4.0125em;
  }

  .hx-filters {
    margin-right: 1em;
  }
}

.hx-transferring-staff-header {
  margin: 20px 40px;
  font-size: 18px;
  font-weight: 600;
}

h2.hx-transferring-staff-header {
  font-size: 15px;
}

.hx-modal.hx-modal-staff-list {
  width: 820px;
  border: 1px solid rgba(0, 0, 0, 0.19);

  .hx-staff-transfer-data-container {
    margin-top: 35px;
    height: 120px;
    display: block;

    i.icon-indicator-dot {
      display: inline-block;
      margin: 0 10px 0 25px;
    }

    .hx-staff-info-data {
      display: inline;
      width: 100%;
      a {
        color: #459afe;
      }
    }

    footer {
      display: flex;
      float: right;

      button.primary {
        background-color: #459afe;
      }

      button {
        margin: 15px 15px;
        display: inline-block !important;
        flex-flow: row;
      }
    }
  }

  .hx-staff-transfer-data-container .hx-staff-info-data ._customDateInput {
    width: auto;
  }

  .form-item {
    display: inline-block;
    width: 250px;
  }
}

.hx-transferring-button {
  background-color: #599bf7 !important;
  display: block !important;
}

.hx-staff-list-table {
  max-width: 1280px;
  margin: 0 auto;

  a,
  .hx-calendar-staff aside a {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    margin: 0;
    align-items: center;
  }

  ul li {
    .phones {
      display: flex;
      flex-direction: column;
      place-content: space-around;
      align-items: left;
      vertical-align: middle;
      justify-content: center;
    }
  }

  sh-button {
    display: none;
  }

  .hx-calendar-staff .hx-staff-initials + div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 7em);
  }

  .hx-calendar-staff:hover sh-button {
    display: flex;
  }

  .hx-staff-list-header-normal {
    height: 2.153846153846154em;
  }

  header {
    padding-right: 2.30769em;

    & > div:nth-child(1) {
      padding-left: 6.46153846em;
    }
  }
}

.staff-list-table {
  .hx-calendar-staff {
    padding-right: 2.30769em;
  }

  ul li {
    & > a {
      display: flex;
      width: 100%;
    }

    .phones {
      display: flex;
      flex-direction: column;
      place-content: space-around;
      align-items: flex-start;
      vertical-align: middle;
      justify-content: center;
    }

    aside .image-32 {
      margin-left: 3.46153846em;
    }
  }

  aside {
    height: 3.69231em;
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    align-items: center;
    line-height: normal;
  }

  .__react_component_tooltip {
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    overflow: hidden;
    color: #fff;
    height: 24px;
    border-radius: 6px;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    background-color: #7a838f;
    padding: 0 0.7em;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: -0.1px;
    top: 0;
    font-family: 'Nunito Sans', sans-serif;
  }

  ._status {
    margin: 0 auto;

    i {
      display: flex;
      height: auto;
    }
  }

  button {
    font-size: 0.9333em;
    margin-right: 10px;
  }
}
