.hx-print-view {
  .print-table thead tr td {
    padding: 0.5em 1.230769230769231em 0;
  }

  .top-colontitle {
    display: flex;
    margin: 0 0 1.230769230769231em;
    height: 2.076923076923077em;
    line-height: 2.076923076923077em;
    width: 100%;
  }

  .top-colontitle > div:first-child {
    letter-spacing: -0.025em;
    font-size: 1.846153846153846em;
    margin-right: 0.666666666666667em;
  }

  .top-colontitle > div:nth-child(2) {
    font-size: 1.076923076923077em;
    letter-spacing: -0.028571428571429em;
    flex-grow: 1;
  }

  .top-colontitle > div:last-child {
    font-size: 1.692307692307692em;
    letter-spacing: -0.027272727272727em;
  }
}
