.hx-header aside:last-child .dropdown-menu li {
  &.hx-facility-link {
    background-color: #535b65;

    &.active a {
      color: white;
    }
  }

  a {
    padding: 0.90909em;
    line-height: 1.5;
  }
}

._mainContainer > header {
  margin-top: -3.84615385em;
}
