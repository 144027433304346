.accordion-wrapper {
  height: 53em
}

.text-color > sh-text {
  color: rgba(var(--ui-1), var(--opacity-3)) 
}

.history > sh-accordion {
  background-color: whitesmoke;
}