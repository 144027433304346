.hx-pending-requests-container ._timeOfRequests {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  padding-left: 2px;
  padding-right: 2px;

  & > div {
    width: 100%;
    position: relative;
    height: 100%;
  }
}
