.extended-preview-link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  // padding: 20px;
  width: 100%;
}

.extended-preview-link-unclicked {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 20px;
}

.extended-preview-link-unclicked:before {
  content: '';
  width: 800%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -500%;
  z-index: -5;
}

.extended-preview-link:before {
  content: '';
  width: 800%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -500%;
  z-index: -5;
  background-color: rgba(231, 231, 231, 0.46);
}
.extended-preview-link:hover {
  cursor: pointer;
}

.extended-preview-link-unclicked:hover {
  cursor: pointer;
}
.link-box {
  width: 396px;
  height: 38px;
  border-radius: 5px;
  background-color: #4a90e2;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.link-text {
  font-size: 16px;
  font-weight: 8px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  position: absolute;

  display: flex;
  justify-content: center;
  margin-top: 0px;
}
