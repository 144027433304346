.bg-selected-shift {
  background-color: rgba(73, 144, 226, 0.23);
  border: 1px solid #4990e2;

  i {
    color: #4990e2;
  }

  &.first-selected,
  &.middle-selected {
    border-right-color: transparent;

    &:last-child::after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 4px;
      background-color: rgba(73, 144, 226, 0.23);
      right: -4px;
    }
  }

  &.last-selected,
  &.middle-selected {
    border-left-color: transparent;
  }
}

._cell.bg-error {
  background-color: #fde3e2;
  border-bottom: 2px solid #f0453d;
}

._cell.bg-selected-shift.bg-error {
  background-color: #fde3e2;
  border-bottom: 1px solid #4990e2;
}

._cell.half-opacity {
  opacity: 0.5;
}

._cell._yesterdayCell {
  border-right-color: #405772;
}

._cell {
  overflow: hidden;
}

._cell > div {
  width: 100%;
  height: 100%;
}

.staff-details {
  .shift-icons {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;

    sh-text {
      letter-spacing: 0.5px;
    }
  }
}
