.printed-day-view {
  .hx-calendar-staff-shift-wrapper {
    padding: 0.461538461538462em 0.307692307692308em 0.384615384615385em !important;
  }

  section .hx-calendar-staff-days {
    overflow: hidden;
  }

  section .hx-calendar-staff-days .hx-calendar-staff-shift._openShift {
    position: static;
    width: 100%;
    margin: 0;
    background-color: #ffffff !important;
    height: 1.307692307692308em;
    border-radius: 0.230769230769231em !important;
    justify-content: flex-start !important;
    padding-left: 0.384615384615385em !important;
    border: dashed 2px #000000 !important;
  }

  .hx-calendar-staff-shift._openShift i {
    font-size: 0.769230769230769em !important;
  }

  .hx-calendar-staff-shift._openShift > span.description,
  .hx-calendar-staff-shift._openShift > span.event-time {
    font-size: 0.615384615384615em !important;
    letter-spacing: -0.02875em !important;
    font-weight: bold !important;
    color: #383838 !important;
    margin-left: 0.375em !important;
  }

  .hx-calendar-staff-shift._openShift > span.event-time {
    margin-right: 1.25em;
  }
}
