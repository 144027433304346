.crud-card-table {
  .crud-card-table-header {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    padding: 5px 20px;
    line-height: 1.33rem;

    .crud-card-table-header-item {
      font-size: 0.76923em;
      font-weight: 900;
      color: #808fa4;
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }
  }

  .crud-card-table-row {
    padding: 10px 20px;

    &:first-of-type {
      padding-top: 20px;
    }

    &:last-of-type {
      padding-bottom: 20px;
    }
  }
}
