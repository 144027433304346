.constraint-weight-container{
    width: 100%; 
    margin: 0 auto;
    display: flex;
    align-items: center;
}
.constraint-weight{
    width: 100%; 
    display: flex; 
    flex-wrap: wrap;
}
.weight{
    width: calc(20% - 4px);
    height: 0.7rem;
    margin: 2px;
}
.constraint-weight .weight:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.constraint-weight .weight:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px; 
}
