@import '/src/styles/foundation/variables';

.hx-open-shifts-header {
  box-shadow: 0 1px 3px 0 rgba(157, 169, 185, 0.2);
  background-color: #ffffff;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  height: 3.38461538em;
  padding: 0 1.07692308em;
  position: fixed;
  top: $access-bar-height + $nav-bar-height;
  width: 100%;
  z-index: 100;
}

.hx-open-shifts-header + div {
  margin-top: 3.384615384615385em;
  padding-top: 3.846153846153846em;
}

.hx-open-shifts-header-module {
  line-height: 44px;

  aside {
    display: inline-block;
  }
}

.hx-status-filter ul li:nth-child(5) label {
  color: #b2c0d1;
  font-weight: bold;
}

.hx-status-filter ul li:nth-child(4) label {
  color: #2ecc71;
  font-weight: bold;
}

.hx-status-filter ul li:nth-child(3) label {
  color: #ea8f17;
  font-weight: bold;
}

.hx-status-filter ul li:nth-child(2) label {
  color: #459afe;
  font-weight: bold;
}

.hx-open-shifts-header-module.center {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.11px;
  color: #5f6978;
}

.hx-open-shifts-header-module.title {
  display: none;
}

@media (max-width: 840px) {
  .hx-open-shifts-header-module.title {
    display: block;
  }
}
