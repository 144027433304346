$color-catskillwhite: #dfe5ef;

.hx-open-shift-card-group {
  width: 80%;
  margin: 0 auto 50px auto;
  font-size: 12px;
}

.hx-cards-hidden-link,
.hx-cards-hidden-link:hover {
  color: white !important;
  position: absolute;
  width: 100%;
  height: 42px;
  z-index: 200;
}

.hx-open-shift-empty {
  height: 56px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;

  sh-text {
    margin: 15px 50px;
    font-size: 14px;
    color: #535b65;
  }
}

.hx-open-shift-cards-outer-header {
  font-size: 10px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 1px;
  color: #32425e;
  height: 28px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(191, 203, 222, 0.5);
  z-index: 99;

  &.js-is-sticky[style*='position: fixed;'] {
    width: 65%;
  }

  &.js-is-stuck {
    display: none;
  }

  &.js-is-stuck + div:before {
    display: block;
    content: ' ';
    width: 100%;
    height: 28px;
  }

  div {
    height: 28px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.8727em 8px 0.8727em 24px;
  }
}

.hx-open-shift-cards-header {
  width: 175px;
  height: 24px;
  margin-bottom: 10px;

  sh-text {
    font-size: 16px;
    color: #535b65;
  }
}

.hx-open-shifts-cards {
  max-width: 90.15385em;
  width: 100%;
  margin: 0 auto;
}

.hx-open-shifts-cards header {
  position: relative;
  padding: 1.15385em 0 1em;
  border-bottom: 1px solid $color-catskillwhite;
  border-left: 1px solid $color-catskillwhite;
  border-right: 1px solid $color-catskillwhite;

  .icon-up,
  .icon-down {
    position: relative;
    top: 0.08441596812453em;
    margin-left: 0.844159681245304em;

    &:before {
      font-size: 1em;
    }
  }
}
.hx-open-shifts-cards header div {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  float: left;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0.84615em;
  line-height: 1.09091em;
  letter-spacing: 0.09091em;
  padding: 0 0.72727em 0 2.18182em;
}

.hx-open-shift-card-group .hx-open-shifts-body h5 {
  font-size: 0.92308em;
  line-height: 1.33333em;
  letter-spacing: 0.1em;
  padding: 0.5em 1.33333em 0.33333em 2em;
  border-bottom: 1px solid #bfcbde;
  margin: 0;

  &:hover {
    background-color: rgba(69, 154, 254, 0.04);
  }
}

.hx-open-shift-card-group .hx-open-shifts-body h5 span {
  font-size: 0.83333em;
  letter-spacing: 0.1em;
  margin-left: 0.9em;
}

.hx-open-shift-card-group .hx-open-shifts-body i {
  position: relative;
  top: 0.08333em;
  cursor: pointer;
}

.hx-open-shift-item-week {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  line-height: 1.2;

  div.row {
    width: 93%;
  }
}

div.hx-cards-date {
  margin: 0.2em;
  padding-left: 0.5em;
}

.hx-open-shift-item,
.hx-open-shift-item-week {
  &.bg-important {
    background-color: #fff2f1;
  }
  div.important-icon {
    padding: 0.1em;

    div.icon {
      border-radius: 50%;
      border: 1px solid;
      padding-left: 0.03em;
    }
  }
}

.hx-open-shift-card {
  cursor: pointer;
  border-bottom: 1px solid $color-catskillwhite;
  border-left: 1px solid $color-catskillwhite;
  border-right: 1px solid $color-catskillwhite;

  .hx-open-shift-item-staff.left-exclamation {
    position: relative;

    &:before {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      content: ' ';

      background: transparent url(/styles/web_v3/exclamation-point.svg) no-repeat center center;
      width: 1.142857142857143em;
      height: 100%;
    }
  }
}

.hx-open-shift-item:hover,
.hx-open-shift-item-week:hover {
  background-color: #fbfbfb;
}

.hx-cards-row {
  border-bottom: 0.2px solid #eeebeb;
}

.hx-open-shift-item > div,
.hx-open-shift-item-week > div {
  padding: 00.72727em 8px 0.72727em 24px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  float: left;
  overflow: hidden;
  white-space: nowrap;
}

.hx-open-shift-item,
.hx-open-shift-item-week,
.hx-open-shift-cards-outer-header {
  .col-1 {
    width: 8%;
  }

  .col-2 {
    width: 10%;
  }

  .col-3 {
    width: 16%;
  }

  .col-4 {
    width: 18%;
  }

  .pl1 {
    padding-left: 0.9em;
  }
}

.hx-empty-col {
  width: 7.9%;
}

.hx-open-shift-item-staff {
  display: inherit;
}

.hx-open-shift-item-staff .image-24,
.hx-open-shift-item-staff-week .image-24 {
  width: 2em;
  height: 2em;
  margin-right: -0.35714em;
  border: 1px solid rgba(0, 0, 0, 0.08);
  position: relative;
  border-radius: 50%;
}

.hx-open-shift-item-staff .image-24:nth-child(1),
.hx-open-shift-item-staff-week .image-24:nth-child(1) {
  z-index: 5;
}

.hx-open-shift-item-staff .image-24:nth-child(2),
.hx-open-shift-item-staff-week .image-24:nth-child(2) {
  z-index: 4;
}

.hx-open-shift-item-staff .image-24:nth-child(3),
.hx-open-shift-item-staff-week .image-24:nth-child(3) {
  z-index: 3;
}

.hx-open-shift-item-staff .image-24:nth-child(4),
.hx-open-shift-item-staff-week .image-24:nth-child(4) {
  z-index: 2;
}

.hx-open-shift-item-staff .image-24:nth-child(5),
.hx-open-shift-item-staff-week .image-24:nth-child(5) {
  z-index: 1;
}

.hx-open-shift-item-staff .rounded-12,
.hx-open-shift-item-staff-week .rounded-12 {
  border-radius: 0.85714em;
}

.hx-open-shift-item-staff > span,
.hx-open-shift-item-staff-week > span {
  margin: 0 0 0 0.71429em;
  padding: 0;
  line-height: normal;
  display: flex;
  align-items: center;
}

.fs-10 {
  font-size: 10px;
}

.hx-open-shift-item-staff > span:first-child,
.hx-open-shift-item-staff-week > span:first-child {
  margin-left: 0;
}

.hx-open-shift-item-staff .half-opacity,
.hx-open-shift-item-staff-week .half-opacity {
  opacity: 0.5;
}

.hx-open-shift-item-staff > span > span:hover,
.hx-open-shift-item-staff-week > span > span:hover {
  text-decoration: underline;
  -moz-text-decoration-style: dashed;
  text-decoration-style: dashed;
}
