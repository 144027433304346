.dropdown-menu {
  position: relative;
  display: inline-flex;
  justify-content: flex-end;

  .hx-staff-avatar,
  .hx-staff-initials {
    margin-right: 0.53846em;
  }
}

.print-and-profile-container {
  display: flex;
  justify-content: flex-end;
}

.dropdown-options {
  position: absolute;
  z-index: 100000;
  top: 37px;
  right: 0;
  font-size: 1.07692em;
  font-weight: 600;
  border-radius: 0.3em;
  background-color: #32425e;
  border: 1px solid #32425e;
  list-style: none;
  width: 16em;
  cursor: pointer;
  & > li {
    border-right: 0;
    border-bottom: 1px solid #dee3ec;
    padding: 0;

    &.hx-facility-link {
      background-color: #32425e;

      &.active a,
      &.active button {
        color: white;
      }
    }
  }
  & > li > a,
  & > li > button {
    color: #808fa4;
    padding: 1.03846em 1.53846em 1.03846em 1.92308em;
    display: block;
  }
  & > li:last-child {
    border-bottom: 0;
  }
  & > li:hover:not(.disabled) {
    background-color: #32425e;
  }
  & > li:hover:not(.disabled) > a,
  & > li:hover:not(.disabled) > button {
    color: white;
  }
  & > li:last-child:hover:not(.disabled) {
    border-bottom-left-radius: 0.85714em;
    border-bottom-right-radius: 0.85714em;
  }
}

.print-and-profile-container .dropdown-menu {
  margin: 0.15em 0.53846em;
}

.hx-header .dropdown-menu {
  margin: 0 0.53846em;
}

.hx-header a.dropdown-menu + ul {
  z-index: 3000;
}

header[data-component='Header'] {
  z-index: 2;
  position: relative;
}

header[data-component='Header'] + .content-container {
  z-index: 1;
  position: relative;
}
