.button.secondary.load-template {
  height: 19px;
  padding: 0 25px;
  margin: 0 5px;
  min-width: 100px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 10px;

  &:hover {
    color: #677a91;
  }
}

.icon-close.delete-template {
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 12px;

  &:hover {
    color: #677a91;
    cursor: pointer;
  }
}
