.expiring-warning {
  display: flex;
  flex-direction: row;
  background-color: #ffd200;
  color: #4e4000;
  width: 110px;
  padding: 10px;
  height: 25px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.expired {
  display: flex;
  flex-direction: row;
  background-color: #e7001d;
  color: #fcdbdf;
  width: 90px;
  height: 25px;
  padding: 10px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.modal-body-container {
  height: calc(100% - 4.5rem);
  margin-bottom: 0;
  padding-top: 0.5rem;
  overflow: auto;
}

.tab-item {
  height: 1.5rem;
}
