.create-form {
  height: 100%;
  background-color: #ffffff;
}
.Form {
  height: auto;
  background-color: #ffffff;

  // padding-right: 20px; //
}

label.form-inline {
  margin-left: 10%;

  font-size: 0.96923em;
}

.subject.form-inline {
  margin-left: 7px;
  // margin-left: 16px;
}

input.form-inline {
  width: 300px;
  font-size: 0.96923em;
  float: right;

  margin-left: -10px;
}

textarea.form-inline {
  resize: none;
  width: 300px;
  height: 80px;
  font-size: 0.96923em;

  color: #808fa4;
  border: 1px solid #bfcbde;
  border-radius: 0.46154em;
}

.badges {
  margin-left: 65px;
}

.each-field {
  margin-top: 20px;
}

.each-field:first-of-type {
  margin-top: 55px;
}

.content .counter {
  margin-top: 58px;
}

button.toggle {
  position: absolute;
  margin-top: 5px;
  right: 10%;
}

.counter {
  color: #84abd8;
  /*margin-top: -20px;*/

  margin-left: -22px;
  margin-top: 3px;
}

.inputBoxAndCount {
  margin-left: 60px;
}

.subtitle {
  margin-right: 10px;
}

.location {
  margin-right: 5px;
}

.optional-field-inline {
  // position: absolute;
  margin-left: 10px;
  margin-top: 0px;
  font-style: italic;
}

.optional-field-below {
  // margin-left: -436px;
  margin-left: -420px;
  margin-top: 20px;
  font-style: italic;
}

.requiredAsterisk {
  margin-left: 5px;
  visibility: hidden;
}

.separator {
  height: 1px;
  width: 85%;
  background-color: #dce2ed;
  position: relative;
  margin-left: 7.5%;
  margin-top: 20px;
}

// .loudspeader-badge {
//   // display: block;
//   // background: transparent url(styles/web_v3/arrow-doc.svg) no-repeat center center;
//   // background-size: contain;
//   // width: 20px;
//   // height: 20px;
//
//   background: transparent url(styles/web_v3/loudspeaker-badge.png) no-repeat center center;
//   border-radius: 50%;
//   width: 76px;
//   height: 76px;
//   float: left;
//   margin: 10px;
//   cursor: pointer;
//
// }
