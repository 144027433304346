.hx-print-view {
  .print-content {
    padding: 0 16px;
    width: 100vw;
  }

  .print-content > div {
    page-break-after: auto;
    width: 98vw;
  }

  .pageBreak {
    page-break-after: always;
    page-break-inside: avoid;
  }
}
