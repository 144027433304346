@import '/src/styles/foundation/variables';
$color-gulf-blue: #32425e;
$color-bombay: #eff1f5;
$color-white: #ffffff;
$color-carmine-pink: #e25141;

@function em($pixels, $base-font-size: $font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($base-font-size)) {
    $base-font-size: $base-font-size * 1px;
  }

  @return calc($pixels / $base-font-size) * 1em;
}

.hx-dashboard-header-navigation {
  border-bottom: 1px solid var(--ui-ui-1-opacity-6, rgba(0, 0, 0, 0.1));
  margin-bottom: 1.30769em;
  height: em(50px, 13px);
  min-height: em(50px, 13px);
  width: 100%;
  z-index: 100;

  ._dashboard-header-items + div {
    width: 20em;
  }

  ._dashboard-header-items {
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 16px;
    background: #eff1f5;
  }
}

.hx-dashboard-navigation-item {
  color: $color-bombay;
  font-size: em(14px, 13);
  letter-spacing: em(-0.1px, 14px);
  font-weight: 600;
  position: relative;
  cursor: pointer;

  a {
    color: grey;
    padding: 1em;
  }

  .label {
    position: absolute;
    background-color: $color-carmine-pink;
    color: black;
    line-height: normal;
    display: flex;
    padding: 0 0.5em;
    border-radius: 1em;
    top: 0.65em;
    left: -0.75em;
    height: em(15px, 11px);
    font-size: em(11px, 14px);
    z-index: 110;
  }

  &:not(.active):hover {
    opacity: 0.7;
  }

  &.active {
    color: black;
    letter-spacing: em(-0.11px, 14px);
    font-weight: 800;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 2px;
      background: var(--ui-ui-2, #ec6602);
      border-radius: 2px;
    }

    a {
      color: black;
    }
  }
}
