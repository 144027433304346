.slot-data-container {
  height: 5rem;
  margin: 0.25rem;
}

.slot-data-left-container {
  height: 100%;
  display: flex;
  align-items: center;
  width: 8rem;
}

.slot-data-right-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20rem;
}

.slot-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.slot-row {
  display: flex;
  gap: 0.25rem;
  padding: 0.5rem;
}
