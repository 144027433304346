// STRUCTURAL
$em-px: 13px; //1 em = ? px
$access-bar-height: calc(32.5px / $em-px) * 1em;
$nav-bar-height: 3.84615385em;
$section-header-height: calc(42px / $em-px) * 1em;

$calendar-toolbar-height: calc(44px / $em-px) * 1em;
$calendar-grid-month-header-height: calc(24px / $em-px) * 1em;

$staff-access-bar-height: calc(58.5px / $em-px) * 1em;
$staff-side-bar-width: 6em;
