.pagination {
  display: inline-flex;
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    height: 2.14385em;
    min-width: 2.14385em;
    border: 1px solid #dfe5ef;
    border-radius: 0.4615em;
    margin: 0 0.2308em;
    font-size: 1.086em;

    a {
      text-decoration: none;
      color: #808fa4;
      width: 100%;
      height: 100%;
      font-size: 1.2307em;
      display: flex;
      align-items: center;
      justify-content: space-around;
      line-height: normal;
      padding: 0.38462em;
    }

    &.active a {
      color: #4e5e7a;
      font-weight: bold;
    }
  }

  .prev,
  .next {
    border: none;

    a {
      color: #4e5e7a;
      font-weight: bold;
    }
  }

  .prev {
    order: -1000;

    a {
      position: relative;
      font-size: 0;

      &:before {
        content: '\E901';
        position: absolute;
        font-family:
          Icons,
          siemens sans,
          sans-serif;
        font-size: 16px;
      }
    }
  }

  .next {
    order: 1000;

    a {
      position: relative;
      font-size: 0;

      &:before {
        content: '\E902';
        position: absolute;
        font-family:
          Icons,
          siemens sans,
          sans-serif;
        font-size: 16px;
      }
    }
  }

  .dotted {
    position: relative;

    &.first {
      margin-right: 2.3077em;

      &:before {
        left: 2.3077em;
      }
    }

    &.last {
      margin-left: 2.3077em;

      &:before {
        left: -2.3077em;
      }
    }

    &:before {
      content: '...';
      height: 2.14385em;
      width: 2.14385em;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
}
