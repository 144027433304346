.hx-footer {
  display: flex;
  vertical-align: center;
  justify-content: space-around;
  align-items: center;
  background-color: #fbfbfb;
  position: relative;
  bottom: 3em;
  width: 100%;
  border-top: 1px solid #dee5ef;
  height: 3em;
  z-index: 100;
}

.wrapper-container {
  top: 0;
  min-height: 100%;
  padding-bottom: 3em;
}
