.printed-day-view {
  .hx-calendar-group-container {
    position: relative;
    top: -2px;
  }

  .hx-calendar-group-container > .divider {
    margin: 0;
    border-bottom: 8px solid #dfe5ef;
  }

  .hx-calendar-group-hours {
    border-left: 2px solid #000000;
    height: 100%;
  }

  .hx-calendar-group-hours .col-1 {
    position: relative;
    align-items: flex-end;
    width: 7.692307692307692% !important;
    padding: 0;
    height: 100%;
    border: 0 !important;
  }

  .hx-calendar-group-hours .col-1 span {
    font-weight: bold;
    letter-spacing: -0.028333333333333em;
    font-size: 0.923076923076923em;
    padding-left: 0.416666666666667em;
  }

  .hx-calendar-group-hours .col-1 i {
    font-size: 0.65em;
    position: absolute;
    left: 3px;
    top: 0;
  }

  .hx-calendar-group-hours .col-1:nth-child(1) i:after,
  .hx-calendar-group-hours .col-1:nth-child(3) i:after,
  .hx-calendar-group-hours .col-1:nth-child(7) i:after {
    position: absolute;
    left: 1.538461538461538em;
    top: 0;
    width: 100%;
    height: 1.641025641025641em;
    line-height: 1.641025641025641em;
    font-weight: bold;
    color: #000000;
    display: block;
    content: 'DAY';
  }

  .hx-calendar-group-hours .col-1:nth-child(3) i:after {
    content: 'MID';
  }

  .hx-calendar-group-hours .col-1:nth-child(7) i:after {
    content: 'NOC';
  }

  .hx-calendar-group-hours .col-1:nth-child(3):before,
  .hx-calendar-group-hours .col-1:nth-child(7):before,
  .hx-calendar-group-hours .col-1:nth-child(13):before {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    content: ' ';
    border-left: 1px solid #000000;
  }

  .hx-calendar-group-hours .col-1:nth-child(13):before {
    border-left: 2px solid #000000;
  }

  .hx-calendar-group header {
    height: 2.307692307692308em;
  }

  .hx-group-summary-header {
    justify-content: space-between;
    align-items: start;
  }

  .hx-calendar-group header .hx-group-summary-header > span {
    font-size: 0.769230769230769em;
    letter-spacing: 0.063em;
    color: #383838;
    font-weight: bold;
    height: 100%;
    display: block;
    padding: 0.48em 0.6em;
  }

  .hx-staffing-totals > span {
    font-size: 0.538461538461538em;
    padding: 0 0.714285714285714em;
    border-right: 1px solid #000000;
    margin-top: 0.48em;
    line-height: 1em;
  }

  .hx-staffing-totals > span:last-child {
    border: 0;
  }
}
