div.card {
  display: flex;
  color: black;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 466px;
  padding-left: 5px;
  padding-right: 0px;
  margin-top: 24px;
}

.simple-card {
  display: flex;
  flex-direction: row;
  padding-left: 25px;
  height: 100%;
  position: relative;
}
.rectangle:before {
  background-color: rgba(231, 231, 231, 0.46);
  content: '';
  width: 100000000000%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -10000000%;
  z-index: -5;
}

.background-rectangle {
  background-color: rgba(231, 231, 231, 0.46);

  height: 250px;
  width: 50%;
  position: absolute;
  z-index: -1;
  left: 0px;
}
div.notifications {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 199px;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.27);
  border: solid 0.5px #cacada;
  background-color: #ffffff;
}

div.notifications-unclicked {
  display: flex;
  flex-direction: column;
  width: 461px;
  height: 199px;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.27);
  border: solid 0.5px #cacada;
  background-color: #ffffff;
}

.notifications-word {
  display: flex;
  flex-direction: column;
}

div.announce {
  display: flex;
  align-items: space-evenly;
}

.extend-of-simple-card {
  display: flex;
  flex-direction: column;
}

.preview-subject {
  width: 321px;
  font-size: 24px;
  font-weight: 710;
  letter-spacing: normal;
  color: #1f1f25;
  padding-top: 23px;
  margin-bottom: 20px;
}

.preview-content {
  width: 100%;
  height: 96px;
  font-size: 20px;
  line-height: 1.25;
  color: #70707e;
  padding-top: 7px;
  padding-right: 20px;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  white-space: normal;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.preview-subtitle {
  width: 100%;
  height: 20px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #70707e;
  padding-top: 3px;
}

.preview-location {
  width: 308px;
  height: 18px;

  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #b3b3b9;
  position: absolute;
  bottom: 15px;
}

.displayed-card-badge {
  // float: right; //
  // padding-top: 29.7px; //@extend
  display: flex;
  border-radius: 50%;
  align-self: center;
  width: 76px;
  height: 76px;
  border: solid 1px rgba(0, 0, 0, 0.08);
}
.extended-simple-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 15px;
}

.separator-extended-simple-card {
  margin-bottom: 17px;
  width: 100%;
  border: solid 1px #cacada;
}

.view-more-detials-separator {
  padding-top: 5px;
  width: 461px;
  border: solid 1px #cacada;
}

.view-more-details {
  font-size: 20px;
  text-align: center;
  color: #27bdc8;
}

.prof_pic {
  width: 76px;
  height: 76px;
  cursor: pointer;
  border-radius: 50%;
}

div.notifications-badge {
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 17px;
  padding-bottom: 5px;
}

.dimmed {
  opacity: 0.5;
}
