.grid-box {
    background-color: white;
    padding: 0.5em;
    margin-top: 0.2em;
    table {
        width: 100%;
        max-width: 100%;
        border-collapse: collapse;
    
        th,
        td {
          max-width: 100%;
        }
    
      }
}