.card-item {
  padding: 10px 20px;
  justify-content: space-between;

  sh-text[size='title-1'] {
    color: #535b65;
    font-size: 14px;
    width: fit-content;
  }

  sh-text[size='body-2'] {
    color: #808fa4;
    font-size: 12px;
  }

  i {
    color: #808fa4;
    margin: 0 10px;
    display: inline-block;
    cursor: pointer;
  }

  .img-container {
    display: inline-block;
    height: 60px;
    width: 60px;
    margin-right: 20px;
    background: #f2f4f5;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .expiry-indicator {
    margin-right: 1em;
  }

  &.disabled .content sh-text {
    color: #afb3bc;
  }
}
