#hx-tooltip {
  position: absolute;
  left: 50px;
  top: 50px;
  font-family:
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  display: block;
}

#hx-tooltip.right {
  margin-left: 5px;
}

#hx-tooltip.left {
  margin-left: -5px;
}

#hx-tooltip.top {
  margin-top: -5px;
}

#hx-tooltip.bottom {
  margin-top: 5px;
}

#hx-tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  border-color: #fff;
  z-index: 100;
}

#hx-tooltip.right .tooltip-arrow {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid #fff;
  margin-left: -20px;
}

#hx-tooltip.top .tooltip-arrow {
  border-top: 10px solid #fff;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

#hx-tooltip.left .tooltip-arrow {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
  border-left: 10px solid #fff;
  margin-right: -20px;
}

#hx-tooltip.bottom .tooltip-arrow {
  border-top: 10px solid transparent;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
