.laborprofile-common-card {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 1em 0.5em;
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  margin: 10px;
}

.hx-card-header {
  display: flex;
  flex-direction: row;
}

.hx-card-details {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.card-heading {
  color: var(--ui-ui-1-opacity-2, rgba(0, 0, 0, 0.9));
  font-family:
    siemens sans,
    sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  display: flex;
  align-items: center;
}

.hx-card-detail {
  display: flex;
  flex-direction: column;
}

.h60 {
  height: 60%;
}

.h20 {
  height: 20%;
}

.countFont {
  color: var(--ui-ui-1-opacity-2, rgba(0, 0, 0, 0.9));
  font-family:
    siemens sans,
    sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 16px 0;
}

.labelFont {
  color: var(--ui-ui-1-opacity-2, rgba(0, 0, 0, 0.9));
  font-family:
    siemens sans,
    sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.divider {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.hx-card-detail-first-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.warning-icon {
  padding: 10%;
}
