.editor-box {
  border-radius: 6px;
  border: solid 1px #bfcbde;
  word-wrap: break-word;

  h1 {
    font-size: 1.38462em;
    line-height: 1.14286em;
  }

  h2 {
    font-size: 1.15385em;
    line-height: 1.16667em;
  }

  h3 {
    font-size: 1em;
    line-height: 1.2em;
  }
}

.detail-text-component {
  margin-top: 55px;
}

.detail-text-counter {
  float: right;
  margin-top: -5px;
  margin-right: 10px;
}

.rdw-option-wrapper {
  // border: 1px solid #F1F1F1;
  padding: 5px;
  min-width: 25px;
  height: 20px;
  border-radius: 2px;
  margin: 0 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.rdw-option-wrapper:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
}
.rdw-option-wrapper:active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-option-active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-option-disabled {
  opacity: 0.3;
  cursor: default;
}
.rdw-dropdown-wrapper {
  height: 30px;
  background: white;
  cursor: pointer;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  margin: 0 3px;
  text-transform: capitalize;
}
.rdw-dropdown-wrapper:focus {
  outline: none;
}
.rdw-dropdown-wrapper:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
  background-color: #ffffff;
}
.rdw-dropdown-wrapper:active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-dropdown-carettoopen {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-top: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.rdw-dropdown-carettoclose {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 35%;
  right: 10%;
  border-bottom: 6px solid black;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.rdw-dropdown-selectedtext {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  padding: 0 5px;
}
.rdw-dropdown-optionwrapper {
  z-index: 100;
  position: relative;
  border: 1px solid #f1f1f1;
  width: 98%;
  background: white;
  border-radius: 2px;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow-y: scroll;
}
.rdw-dropdown-optionwrapper:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
  background-color: #ffffff;
}
.rdw-dropdownoption-default {
  min-height: 25px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}
.rdw-dropdownoption-highlighted {
  background: #f1f1f1;
}
.rdw-dropdownoption-active {
  background: #f5f5f5;
}
.rdw-dropdownoption-disabled {
  opacity: 0.3;
  cursor: default;
}
.rdw-inline-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-inline-dropdown {
  width: 50px;
}
.rdw-inline-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center;
}
.rdw-block-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-block-dropdown {
  width: 110px;
}
.rdw-fontsize-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-fontsize-dropdown {
  min-width: 40px;
}
.rdw-fontsize-option {
  display: flex;
  justify-content: center;
}
.rdw-fontfamily-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-fontfamily-dropdown {
  width: 115px;
}
.rdw-fontfamily-placeholder {
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rdw-fontfamily-optionwrapper {
  width: 140px;
}
.rdw-list-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-list-dropdown {
  width: 50px;
  z-index: 90;
}
.rdw-list-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}
.rdw-text-align-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-text-align-dropdown {
  width: 50px;
  z-index: 90;
}
.rdw-text-align-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}
.rdw-right-aligned-block {
  text-align: right;
}
.rdw-left-aligned-block {
  text-align: left !important;
}
.rdw-center-aligned-block {
  text-align: center !important;
}
.rdw-justify-aligned-block {
  text-align: justify !important;
}
.rdw-right-aligned-block > div {
  display: inline-block;
}
.rdw-left-aligned-block > div {
  display: inline-block;
}
.rdw-center-aligned-block > div {
  display: inline-block;
}
.rdw-justify-aligned-block > div {
  display: inline-block;
}
.rdw-colorpicker-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-colorpicker-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 175px;
  height: 175px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #bfbdbd;
}
.rdw-colorpicker-modal-header {
  display: flex;
  padding-bottom: 5px;
}
.rdw-colorpicker-modal-style-label {
  font-size: 15px;
  width: 50%;
  text-align: center;
  cursor: pointer;
  padding: 0 10px 5px;
}
.rdw-colorpicker-modal-style-label-active {
  border-bottom: 2px solid #0a66b7;
}
.rdw-colorpicker-modal-options {
  margin: 5px auto;
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  overflow: scroll;
}
.rdw-colorpicker-cube {
  width: 22px;
  height: 22px;
  border: 1px solid #f1f1f1;
}
.rdw-colorpicker-option {
  margin: 3px;
  padding: 0;
  min-height: 20px;
  border: none;
  width: 22px;
  height: 22px;
  min-width: 22px;
  box-shadow: 1px 2px 1px #bfbdbd inset;
}
.rdw-colorpicker-option:hover {
  box-shadow: 1px 2px 1px #bfbdbd;
}
.rdw-colorpicker-option:active {
  box-shadow: -1px -2px 1px #bfbdbd;
}
.rdw-colorpicker-option-active {
  box-shadow: 0px 0px 2px 2px #bfbdbd;
}
.rdw-link-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-link-dropdown {
  width: 50px;
}
.rdw-link-dropdownOption {
  height: 40px;
  display: flex;
  justify-content: center;
}
.rdw-link-dropdownPlaceholder {
  margin-left: 8px;
}
.rdw-link-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 205px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #bfbdbd;
}
.rdw-link-modal-label {
  font-size: 15px;
}
.rdw-link-modal-input {
  margin-top: 5px;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
  height: 25px;
  margin-bottom: 15px;
  padding: 0 5px;
}
.rdw-link-modal-input:focus {
  outline: none;
}
.rdw-link-modal-buttonsection {
  margin: 0 auto;
}
.rdw-link-modal-target-option {
  margin-bottom: 20px;
}
.rdw-link-modal-target-option > span {
  margin-left: 5px;
}
.rdw-link-modal-btn {
  margin-left: 10px;
  width: 75px;
  height: 30px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.rdw-link-modal-btn:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
}
.rdw-link-modal-btn:active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-link-modal-btn:focus {
  outline: none !important;
}
.rdw-link-modal-btn:disabled {
  background: #ece9e9;
}
.rdw-link-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center;
}
.rdw-history-dropdown {
  width: 50px;
}
.rdw-embedded-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-embedded-modal {
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-direction: column;
  width: 235px;
  height: 180px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  justify-content: space-between;
  box-shadow: 3px 3px 5px #bfbdbd;
}
.rdw-embedded-modal-header {
  font-size: 15px;
  display: flex;
}
.rdw-embedded-modal-header-option {
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.rdw-embedded-modal-header-label {
  width: 95px;
  border: 1px solid #f1f1f1;
  margin-top: 5px;
  background: #6eb8d4;
  border-bottom: 2px solid #0a66b7;
}
.rdw-embedded-modal-link-section {
  display: flex;
  flex-direction: column;
}
.rdw-embedded-modal-link-input {
  width: 88%;
  height: 35px;
  margin: 10px 0;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 15px;
  padding: 0 5px;
}
.rdw-embedded-modal-link-input-wrapper {
  display: flex;
  align-items: center;
}
.rdw-embedded-modal-link-input:focus {
  outline: none;
}
.rdw-embedded-modal-btn-section {
  display: flex;
  justify-content: center;
}
.rdw-embedded-modal-btn {
  margin: 0 3px;
  width: 75px;
  height: 30px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.rdw-embedded-modal-btn:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
}
.rdw-embedded-modal-btn:active {
  box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-embedded-modal-btn:focus {
  outline: none !important;
}
.rdw-embedded-modal-btn:disabled {
  background: #ece9e9;
}
.rdw-embedded-modal-size {
  align-items: center;
  display: flex;
  margin: 8px 0;
  justify-content: space-between;
}
.rdw-embedded-modal-size-input {
  width: 80%;
  height: 20px;
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  font-size: 12px;
}
.rdw-embedded-modal-size-input:focus {
  outline: none;
}
.rdw-emoji-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-emoji-modal {
  overflow: auto;
  position: absolute;
  top: 35px;
  left: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 235px;
  height: 180px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 2px;
  z-index: 100;
  background: white;
  box-shadow: 3px 3px 5px #bfbdbd;
}
.rdw-emoji-icon {
  margin: 2.5px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rdw-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.rdw-spinner > div {
  width: 12px;
  height: 12px;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.rdw-spinner .rdw-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.rdw-spinner .rdw-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.rdw-remove-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
}
.rdw-history-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  flex-wrap: wrap;
}
.rdw-history-dropdownoption {
  height: 40px;
  display: flex;
  justify-content: center;
}

.rdw-suggestion-wrapper {
  position: relative;
}
.rdw-suggestion-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
  min-width: 100px;
  max-height: 150px;
  overflow: auto;
  background: white;
  z-index: 100;
}
.rdw-suggestion-option {
  padding: 7px 5px;
  border-bottom: 1px solid #f1f1f1;
}
.rdw-suggestion-option-active {
  background-color: #f1f1f1;
}
.rdw-hashtag-link {
  text-decoration: none;
  color: #1236ff;
  background-color: #f0fbff;
  padding: 1px 2px;
  border-radius: 2px;
}
/*.rdw-image-alignment-options-popup {
  position: absolute;
  background: white;
  display: flex;
  padding: 5px 2px;
  border-radius: 2px;
  border: 1px solid #F1F1F1;
  width: 105px;
  cursor: pointer;
  z-index: 100;
}
.rdw-alignment-option-left {
  justify-content: flex-start;
}
.rdw-image-alignment-option {
  height: 15px;
  width: 15px;
  min-width: 15px;
}
.rdw-image-alignment {
  position: relative;
}
.rdw-image-imagewrapper {
  position: relative;
}
.rdw-image-center {
  display: flex;
  justify-content: center;
}
.rdw-image-left {
  display: flex;
}
.rdw-image-right {
  display: flex;
  justify-content: flex-end;
}
.rdw-image-alignment-options-popup-right {
  right: 0;
}*/
.rdw-editor-main {
  height: 211px;
  width: 460px;
  overflow: auto;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  // margin-top: 10px;
  word-wrap: break-word;
}

.extended-edit-preview .rdw-editor-main {
  padding: 0px;
}

.rdw-editor-toolbar {
  padding: 6px 5px 0;
  // border-radius: 2px;
  // border: 1px solid #F1F1F1;
  border-bottom: solid 1px #bfcbde;
  display: flex;
  justify-content: flex-start;
  // background: white;
  flex-wrap: wrap;
  font-size: 15px;
  margin-bottom: 5px;
  user-select: none;
}
.public-DraftStyleDefault-block {
  margin: 1em 0;
  position: relative;
  white-space: pre-wrap;
}
.rdw-editor-wrapper:focus {
  outline: none;
}
.rdw-editor-wrapper {
  box-sizing: content-box;
}
.rdw-editor-main blockquote {
  border-left: 5px solid #f1f1f1;
  padding-left: 5px;
}
.rdw-editor-main pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 1px 10px;
}

.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}

.public-DraftEditor-content[contenteditable='true'] {
  -webkit-user-modify: read-write-plaintext-only;
}

.DraftEditor-root {
  position: relative;
}

.DraftEditor-editorContainer {
  background-color: rgba(255, 255, 255, 0);
  border-left: 0.1px solid transparent;
  position: relative;
  z-index: 1;
}

.public-DraftEditor-block {
  position: relative;
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left;
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  left: 0;
  text-align: left;
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center;
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right;
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  right: 0;
  text-align: right;
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  z-index: 0;
}

.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}

.DraftEditorPlaceholder-hidden {
  display: none;
}

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}

.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}

.public-DraftStyleDefault-listLTR {
  direction: ltr;
}

.public-DraftStyleDefault-listRTL {
  direction: rtl;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding: 0;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}

.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -36px;
  position: absolute;
  text-align: right;
  width: 30px;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  position: absolute;
  right: -36px;
  text-align: left;
  width: 30px;
}

.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) '. ';
  counter-increment: ol0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1) '. ';
  counter-increment: ol1;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2) '. ';
  counter-increment: ol2;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) '. ';
  counter-increment: ol3;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4) '. ';
  counter-increment: ol4;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}
