.templates-header-container {
  width: 100%;
  height: 50px;
  background-color: #fbfbfb;
  display: flex;
  flex-flow: row;
  line-height: 35px;
  padding: 7.5px 20px;
  vertical-align: middle;
  justify-content: flex-start;

  .templates-list-container {
    max-width: calc(100% - 330px);
    display: flex;
    flex-flow: row;
    overflow-x: auto;
    // position: relative;
  }

  //   .save-template-button {
  //     width: auto;
  //     padding: 0 5px;
  //     text-align: center;
  //     position: absolute;
  //     right: 10px;
  //
  //
  //   }
}

.load-template-button {
  position: relative;
  width: auto;
  height: 19px;
}

.update-and-save-buttons {
  // vertical-align: middle;
  // position: absolute;
  // right: 10px;
  // top: 62px;

  button {
    padding-right: 1em;
    padding-left: 1em;
    i {
      margin-right: 5px;
    }
  }
}
