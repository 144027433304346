.date-range-selector {
  padding: 10px 20px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  i {
    cursor: pointer;
    padding: 0;
    &.disabled {
      cursor: default;
      pointer-events: none;
      color: #afb3bc;
    }
  }
  sh-text[size='body-1'] {
    font-size: 14px;
    width: fit-content;
    padding: 0 10px;
  }
  justify-content: space-between;

  .year-selector {
    background-color: #f2f4f5;
    padding: 3px 10px 2px 0;
    .buttons {
      display: flex;
      flex-direction: column;
    }
  }
}
