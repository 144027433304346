@function em($pixels, $base-font-size: $font-size) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($base-font-size)) {
    $base-font-size: $base-font-size * 1px;
  }

  @return calc($pixels / $base-font-size) * 1em;
}

@function slideOffsetLeft($slidesAmount) {
  @return 0 calc(1em * (-111.961538461538462 - #{$slidesAmount} * 17.307692307692308)) 0
    calc(1em * (-111.961538461538462 + #{$slidesAmount} * 17.307692307692308));
}

@function slideOffsetRight($slidesAmount) {
  @return 0 calc(1em * (-111.961538461538462 + #{$slidesAmount} * 17.307692307692308)) 0
    calc(1em * (-111.961538461538462 - #{$slidesAmount} * 17.307692307692308));
}

@mixin slideOffset($slidesAmount, $direction: 'left') {
  @if ($direction == 'left') {
    from {
      margin: 0 -111.961538461538462em;
    }

    to {
      margin: slideOffsetLeft($slidesAmount);
    }
  } @else {
    from {
      margin: 0 -111.961538461538462em;
    }

    to {
      margin: slideOffsetRight($slidesAmount);
    }
  }
}

$color-back-haze: #f6f6f8;
$color-cornflower-blue: #599bf7;
$color-pattens-blue: #d7e0ef;
$color-white: #ffffff;

@keyframes prev {
  @include slideOffset(4, 'left');
}

@keyframes next {
  @include slideOffset(4, 'right');
}

@keyframes prev-3 {
  @include slideOffset(1, 'left');
}

@keyframes prev-4 {
  @include slideOffset(2, 'left');
}

@keyframes prev-5 {
  @include slideOffset(3, 'left');
}

.hx-schedule-selector {
  position: absolute;
  left: calc(50% - calc(1015px / 2));
  width: em(1015px, 13px);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 101;
  top: em(24px, 13px);

  .prev {
    animation-duration: 1s;
    animation-name: prev;
  }

  .next {
    animation-duration: 1s;
    animation-name: next;
  }

  .prev.prev-3 {
    animation-name: prev-3;
  }

  .prev.prev-4 {
    animation-name: prev-4;
  }

  .prev.prev-5 {
    animation-name: prev-5;
  }
}

.hx-schedule-selector header {
  height: 44px;
}

// TODO refactor nesting

.hx-schedule-selector .icon-Chevron---Down {
  cursor: pointer;
}
.hx-schedule-selector header > .row {
  height: 100%;
}
.hx-schedule-selector header .col-3 span {
  margin-left: 1.80662em;
  padding: 0 2.4em;
  font-size: 1.23077em;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33em;
  letter-spacing: 0.02em;
  text-align: left;
}

.hx-schedule-selector:not(.staff-list) header .col-4:nth-child(2) {
  height: 6.1538em;
  padding-bottom: 2.530769230769231em;
}
.hx-schedule-selector header .col-4 > div {
  display: flex;
  align-items: center;
}
.hx-schedule-selector header .col-4 > div > span:first-child {
  font-size: 1.69231em;
  letter-spacing: -0.02em;
}
.hx-schedule-selector header .col-4 > div > span:nth-child(2) {
  margin: 0 1.8em 0 0.9em;
  display: inline-block;
  padding: 0.5em 1.3em 0.3em 1.3em;
  font-size: 0.76923em;
  line-height: 1.2em;
  letter-spacing: 0.1em;
}
.hx-schedule-selector header .col-6 > div i {
  font-size: 0.76923em;
}

.hx-schedule-selector > div {
  overflow: hidden;
  margin: 0;
  position: relative;

  &.hx-schedule-list {
    height: auto;
    padding: 2em 0;
    position: absolute;
    width: 100%;
    top: 5em;
    z-index: 110;
    box-shadow: 0 0.15385em 0.92308em 0 rgba(34, 50, 71, 0.25);
  }
}

.hx-schedule-selector > div > div {
  margin: 0 -111.961538461538462em;
  position: relative;
  height: 100%;
}

.hx-schedule-selector > div > div > div {
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.hx-schedule-selector > div > div > div > section {
  width: 180px;
  white-space: nowrap;
  height: 138px;
  margin: 0 22.5px;
  border: 1px solid $color-pattens-blue;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.hx-schedule-selector > div > div > div > section.active {
  border: 2px solid $color-cornflower-blue;
}
.hx-schedule-selector > div > div > div > section > div:first-child {
  font-size: 1.8em;
  margin: 0;
  padding: 0;
}
.hx-schedule-selector > div > div > div > section > div:nth-child(2) {
  font-size: em(15px, 13px);
  letter-spacing: -0.01538em;
  padding: 0;
  margin-bottom: 10px;
}
.hx-schedule-selector > div > div > div > section > div:last-child span {
  font-size: 0.69231em;
  padding: 0.22222em 0.66667em;
  letter-spacing: 0.06923em;
}
.hx-schedule-selector > div > div > div > section.half-opacity div {
  opacity: 0.5;
}

.hx-schedule-selector > div > div > div > section:nth-child(2),
.hx-schedule-selector > div > div > div > section:last-child {
  cursor: auto;
}
.hx-schedule-selector > div > div > div > section:nth-child(2) div,
.hx-schedule-selector > div > div > div > section:last-child div {
  opacity: 0.5;
}

.hx-schedule-selector > div > nav {
  height: auto;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 52px 0px;
  font-size: 8px;
  background: transparent;

  button {
    background: #ffffff;
  }
}
.hx-schedule-selector > div > nav:first-child {
  left: 20px;
}
.hx-schedule-selector > div > nav:nth-child(2) {
  right: 20px;
}

.hx-schedule-selector > div > nav > button {
  width: 3.11111em;
  height: 3.11111em;
  font-size: 1.38462em;
  border-radius: 2.15385em;
  box-shadow: 0 0.11111em 0.22222em 0 rgba(34, 50, 71, 0.25);
}

.date-range-container {
  // .date-range-container == .hx-schedule-selector > div > div > div > section. Last one is being used somewhere else
  .year {
    font-size: 15px !important;
    letter-spacing: -0.2px;
    color: #6a7b96;
    height: 23px; // To not pull content up; might be empty sometimes.
  }
}
