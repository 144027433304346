.hx-calendar-indicators-button.active + .custom-multiselect-filter {
  display: flex;
}
.hx-filters .filters-content {
  margin-top: 0;
  left: 1.5em;
}

.custom-multiselect-filter {
  display: none;
  margin-top: 14px;
  margin-left: 10px;
  font-size: 1em;
  min-width: 20em;
  max-width: 35em;
  border-radius: 0.46154em;
  box-shadow: 0 2px 34px 0 rgba(34, 50, 71, 0.25);
  position: absolute;
  z-index: 10000;

  .selectArrowUp {
    position: absolute;
    top: -0.65em;
    left: 5px;
    font-size: 1.53846em;
    margin-left: calc(50% - 0.25em - 20px);
    z-index: 10000;
  }
}

.range-input {
  width: 100%;
  margin: 0;

  .date-range-picker {
    border: 1px solid #bfcbde;
    display: inline;
    position: relative;
    padding: 0 0.69231em 0 2em;

    & + input {
      & + .icon {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1.76em;
        padding: 0 0.3em;
        font-size: 1.3em;
      }
    }
  }
  label {
    line-height: 1em;
    display: flex;
    justify-content: space-between;

    .description {
      font-size: 0.69231em;
    }
  }
}
